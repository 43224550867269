import { createSliceFactory } from "../feature-components/sliceFactory";

const { reducer, fetchAll, fetchSingle, postData, updateData, error } =
  createSliceFactory({
    name: "feesStructures",
    url: "accounting/admin/fees/structures/",
    fetchParams: (params) => {
      return params;
    },
  });

export { fetchAll, fetchSingle, postData, updateData, error };
export default reducer;
