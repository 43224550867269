import {
  Breadcrumb,
  Button,
  Input,
  message,
  Modal,
  Pagination,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchAcademicYears } from "../../../libs/redux/features/academic/academicYearNewSlice";
import { fetchAll as fetchGrades } from "../../../libs/redux/features/exams-features/gradesSlice";
import { fetchAll as fetchCourses } from "../../../libs/redux/features/academic/coursesNewSlice";
import { fetchAll as fetchStudents } from "../../../libs/redux/features/student-info/studentsSlice";
import {
  fetchAll as fetchFinalGrades,
  postData,
  updateData,
} from "../../../libs/redux/features/exams-features/finalGradesSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../../components/utility";
import {
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import DataConversion from "../../../components/DataConversion";
import { Link } from "react-router-dom";

const { Search } = Input;
const { Option } = Select;

const FinalGrades = () => {
  usePageTitle("Final Grades");

  const breadcrumbitems = [
    {
      title: (
        <Link to="/">
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: "Exams Data",
    },
    { title: "Final Grades" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const finalGrades = useSelector((state) => state.finalGrades.data);
  const totalItems = useSelector((state) => state.finalGrades.totalItems);
  const isLoading = useSelector((state) => state.finalGrades.isLoading);
  const error = useSelector((state) => state.finalGrades.error);

  const academicYearsData = useSelector((state) => state.academicYearsNew.data);
  const gradesData = useSelector((state) => state.grades.data);
  const studentsData = useSelector((state) => state.students.data);
  const coursesData = useSelector((state) => state.coursesNew.data);

  const [modalData, setModalData] = useState({
    student: null,
    course: null,
    total_marks: "",
    final_grade: null,
  });

  useEffect(() => {
    if (academicYearsData.length === 0) {
      dispatch(fetchAcademicYears());
    }
    if (gradesData.length === 0) {
      dispatch(fetchGrades());
    }
    if (studentsData.length === 0) {
      dispatch(fetchStudents());
    }
    if (coursesData.length === 0) {
      dispatch(fetchCourses());
    }
  }, [
    dispatch,
    academicYearsData.length,
    coursesData.length,
    studentsData.length,
    gradesData.length,
  ]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    academic_year: "all",
    final_grade: "all",
    search: "",
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.academic_year !== "all") {
      params.academic_year = filters.academic_year;
    }
    if (filters.final_grade !== "all") {
      params.final_grade = filters.final_grade;
    }
    if (filters.search !== "") {
      params.search = filters.search;
    }
    dispatch(fetchFinalGrades(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => {
    const student =
      studentsData &&
      studentsData.find((studentItem) => studentItem.id === item.student);
    const course =
      coursesData &&
      coursesData.find((courseItem) => courseItem.id === item.course)?.name;
    const grade =
      gradesData &&
      gradesData.find((gradeItem) => gradeItem.id === item.final_grade)?.letter;
    return {
      Serial: index + 1,
      Student: student
        ? `${student.user.first_name} ${student.user.last_name}`
        : "N/A",
      Course: course || "N/A",
      "Final Grade": grade || "N/A",
      "Total Marks": item.total_marks || "N/A",
    };
  };

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Student",
      "Course",
      "Final Grade",
      "Total Marks",
    ];
    downloadPDF(finalGrades, columns, formatTimeSlotsData, "Final Grade Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(finalGrades, formatTimeSlotsData, "Final Grade Data");
  };

  const [id, setId] = useState(null);

  const fetchSingleItem = (data) => {
    setModalData({
      student: data.student,
      course: data.course,
      total_marks: data.total_marks,
      final_grade: data.final_grade,
    });
    setId(data.id);
    setAddModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Student",
      dataIndex: "student",
      render: (student) => {
        const studentInfo =
          studentsData && studentsData.find((item) => item.id === student);
        return studentInfo
          ? `${studentInfo.user.first_name} ${studentInfo.user.last_name}`
          : "Unknown";
      },
    },
    {
      title: "Course",
      dataIndex: "course",
      render: (course) =>
        coursesData && coursesData.find((item) => item.id === course)?.name,
    },
    {
      title: "Final Grade",
      dataIndex: "final_grade",
    },
    {
      title: "Total Marks",
      dataIndex: "total_marks",
    },

    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Button className="me-4 p-2" onClick={() => fetchSingleItem(record)}>
            <EyeOutlined />
          </Button>
          <div
            onClick={() =>
              handleDelete(
                `${record.student}`,
                dispatch,
                `exam/admin/final-grades/${record.id}/`,
                fetchFinalGrades,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handleModalCancel = () => {
    setAddModal(false);
    if (id) {
      setId(null);
    }
    setModalData((prev) => ({
      ...prev,
      student: null,
      course: null,
      total_marks: "",
    }));
  };

  const handleModalOk = () => {
    const payload = {
      ...modalData,
      academic_year: 23,
    };
    if (!id) {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New final grade added successfully!");
            setModalData((prev) => ({
              ...prev,
              total_marks: "",
            }));
            setAddModal(false);
          },
        })
      );
    } else {
      dispatch(updateData({ id, payload }));
    }
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Final Grades</h1>
          <Breadcrumb className="fs-5" items={breadcrumbitems} />
        </div>
        <div>
          <Button
            onClick={() => setAddModal(true)}
            type="primary"
            icon={<PlusOutlined />}
            size="large"
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search..."
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("final_grade", value)}
            >
              <Option value="all">All grades</Option>
              {gradesData && gradesData.length > 0
                ? gradesData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.letter}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              className="w-200"
              value={filters.academic_year}
              onChange={(value) => handleFilterChange("academic_year", value)}
            >
              <Option value="all">All Academic Years</Option>
              {academicYearsData && academicYearsData.length > 0
                ? academicYearsData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.year}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={finalGrades}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* add and edit modal */}
      {addModal && (
        <Modal
          title={<h2 className="fs-2 mb-3">Final Grade Form</h2>}
          open={addModal}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={isLoading}
              loading={isLoading}
            >
              {id ? "Update" : "Submit"}
            </Button>,
          ]}
          onCancel={handleModalCancel}
        >
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Student</p>
              {error && error.student ? (
                <p className="text-sm text-danger ms-3">({error.student})</p>
              ) : (
                ""
              )}
            </div>
            <Select
              showSearch
              className="w-100"
              size="large"
              placeholder="Select Student"
              optionFilterProp="children"
              value={modalData.student || null}
              onChange={(value) =>
                setModalData((prev) => ({
                  ...prev,
                  student: value,
                }))
              }
              allowClear
              onClear={() => handleFilterChange("student", null)}
              status={error && error.student ? "error" : ""}
            >
              {studentsData && studentsData.length > 0
                ? studentsData.map((student) => (
                    <Option value={student.id} key={student.id}>
                      {student.user.first_name} {student.user.last_name}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Course</p>
              {error && error.course ? (
                <p className="text-sm text-danger ms-3">({error.course})</p>
              ) : (
                ""
              )}
            </div>
            <Select
              showSearch
              className="w-100"
              size="large"
              placeholder="Select Course"
              optionFilterProp="children"
              value={modalData.course || null}
              onChange={(value) =>
                setModalData((prev) => ({
                  ...prev,
                  course: value,
                }))
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              allowClear
              onClear={() => handleFilterChange("course", null)}
              status={error && error.course ? "error" : ""}
            >
              {coursesData && coursesData.length > 0
                ? coursesData.map((student) => (
                    <Option value={student.id} key={student.id}>
                      {student.name}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Final Grade</p>
              {error && error.final_grade ? (
                <p className="text-sm text-danger ms-3">
                  ({error.final_grade})
                </p>
              ) : (
                ""
              )}
            </div>
            <Select
              className="w-100"
              size="large"
              placeholder="Select final grade"
              value={modalData.final_grade}
              onChange={(value) =>
                setModalData((prev) => ({
                  ...prev,
                  final_grade: value,
                }))
              }
              status={error && error.final_grade ? "error" : ""}
            >
              {gradesData && gradesData.length > 0
                ? gradesData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.letter}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Total Marks</p>
              {error && error.total_marks ? (
                <p className="text-sm text-danger ms-3">
                  ({error.total_marks})
                </p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Total Marks"
              required
              value={modalData.total_marks}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  total_marks: e.target.value,
                }))
              }
              status={error && error.total_marks ? "error" : ""}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default FinalGrades;
