import { Button, Image, Input, Pagination, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchSections } from "../../../libs/redux/features/academic/sectionsNewSlice";
import { fetchAll as fetchStudents } from "../../../libs/redux/features/student-info/studentsSlice";
import { Link } from "react-router-dom";
import BreadBtn from "../../../components/BreadBtn";
import PromotionModal from "./PromotionModal";
import { postData } from "../../../libs/axios/axiosInstance";

const { Option } = Select;
const { Search } = Input;

const PromotionRecordForm = () => {
  const breadcrumbitems = [
    { title: "Student Data" },
    {
      title: (
        <Link to="/student-data/promotion-records">Promotion Records</Link>
      ),
    },
    { title: "New Promotion" },
  ];

  const dispatch = useDispatch();

  const classesData = useSelector((state) => state.classesNew.data);
  const sectionsData = useSelector((state) => state.sectionsNew.data);

  const studentsData = useSelector((state) => state.students.data);
  const isLoading = useSelector((state) => state.students.isLoaing);
  const totalItems = useSelector((state) => state.students.totalItems);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
  }, [dispatch, classesData.length]);

  const [filters, setFilters] = useState({
    search: "",
    limit: 10,
    offset: 0,
    school_class: null,
    section: null,
  });

  useEffect(() => {
    if (filters.school_class) {
      dispatch(fetchSections({ class_level: filters.school_class }));
    } else {
      dispatch(fetchSections());
    }
  }, [filters.school_class, dispatch]);

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.search !== "") {
      params.search = filters.search;
    }
    if (filters.school_class !== null || filters.school_class !== undefined) {
      params.school_class = filters.school_class;
    }
    if (filters.section !== null || filters.section !== undefined) {
      params.section = filters.section;
    }

    dispatch(fetchStudents(params));
  }, [filters, dispatch]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const [promoteModal, setPromoteModal] = useState(false);
  const [modalStudent, setModalStudent] = useState({});
  const [modalLoading, setModalLoading] = useState(false);

  const handlePromotionModalData = (student) => {
    setModalStudent(student);
    setPromoteModal(true);
  };

  const cancelPromoteModal = () => {
    setPromoteModal(false);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Name",
      dataIndex: "user",
      render: (user) => (
        <div className="flex aic">
          <Image
            src={user.image.image}
            alt={user.image.alt_text}
            style={{
              width: 50,
              height: 50,
              borderRadius: 10,
              marginRight: 10,
            }}
          />
          <span>{`${user.first_name} ${user.last_name}`}</span>
        </div>
      ),
    },
    {
      title: "Promote",
      dataIndex: "promote",
      key: "promote",
      render: (_, student) => (
        <Button
          size="small"
          type="primary"
          onClick={() => handlePromotionModalData(student)}
        >
          Promote
        </Button>
      ),
    },
  ];

  console.log(modalStudent);
  const owner = localStorage.getItem("owner");

  const handleModalSubmit = async (payloadData) => {
    setModalLoading(true);
    const payload = { ...payloadData, academic_year: 23 };
    console.log("payload prop", payload);
    try {
      const response = await postData(
        "student/admin/promotion-records/",
        payload
      );
      console.log(response);
      setModalLoading(false);
    } catch (error) {
      console.log("error promoting", error);
      setModalLoading(false);
    }
  };

  return (
    <div>
      <BreadBtn headerTitle="New Promotion" items={breadcrumbitems} />
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3">
          <div className="flex aic g-3">
            <Search
              placeholder="Search..."
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
            />
            <Select
              showSearch
              className="w-200"
              placeholder="Select Class"
              optionFilterProp="children"
              value={filters.school_class || null}
              onChange={(value) => handleFilterChange("school_class", value)}
              allowClear
              onClear={() => handleFilterChange("school_class", null)}
            >
              {classesData &&
                classesData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
            <Select
              showSearch
              className="w-200"
              placeholder="Select Section"
              optionFilterProp="children"
              value={filters.section || null}
              onChange={(value) => handleFilterChange("section", value)}
              allowClear
              onClear={() => handleFilterChange("section", null)}
            >
              {sectionsData &&
                sectionsData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={studentsData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {promoteModal && (
        <PromotionModal
          visible={promoteModal}
          onCancel={cancelPromoteModal}
          student={modalStudent}
          initialData={{
            student: modalStudent.id,
            from_class: "",
            to_class: "",
            from_section: "",
            to_section: "",
            promoted_by: Number(owner),
          }}
          loading={modalLoading}
          onSubmit={handleModalSubmit}
        />
      )}
    </div>
  );
};

export default PromotionRecordForm;
