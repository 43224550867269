import { Input, Space, Button, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import { deleteData, patchData } from "../libs/axios/axiosInstance";
import { FaFileImage, FaFilePdf, FaFileAlt } from "react-icons/fa";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import logo from "../assets/logo.png";

export const determineDocType = (file) => {
  const extension = file.name.split(".").pop().toLowerCase();
  if (["jpg", "jpeg", "png", "webp"].includes(extension)) return 0;
  if (["pdf"].includes(extension)) return 1;
  return 2;
};

export const getDocIcon = (doc_type) => {
  switch (doc_type) {
    case 0:
      return <FaFileImage />;
    case 1:
      return <FaFilePdf />;
    default:
      return <FaFileAlt />;
  }
};

export const createFormData = (file, title, owner, docDescription) => {
  const formData = new FormData();
  formData.append("owner", owner);
  formData.append("document", file);
  formData.append("doc_type", determineDocType(file));
  formData.append("title", title || "Untitled");
  formData.append("description", docDescription || "Untitled");
  return formData;
};

export const createFormDataImage = (
  file,
  title,
  caption,
  alt_text,
  isGallery
) => {
  const formData = new FormData();
  formData.append("alt_text", alt_text || "Sample alt text");
  formData.append("title", title || "Untitled");
  formData.append("caption", caption || "Sample caption");
  formData.append("image", file);
  formData.append("is_gallery_image", isGallery);
  return formData;
};

// helpers for table data

// Utility function for column search props
export const getColumnSearchProps = (
  dataIndex,
  searchedColumn,
  setSearchedColumn
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() =>
          handleSearch(selectedKeys, confirm, dataIndex, setSearchedColumn)
        }
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() =>
            handleSearch(selectedKeys, confirm, dataIndex, setSearchedColumn)
          }
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, confirm, setSearchedColumn)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : "",
  render: (text) =>
    searchedColumn === dataIndex ? <strong>{text}</strong> : text,
});

// Helper function for search handling
const handleSearch = (selectedKeys, confirm, dataIndex, setSearchedColumn) => {
  confirm();
  setSearchedColumn(dataIndex);
};

// Helper function for resetting filters
const handleReset = (clearFilters, confirm, setSearchedColumn) => {
  clearFilters();
  confirm();
  setSearchedColumn("");
};

//

export const handleDelete = async (
  name,
  dispatch,
  url,
  fetchCallback,
  params = null
) => {
  Modal.confirm({
    title: `Are you sure you want to delete ${name}?`,
    content: "This action cannot be undone.",
    okText: "Yes, Delete",
    okType: "danger",
    cancelText: "No, Cancel",
    onOk: async () => {
      try {
        await deleteData(url);
        message.success(`${name} has been deleted.`);
        if (fetchCallback) {
          if (params) {
            dispatch(fetchCallback(params));
          } else {
            dispatch(fetchCallback({ limit: 50, offset: 0 }));
          }
        }
      } catch (error) {
        message.error("Failed to delete the item. Please try again.");
      }
    },
  });
};

//

export const handleStatusChange = async (
  checked,
  record,
  dispatch,
  statusUrl,
  functionCall,
  params = null
) => {
  const updatedStatus = checked;

  try {
    await patchData(statusUrl, {
      is_active: updatedStatus,
    });
    message.success(
      ` ${
        record.name ||
        record.title ||
        record.job_title ||
        record.student_name ||
        record.user.first_name ||
        record.id
      } has been ${updatedStatus ? "activated" : "deactivated"}.`
    );
    if (functionCall) {
      if (params) {
        dispatch(functionCall(params));
      } else {
        dispatch(functionCall({ limit: 50, offset: 0 }));
      }
    }
  } catch (error) {
    console.log("patch error", error);

    message.error("Failed to update the status. Please try again.");
  }
};

export const handleSwitchChange = async (
  key,
  checked,
  record,
  dispatch,
  statusUrl,
  functionCall,
  params = null
) => {
  const updatedStatus = checked;
  try {
    await patchData(statusUrl, {
      [key]: updatedStatus,
    });
    message.success(
      ` ${
        record.name || record.title || record.job_title || record.id
      } has been ${updatedStatus ? "activated" : "deactivated"}.`
    );
    if (functionCall) {
      if (params) {
        dispatch(functionCall(params));
      } else {
        dispatch(functionCall({ limit: 50, offset: 0 }));
      }
    }
  } catch (error) {
    message.error("Failed to update the status. Please try again.");
  }
};

//

export const renderDescription = (text) => {
  const truncatedText = text.split(" ").slice(0, 3).join(" ");
  return (
    <Tooltip title={text}>
      <span>{truncatedText}...</span>
    </Tooltip>
  );
};

export const weekDayData = [
  { id: 0, title: "Saturday" },
  { id: 1, title: "Sunday" },
  { id: 2, title: "Monday" },
  { id: 3, title: "Tuesday" },
  { id: 4, title: "Wednesday" },
  { id: 5, title: "Thursday" },
  { id: 6, title: "Friday" },
];

export const enterFullscreen = () => {
  if (document.documentElement.requestFullscreen) {
    document.documentElement.requestFullscreen();
  } else if (document.documentElement.mozRequestFullScreen) {
    // Firefox
    document.documentElement.mozRequestFullScreen();
  } else if (document.documentElement.webkitRequestFullscreen) {
    // Chrome, Safari and Opera
    document.documentElement.webkitRequestFullscreen();
  } else if (document.documentElement.msRequestFullscreen) {
    // IE/Edge
    document.documentElement.msRequestFullscreen();
  }
};

export const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    // Firefox
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    // Chrome, Safari and Opera
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    // IE/Edge
    document.msExitFullscreen();
  }
};

//Utility function for downloading data as an pdf file

export const downloadPDF = (
  data,
  columns,
  formatData,
  title = "Report"
  // academicYear = "2024 - 2025"
) => {
  const doc = new jsPDF();

  // Get page width
  const pageWidth = doc.internal.pageSize.getWidth();

  const academicYear = localStorage.getItem("academic_year") || "Not found";

  // School Name (Centered)
  const schoolName = "Wisdom International School";
  const schoolNameWidth = doc.getTextWidth(schoolName);
  doc.setFontSize(16);
  doc.text(schoolName, (pageWidth - schoolNameWidth) / 2, 18);

  // Academic Year (Centered)
  const academicYearText = `Academic Year: ${academicYear}`;
  const academicYearWidth = doc.getTextWidth(academicYearText);
  const leftMargin = 13;
  doc.setFontSize(10);
  doc.text(
    academicYearText,
    (pageWidth - academicYearWidth) / 2 + leftMargin,
    25
  );

  // Title of the dataset and current date (Justified)
  const currentDate = new Date().toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const titleText = `${title}`;
  const dateWidth = doc.getTextWidth(currentDate);

  doc.setFontSize(12);
  doc.text(titleText, 15, 35);
  doc.text(currentDate, pageWidth - dateWidth - 18, 35);

  // Add margin between the header and the table
  const marginBottom = 10;
  const tableStartY = 30 + marginBottom;

  // Format data
  const formattedData = data.map((item, index) => formatData(item, index));

  const headers = columns;

  const tableData = formattedData.map((item) =>
    headers.map((header) => item[header])
  );

  // Generate the table with adjusted startY
  doc.autoTable({
    head: [headers],
    body: tableData,
    startY: tableStartY, // Table starts after header with some margin
  });

  // Save the PDF
  doc.save(`${title}.pdf`);
};

// Utility function for downloading data as an XLS file
export const downloadXLS = (data, formatData, title = "Report") => {
  const formattedData = data.map((item, index) => formatData(item, index));
  const headers = Object.keys(formattedData[0] || {});
  const worksheet = XLSX.utils.json_to_sheet(formattedData, {
    header: headers,
  });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, title);
  XLSX.writeFile(workbook, `${title}.xlsx`);
};

export const formatDateTime = (dateString) => {
  return dayjs(dateString).format("YY-MM-DD | HH:mm:ss");
};

//
export const signOutFunc = (navigate) => {
  message.error("Session Expired!");
  localStorage.removeItem("token");
  localStorage.removeItem("owner");
  localStorage.removeItem("role");
  navigate("/sign-in");
};

//
export const pageTypeData = [
  {
    id: 0,
    title: "Privacy Policy",
  },
  {
    id: 1,
    title: "Terms & Conditions",
  },
  {
    id: 2,
    title: "About Us",
  },
  {
    id: 3,
    title: "General",
  },
];

// status data - student enrollment
export const enrollmentStatusData = [
  { title: "Enrolled", value: "Enrolled" },
  { title: "Transferred", value: "Transferred" },
  { title: "Withdrawn", value: "Withdrawn" },
  { title: "Dropped Out", value: "Dropped Out" },
  { title: "Promoted", value: "Promoted" },
  { title: "Completed", value: "Completed" },
];

export const roleData = [
  { title: "Admin", value: 0 },
  { title: "Teacher", value: 1 },
  { title: "Student", value: 2 },
  { title: "Office Staff", value: 3 },
];

/**
 * Utility function to generate and download a PDF report.
 * @param {string} title - The title of the report (e.g., 'Budget Report')
 * @param {Object} reportModalData - The data to display in the table (key-value pairs)
 * @param {Object} options - Additional options for customization (e.g., date format)
 */
export const generatePDFReport = (title, reportModalData, options = {}) => {
  const { dateFormat = "en-GB" } = options;

  const pdf = new jsPDF("p", "pt", "a4");
  const pageWidth = pdf.internal.pageSize.getWidth(); // Get the page width

  // Add logo to PDF (Centered)
  const img = new Image();
  img.src = logo;
  const imgWidth = 60; // Adjust image width
  const imgHeight = 60; // Adjust image height
  const imgX = (pageWidth - imgWidth) / 2; // Calculate x to center the image
  pdf.addImage(img, "PNG", imgX, 30, imgWidth, imgHeight); // Centered logo

  // Add title (Centered)
  pdf.setFontSize(24);
  const schoolTitle = "Wisdom International School";
  const titleWidth = pdf.getTextWidth(schoolTitle);
  pdf.text(schoolTitle, (pageWidth - titleWidth) / 2, 115); // Center the title

  // Add subheading (Centered)
  pdf.setFontSize(12);
  const subheading = "Academic Year: (2024 - 2025)";
  const subheadingWidth = pdf.getTextWidth(subheading);
  pdf.text(subheading, (pageWidth - subheadingWidth) / 2, 140); // Center the subheading

  // Add report title and current date
  const currentDate = new Date().toLocaleDateString(dateFormat, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  pdf.setFontSize(14);

  // Report title (Left-aligned)
  pdf.text(`${title}`, 40, 180);

  // Date (Right-aligned)
  const dateX = pageWidth - 80 - pdf.getTextWidth(currentDate);
  pdf.text(`Date: ${currentDate}`, dateX, 180);

  // Add a horizontal line
  pdf.line(40, 190, pageWidth - 40, 190); // Dynamic line width based on page width

  // Table Configuration
  const cellPadding = 5;
  pdf.setFontSize(14);
  const rowHeight = 20;
  const startX = 40;
  const startY = 220;
  const col1Width = 257;
  const col2Width = 257;

  // Draw table headers (centered with padding)
  pdf.setFontSize(12);
  pdf.text(
    "Field",
    startX + col1Width / 2,
    startY + rowHeight / 2 + cellPadding,
    {
      align: "center",
    }
  );
  pdf.text(
    "Value",
    startX + col1Width + col2Width / 2,
    startY + rowHeight / 2 + cellPadding,
    {
      align: "center",
    }
  );

  // Add borders for headers
  pdf.rect(startX, startY, col1Width, rowHeight); // Field header
  pdf.rect(startX + col1Width, startY, col2Width, rowHeight); // Value header

  // Draw table rows dynamically with centered content and padding
  let yPosition = startY + rowHeight; // Start below the header
  Object.entries(reportModalData).forEach(([key, value]) => {
    // Field cell (centered with padding)
    pdf.text(
      `${key.replace(/_/g, " ")}`,
      startX + col1Width / 2,
      yPosition + rowHeight / 2 + cellPadding,
      {
        align: "center",
      }
    );
    // Value cell (centered with padding)
    pdf.text(
      `${value}`,
      startX + col1Width + col2Width / 2,
      yPosition + rowHeight / 2 + cellPadding,
      {
        align: "center",
      }
    );

    // Draw borders for each row
    pdf.rect(startX, yPosition, col1Width, rowHeight); // Field column border
    pdf.rect(startX + col1Width, yPosition, col2Width, rowHeight); // Value column border

    yPosition += rowHeight; // Move to next row
  });

  // Save the PDF
  pdf.save(`${title.replace(/\s/g, "_")}.pdf`);
};

export const paymentMethodData = [
  { title: "Cash", value: 0 },
  { title: "Bank Transfer", value: 1 },
  { title: "Online Payment", value: 2 },
  { title: "Cheque", value: 3 },
];
