import { createSliceFactory } from "../feature-components/sliceFactory";

const { reducer, fetchAll, fetchSingle, postData, updateData, error } =
  createSliceFactory({
    name: "examTypes",
    url: "exam/admin/exam-types/",
    fetchParams: (params) => {
      return params;
    },
  });

export { fetchAll, fetchSingle, postData, updateData, error };
export default reducer;
