import { createSliceFactory } from "../feature-components/sliceFactory";

const { reducer, fetchAll, fetchSingle, postData, updateData, error } =
  createSliceFactory({
    name: "salarySlips",
    url: "payroll/admin/salary-slips/",
    fetchParams: (params) => {
      return params;
    },
  });

export { fetchAll, fetchSingle, postData, updateData, error };
export default reducer;
