import React, { useEffect, useState } from "react";
import usePageTitle from "../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll } from "../../libs/redux/features/learning/studyMaterialsSlice";
import { Link } from "react-router-dom";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../components/utility";
import BreadBtn from "../../components/BreadBtn";
import DataConversion from "../../components/DataConversion";
import { Pagination, Table } from "antd";
import dayjs from "dayjs";

const StudyMaterials = () => {
  usePageTitle("Study Materials");

  const breadcrumbitems = [
    {
      title: "Learning",
    },
    { title: "Study Materials" },
  ];

  const dispatch = useDispatch();
  const studyMaterialsData = useSelector((state) => state.studyMaterials.data);
  const totalItems = useSelector((state) => state.studyMaterials.totalItems);
  const isLoading = useSelector((state) => state.studyMaterials.isLoading);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
  });

  useEffect(() => {
    dispatch(fetchAll(filters));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Title: item.title || "N/A",
    "Upload Date": item.upload_date || "N/A",
    "Academic Year": item.academic_year || "N/A",
    Class: item.class_level || "N/A",
    Course: item.course || "N/A",
    Teacher: item.teacher || "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Title",
      "Upload Date",
      "Academic Year",
      "Class",
      "Course",
      "Teacher",
    ];
    downloadPDF(
      studyMaterialsData,
      columns,
      formatTimeSlotsData,
      "Study Materials Data"
    );
  };

  const handleDownloadXLS = () => {
    downloadXLS(
      studyMaterialsData,
      formatTimeSlotsData,
      "Study Materials Data"
    );
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Upload Date",
      dataIndex: "upload_date",
      render: (date) => dayjs(date).format("YYYY-MM-DD"),
    },
    {
      title: "Course",
      dataIndex: "course",
    },
    {
      title: "Class",
      dataIndex: "class_level",
    },
    {
      title: "Teacher",
      dataIndex: "teacher",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/learning/study-materials/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.title}`,
                dispatch,
                `learning/admin/study-materials/${record.id}/`,
                fetchAll,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <BreadBtn
        headerTitle="Study Materials"
        items={breadcrumbitems}
        addPath="/learning/study-materials/add-study-material"
      />
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3">
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={studyMaterialsData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default StudyMaterials;
