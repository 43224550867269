import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData } from "../../../axios/axiosInstance";

export const fetchSections = createAsyncThunk(
  "sections/fetchSections",
  async (
    { class_level, name = "", limit = 30, offset = 0, is_active } = {},
    { rejectWithValue }
  ) => {
    try {
      const params = {
        limit,
        offset,
      };

      if (name !== "") {
        params.name = name;
      }
      if (class_level !== undefined) {
        params.class_level = class_level;
      }
      if (is_active !== undefined) {
        params.is_active = is_active;
      }
      console.log("section params", params);

      const response = await getData("academic/admin/sections/", params);
      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const sectionSlice = createSlice({
  name: "sections",
  initialState: {
    data: [],
    isLoading: false,
    offset: 0,
    limit: 10,
    totalItems: 0,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSections.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchSections.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.totalItems = 0;
        state.error = action.payload;
      });
  },
});

export default sectionSlice.reducer;
