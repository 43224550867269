import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

// Fetch teachers with optional filters (search, department, designations)
export const fetchTeachers = createAsyncThunk(
  "teachers/fetchTeachers",
  async (
    {
      search = "",
      limit = 10,
      offset = 0,
      is_active,
      department,
      designations,
    } = {},
    { rejectWithValue }
  ) => {
    try {
      const params = {
        limit,
        offset,
        search,
      };

      if (is_active !== undefined) {
        params.is_active = is_active;
      }
      if (department !== undefined) {
        params.department = department;
      }
      if (designations !== undefined) {
        params.designations = designations;
      }
      console.log("params", params);

      const response = await getData("teacher/admin/teachers/", params);
      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch a single teacher by ID
export const fetchSingleTeacher = createAsyncThunk(
  "teachers/fetchSingleTeacher",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`teacher/admin/teachers/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Post a new teacher
export const postTeacher = createAsyncThunk(
  "teachers/postTeacher",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await postData("teacher/admin/teachers/", payload);
      if (response.status === 200 || 201) {
        message.success("Teacher created successfully!");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.user?.email ||
          error.response.data.user?.mobile ||
          error.response.data.user?.password ||
          error.response.data.user?.confirm_password ||
          error.response.data?.employee_id ||
          "Failed to post teacher.";
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Update an existing teacher by ID
export const putTeacher = createAsyncThunk(
  "teachers/putTeacher",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await putData(`teacher/admin/teachers/${id}/`, payload);
      if (response.status === 200 || 201) {
        message.success("Teacher updated successfully!");
        fetchSingleTeacher(id);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.user?.email ||
          error.response.data.user?.mobile ||
          error.response.data.user?.password ||
          error.response.data.user?.confirm_password ||
          error.response.data?.employee_id ||
          "Failed to update teacher.";
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Teachers slice
const teachersSlice = createSlice({
  name: "teachers",
  initialState: {
    data: [],
    singleData: {},
    isLoading: false,
    offset: 0,
    limit: 10,
    totalItems: 0,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeachers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTeachers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchTeachers.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = action.payload;
      })
      .addCase(fetchSingleTeacher.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleTeacher.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleData = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleTeacher.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postTeacher.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTeacher.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postTeacher.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(putTeacher.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putTeacher.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(putTeacher.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default teachersSlice.reducer;
