import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "./ProtectedRoutes";
import Students from "../view/student-data/students/Students";
import StudentForm from "../view/student-data/students/StudentForm";
import StudentAttendances from "../view/student-data/student-attendance/StudentAttendances";
import StudentAttendanceForm from "../view/student-data/student-attendance/StudentAttendanceForm";
import Enrollments from "../view/student-data/enrollment/Enrollments";
import EnrollmentForm from "../view/student-data/enrollment/EnrollmentForm";
import PromotionRecord from "../view/student-data/promotion/PromotionRecord";
import PromotionRecordForm from "../view/student-data/promotion/PromotionRecordForm";

export default function StudentsRoutes() {
  return (
    <Routes>
      <Route
        path="/students"
        element={<ProtectedRoutes Component={Students} />}
      />
      <Route
        path="/students/add-student"
        element={<ProtectedRoutes Component={StudentForm} />}
      />
      <Route
        path="/students/:id"
        element={<ProtectedRoutes Component={StudentForm} />}
      />
      {/* student attendance */}
      <Route
        path="/student-attendances"
        element={<ProtectedRoutes Component={StudentAttendances} />}
      />
      <Route
        path="/student-attendances/add-attendance"
        element={<ProtectedRoutes Component={StudentAttendanceForm} />}
      />
      {/* enrollments */}
      <Route
        path="/enrollments"
        element={<ProtectedRoutes Component={Enrollments} />}
      />
      <Route
        path="/enrollments/new-enrollment"
        element={<ProtectedRoutes Component={EnrollmentForm} />}
      />
      <Route
        path="/enrollments/:id"
        element={<ProtectedRoutes Component={EnrollmentForm} />}
      />
      {/* promotion records */}
      <Route
        path="/promotion-records"
        element={<ProtectedRoutes Component={PromotionRecord} />}
      />
      <Route
        path="/promotion-records/new-promotion"
        element={<ProtectedRoutes Component={PromotionRecordForm} />}
      />
      <Route
        path="/promotion-records/:id"
        element={<ProtectedRoutes Component={PromotionRecordForm} />}
      />
    </Routes>
  );
}
