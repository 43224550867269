import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchAcademicYear } from "../../../libs/redux/features/academic/academicYearNewSlice";
import {
  Breadcrumb,
  Button,
  Switch,
  Table,
  DatePicker,
  Pagination,
  Select,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  handleStatusChange,
  signOutFunc,
} from "../../../components/utility";
import moment from "moment";
import usePageTitle from "../../../components/PageTitle";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";

const { RangePicker } = DatePicker;
const { Option } = Select;

const AcademicYear = () => {
  usePageTitle("Academic Years");
  const dispatch = useDispatch();
  const { data, totalItems, isLoading, error } = useSelector(
    (state) => state.academicYearsNew
  );

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    start_date: null,
    end_date: null,
    is_active: "all",
    is_default: "all",
  });

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };

    if (filters.start_date && filters.end_date) {
      params.start_date = filters.start_date;
      params.end_date = filters.end_date;
    }
    if (filters.is_active !== "all") {
      params.is_active = filters.is_active;
    }
    if (filters.is_default !== "all") {
      params.is_default = filters.is_default;
    }
    dispatch(fetchAcademicYear(params));
  }, [dispatch, filters]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Year",
      dataIndex: "year",
      render: (year, record) => {
        const isDefault = record.is_default;

        return isDefault ? `${year} ( Default )` : year;
      },
    },
    {
      title: "Start Date",
      dataIndex: "start_date",

      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
    {
      title: "End Date",
      dataIndex: "end_date",

      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
    {
      title: "No. of Terms",
      dataIndex: "term_count",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      width: 150,
      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) =>
            handleStatusChange(
              checked,
              record,
              dispatch,
              `academic/admin/academic-years/${record.id}/`,
              fetchAcademicYear,
              filters
            )
          }
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/academic/academic-years/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.start_date} - ${record.end_date}`,
                dispatch,
                `academic/admin/academic-years/${record.id}/`,
                fetchAcademicYear,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    "Start Date": item.start_date || "N/A",
    "End Date": item.end_date || "N/A",
    Status: item.is_active ? "Active" : "Inactive",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Start Date", "End Date", "Status"];
    downloadPDF(data, columns, formatTimeSlotsData, "Academic Years Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Academic Years Data");
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (error && error.detail === "Invalid token.") {
      signOutFunc(navigate);
    }
  }, [error, navigate]);

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Academic Years</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    {" "}
                    <HomeOutlined />
                  </Link>
                ),
              },
              { title: "Academic" },
              { title: "Academic Years" },
            ]}
          />
        </div>
        <div>
          <Link to="/academic/academic-years/add-new">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10 shadow-sm-2">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <RangePicker
              className="w-100-r"
              format="YYYY-MM-DD"
              placeholder={["Start Date", "End Date"]}
              onChange={(dates, dateStrings) => {
                handleFilterChange("start_date", dateStrings[0] || null);
                handleFilterChange("end_date", dateStrings[1] || null);
              }}
              onClear={() => {
                handleFilterChange("start_date", null);
                handleFilterChange("end_date", null);
              }}
              allowClear
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) =>
                setFilters((prev) => ({
                  ...prev,
                  is_active: value,
                }))
              }
            >
              <Option value="all">Filter by All Status</Option>
              <Option value="true">Active</Option>
              <Option value="false">Inactive</Option>
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) =>
                setFilters((prev) => ({
                  ...prev,
                  is_default: value,
                }))
              }
            >
              <Option value="all">Filter by Default Year</Option>
              <Option value="true">Yes</Option>
              <Option value="false">No</Option>
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default AcademicYear;
