import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Select,
  Spin,
  TimePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../components/PageTitle";
import { Link, useParams } from "react-router-dom";
import {
  fetchSingle,
  updateData,
  postData,
} from "../../libs/redux/features/learning/onlineClassesSlice";
import { useDispatch, useSelector } from "react-redux";
import BreadBtn from "../../components/BreadBtn";
import PageCard from "../../components/PageCard";
import { fetchAll as fetchClasses } from "../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchCourses } from "../../libs/redux/features/academic/coursesNewSlice";
import { fetchAll as fetchTeachers } from "../../libs/redux/features/teacher-info/teachersNewSlice";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;
const { RangePicker } = TimePicker;

const OnlineClassesForm = () => {
  const breadcrumbitems = [
    {
      title: "Learning",
    },
    { title: <Link to="/learning/online-classes">Online Classes</Link> },
    {
      title: "Add Class",
    },
  ];

  const { id } = useParams();

  usePageTitle(id ? "Online Class Detail" : "Add Online Classes");

  const dispatch = useDispatch();
  const singleData = useSelector((state) => state.onlineClasses.singleData);
  const isLoading = useSelector((state) => state.onlineClasses.isLoading);
  const error = useSelector((state) => state.onlineClasses.error);

  const classesData = useSelector((state) => state.classesNew.data);
  const coursesData = useSelector((state) => state.coursesNew.data);
  const teachersData = useSelector((state) => state.teachersNew.data);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
    if (teachersData.length === 0) {
      dispatch(fetchTeachers());
    }
  }, [dispatch, classesData.length, teachersData.length]);

  const [onlineClass, setOnlineClass] = useState({
    meeting_link: "",
    scheduled_date: "",
    start_time: "",
    end_time: "",
    description: "",
    class_level: null,
    course: null,
    teacher: null,
  });

  useEffect(() => {
    if (onlineClass.class_level) {
      dispatch(fetchCourses({ class_level: onlineClass.class_level }));
    } else {
      dispatch(fetchCourses());
    }
  }, [dispatch, onlineClass.class_level]);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingle(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setOnlineClass({
        meeting_link: singleData.meeting_link || "",
        scheduled_date: dayjs(singleData.scheduled_date) || "",
        start_time: singleData.start_time || "",
        end_time: singleData.end_time || "",
        description: singleData.description || "",
        class_level: singleData.class_level || null,
        course: singleData.course || null,
        teacher: singleData.teacher || null,
      });
    }
  }, [id, singleData]);

  const handleTimeChange = (times) => {
    const [start, end] = times || [];
    setOnlineClass((prev) => ({
      ...prev,
      start_time: start ? start.format("HH:mm") : "",
      end_time: end ? end.format("HH:mm") : "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      ...onlineClass,
      scheduled_date: dayjs(onlineClass.scheduled_date).format("YYYY-MM-DD"),
      academic_year: 23,
    };
    console.log("payload", payload);
    if (id) {
      dispatch(updateData({ id, payload: payload }));
    } else {
      dispatch(
        postData({
          payload: payload,
          onSuccess: () => {
            message.success("New online class added successfully!");
            setOnlineClass((prev) => ({
              ...prev,
              meeting_link: "",
              scheduled_date: "",
              start_time: "",
              end_time: "",
              description: "",
            }));
          },
        })
      );
    }
  };

  return (
    <div>
      <BreadBtn headerTitle="Add Online Class" items={breadcrumbitems} />
      <PageCard
        title="Online Class Information"
        content={
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Class</p>
                      {error && error.class_level ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.class_level})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      className="w-100"
                      size="large"
                      value={onlineClass.class_level || null}
                      placeholder="Select Class"
                      onChange={(value) =>
                        setOnlineClass((prev) => ({
                          ...prev,
                          class_level: value,
                        }))
                      }
                      status={error && error.class_level ? "error" : ""}
                    >
                      {classesData && classesData.length > 0
                        ? classesData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Course</p>
                      {error && error.course ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.course})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      className="w-100"
                      size="large"
                      value={onlineClass.course || null}
                      placeholder="Select Course"
                      onChange={(value) =>
                        setOnlineClass((prev) => ({
                          ...prev,
                          course: value,
                        }))
                      }
                      status={error && error.course ? "error" : ""}
                    >
                      {coursesData && coursesData.length > 0
                        ? coursesData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Teacher</p>
                      {error && error.teacher ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.teacher})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      showSearch
                      className="w-100"
                      size="large"
                      placeholder="Select Teacher"
                      optionFilterProp="children"
                      value={onlineClass.teacher || null}
                      onChange={(value) =>
                        setOnlineClass((prev) => ({
                          ...prev,
                          teacher: value,
                        }))
                      }
                      allowClear
                      onClear={() =>
                        setOnlineClass((prev) => ({
                          ...prev,
                          teacher: null,
                        }))
                      }
                      status={error && error.teacher ? "error" : ""}
                    >
                      {teachersData && teachersData.length > 0
                        ? teachersData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.user.first_name} {item.user.last_name}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Time Range</p>
                      {error && (error.start_time || error.end_time) ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.start_time || error.end_time})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <RangePicker
                      className="w-100"
                      size="large"
                      format="HH:mm"
                      value={
                        onlineClass.start_time && onlineClass.end_time
                          ? [
                              dayjs(onlineClass.start_time, "HH:mm"),
                              dayjs(onlineClass.end_time, "HH:mm"),
                            ]
                          : null
                      }
                      onChange={handleTimeChange}
                      status={
                        error && (error.start_time || error.end_time)
                          ? "error"
                          : ""
                      }
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Meeting Link</p>
                      {error && error.meeting_link ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.meeting_link})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Meeting Link"
                      required
                      value={onlineClass.meeting_link}
                      onChange={(e) =>
                        setOnlineClass((prev) => ({
                          ...prev,
                          meeting_link: e.target.value,
                        }))
                      }
                      status={error && error.meeting_link ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Scheduled Date</p>
                      {error && error.scheduled_date ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.scheduled_date})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <DatePicker
                      className="w-100"
                      size="large"
                      placeholder="Scheduled Date"
                      required
                      value={
                        onlineClass.scheduled_date
                          ? dayjs(onlineClass.scheduled_date)
                          : ""
                      }
                      onChange={(e) =>
                        setOnlineClass((prev) => ({
                          ...prev,
                          scheduled_date: e
                            ? dayjs(e).format("YYYY-MM-DD")
                            : "",
                        }))
                      }
                      status={error && error.scheduled_date ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Description</p>
                      {error && error.description ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.description})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <TextArea
                      className="w-100"
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder="Description"
                      required
                      value={onlineClass.description}
                      onChange={(e) =>
                        setOnlineClass((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                      status={error && error.description ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <Button
                    className="w-100 mt-3"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {id ? "Update" : "Submit"}
                  </Button>
                </Col>
              </Row>
            </form>
          </Spin>
        }
      />
    </div>
  );
};

export default OnlineClassesForm;
