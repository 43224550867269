import {
  Breadcrumb,
  Button,
  DatePicker,
  Input,
  Modal,
  Pagination,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchExpenseTypes } from "../../../libs/redux/features/accounting/expenseTypesSlice";
import { fetchAll as fetchExpenses } from "../../../libs/redux/features/accounting/espenseSlice";
import {
  downloadPDF,
  downloadXLS,
  generatePDFReport,
  handleDelete,
  paymentMethodData,
  renderDescription,
} from "../../../components/utility";
import {
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import DataConversion from "../../../components/DataConversion";
import dayjs from "dayjs";
import usePageTitle from "../../../components/PageTitle";
import { getData } from "../../../libs/axios/axiosInstance";
import { fetchAll as fetchAcademicYear } from "../../../libs/redux/features/academic/academicYearNewSlice";
import { Link } from "react-router-dom";
import ExpenseModal from "./ExpenseModal";

const { Option } = Select;
const { Search } = Input;

const Expense = () => {
  usePageTitle("Expenses");

  const breadcrumbitems = [
    {
      title: (
        <Link to="/">
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: "Accounting",
    },
    { title: "Expenses" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const expensesData = useSelector((state) => state.expenses.data);
  const totalItems = useSelector((state) => state.expenses.totalItems);
  const isLoading = useSelector((state) => state.expenses.isLoading);

  const expenseTypes = useSelector((state) => state.expenseType.data);
  const academicYearData = useSelector((state) => state.academicYearsNew.data);

  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (expenseTypes.length === 0) {
      dispatch(fetchExpenseTypes());
    }
    if (academicYearData.length === 0) {
      dispatch(fetchAcademicYear());
    }
  }, [dispatch, expenseTypes.length, academicYearData.length]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    expense_type: "all",
    date_from: "",
    date_to: "",
    search: "",
    academic_year: "all",
    payment_method: "all",
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.expense_type !== "all") {
      params.expense_type = filters.expense_type;
    }
    if (filters.academic_year !== "all") {
      params.academic_year = filters.academic_year;
    }
    if (filters.date_from !== "") {
      params.date_from = filters.date_from;
    }
    if (filters.date_to !== "") {
      params.date_to = filters.date_to;
    }
    if (filters.search !== "") {
      params.search = filters.search;
    }
    if (filters.payment_method !== "all") {
      params.payment_method = filters.payment_method;
    }

    dispatch(fetchExpenses(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => {
    const expenseTypeItem =
      expenseTypes &&
      expenseTypes.find((types) => types.id === item.expense_type)?.name;

    const paymentMethod = paymentMethodData.find(
      (method) => method.value === item.payment_method
    )?.title;

    return {
      Serial: index + 1,
      Title: item.title || "N/A",
      Description: item.description || "N/A",
      Amount: item.amount || "N/A",
      "Payment Method": paymentMethod || "N/A",
      Date: item.date || "N/A",
      "Expense Type": expenseTypeItem || "N/A",
    };
  };

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Title",
      "Description",
      "Amount",
      "Payment Method",
      "Date",
      "Expense Type",
    ];
    downloadPDF(expensesData, columns, formatTimeSlotsData, "Expenses Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(expensesData, formatTimeSlotsData, "Expenses Data");
  };

  const [id, setId] = useState(null);

  const fetchSingleItem = (data) => {
    setModalData({
      title: data.title,
      description: data.description,
      date: data.date ? dayjs(data.date) : null,
      amount: data.amount,
      expense_type: data.expense_type,
      payment_method: data.payment_method,
    });
    setId(data.id);
    setAddModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => renderDescription(text),
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Expense Type",
      dataIndex: "expense_type",
      render: (type) =>
        expenseTypes && expenseTypes.find((item) => item.id === type)?.name,
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Button className="me-4 p-2" onClick={() => fetchSingleItem(record)}>
            <EyeOutlined />
          </Button>
          <div
            onClick={() =>
              handleDelete(
                `${record.title}`,
                dispatch,
                `accounting/admin/expenses/${record.id}/`,
                fetchExpenses,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handleModalCancel = () => {
    setAddModal(false);
    if (id) {
      setId(null);
    }
    setModalData({});
  };

  const [reportModal, setReportModal] = useState(false);
  const [reportModalData, setReportModalData] = useState({});
  const [reportLoading, setReportLoading] = useState(false);

  const reportModalClick = async () => {
    setReportLoading(true);
    try {
      const response = await getData(
        "accounting/admin/expenses/total-expenses/"
      );
      if (response.status === 200) {
        setReportModalData(response.data);
        setReportLoading(false);
        setReportModal(true);
      }
    } catch (error) {
      console.log("error getting all expenses", error.response);
      setReportLoading(false);
    }
  };

  const handleReportModalCancel = () => {
    setReportModal(false);
    setReportLoading(false);
    setReportModalData({});
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Expenses</h1>
          <Breadcrumb className="fs-5" items={breadcrumbitems} />
        </div>
        <div>
          <Button
            className="me-3"
            size="large"
            onClick={reportModalClick}
            loading={reportLoading}
          >
            Total Expenses
          </Button>
          <Button
            onClick={() => setAddModal(true)}
            type="primary"
            icon={<PlusOutlined />}
            size="large"
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search..."
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
            />
            <Select
              className="w-200"
              value={filters.expense_type}
              onChange={(value) => handleFilterChange("expense_type", value)}
            >
              <Option value="all">Filter by All Expense Types</Option>
              {expenseTypes && expenseTypes.length > 0
                ? expenseTypes.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              className="w-200"
              value={filters.academic_year}
              onChange={(value) => handleFilterChange("academic_year", value)}
            >
              <Option value="all">All Academic Years</Option>
              {academicYearData && academicYearData.length > 0
                ? academicYearData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.year}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              className="w-200"
              value={filters.payment_method}
              onChange={(value) => handleFilterChange("payment_method", value)}
            >
              <Option value="all">All Payment Methods</Option>
              {paymentMethodData.map((item) => (
                <Option value={item.value} key={item.value}>
                  {item.title}
                </Option>
              ))}
            </Select>
            <DatePicker
              placeholder="Date From"
              className="w-200"
              value={filters.date_from ? dayjs(filters.date_from) : null}
              onChange={(value) =>
                handleFilterChange(
                  "date_from",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
            <DatePicker
              placeholder="Date To"
              className="w-200"
              value={filters.date_to ? dayjs(filters.date_to) : null}
              onChange={(value) =>
                handleFilterChange(
                  "date_to",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={expensesData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* add and edit modal */}
      {addModal && (
        <>
          <ExpenseModal
            open={addModal}
            onClose={handleModalCancel}
            id={id || null}
            data={modalData || {}}
          />
        </>
      )}

      {reportModal && (
        <Modal
          title={<h2 className="fs-3 mb-3 text-dark">Total Expenses</h2>}
          open={reportModal}
          onCancel={handleReportModalCancel}
          onOk={() => generatePDFReport("Total Expense", reportModalData)}
          okText="Download"
        >
          <table
            style={{
              marginTop: 30,
              width: "100%",
              borderCollapse: "collapse",
              textAlign: "left",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "#f0f0f0",
                  borderBottom: "2px solid #ccc",
                }}
              >
                <th style={{ padding: 10, border: "1px solid #ccc" }}>Field</th>
                <th style={{ padding: 10, border: "1px solid #ccc" }}>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(reportModalData).length > 0 ? (
                Object.entries(reportModalData).map(([key, value]) => (
                  <tr key={key}>
                    <td
                      style={{
                        padding: 10,
                        border: "1px solid #ccc",
                        textTransform: "capitalize",
                      }}
                    >
                      {key.replace(/_/g, " ")}
                    </td>
                    <td style={{ padding: 10, border: "1px solid #ccc" }}>
                      {value}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      padding: 10,
                      textAlign: "center",
                      border: "1px solid #ccc",
                    }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal>
      )}
    </div>
  );
};

export default Expense;
