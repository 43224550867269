import "./App.css";
import AppRoutes from "./routes/appRoutes";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#2b3990",
          itemBorderRadius: 0,
        },
      }}
    >
      <AppRoutes />
    </ConfigProvider>
  );
}

export default App;
