import React, { useEffect, useState } from "react";
import BreadBtn from "../../../components/BreadBtn";
import { Link, useParams } from "react-router-dom";
import usePageTitle from "../../../components/PageTitle";
import PageCard from "../../../components/PageCard";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { fetchAll as fetchAcademicYear } from "../../../libs/redux/features/academic/academicYearNewSlice";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchSections } from "../../../libs/redux/features/academic/sectionsNewSlice";
import { fetchAll as fetchStudents } from "../../../libs/redux/features/student-info/studentsSlice";
import { enrollmentStatusData } from "../../../components/utility";
import dayjs from "dayjs";
import {
  fetchSingle,
  postData,
  patchData,
} from "../../../libs/redux/features/student-info/enrollmentSlice";

const { Option } = Select;

const EnrollmentForm = () => {
  const breadcrumbitems = [
    { title: "Student Data" },
    { title: <Link to="/student-data/enrollments">Enrollments</Link> },
    { title: "New Enrollment" },
  ];

  const { id } = useParams();

  const dispatch = useDispatch();
  const singleData = useSelector((state) => state.enrollments.singleData);
  const isLoading = useSelector((state) => state.enrollments.isLoading);
  const error = useSelector((state) => state.enrollments.error);

  // const academicYearData = useSelector((state) => state.academicYearsNew.data);
  const classesData = useSelector((state) => state.classesNew.data);
  const sectionsData = useSelector((state) => state.sectionsNew.data);
  const studentsData = useSelector((state) => state.students.data);

  useEffect(() => {
    // if (academicYearData.length === 0) {
    //   dispatch(fetchAcademicYear());
    // }
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
  }, [dispatch, classesData.length]);

  const [enrollment, setEnrollment] = useState({
    roll_number: "",
    enrollment_date: "",
    student: null,
    status: "Enrolled",
    school_class: null,
    section: null,
    is_active: true,
  });

  useEffect(() => {
    if (enrollment.school_class) {
      dispatch(fetchSections({ class_level: enrollment.school_class }));
      dispatch(fetchStudents({ school_class: enrollment.school_class }));
    } else {
      dispatch(fetchSections());
      dispatch(fetchStudents());
    }
  }, [dispatch, enrollment.school_class]);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingle(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setEnrollment({
        roll_number: singleData.roll_number,
        enrollment_date: singleData.enrollment_date
          ? dayjs(singleData.enrollment_date)
          : null,
        student: singleData.student,
        status: singleData.status,
        school_class: singleData.school_class,
        section: singleData.section,
        is_active: singleData.is_active,
      });
    }
  }, [id, singleData]);

  usePageTitle(id ? "Enrollment Detail" : "New Enrollment");

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      ...enrollment,
      enrollment_date: dayjs(enrollment.enrollment_date).format("YYYY-MM-DD"),
      academic_year: 23,
    };

    if (id) {
      delete payload.roll_number;
      console.log("enrollment", payload);
      dispatch(patchData({ id, payload }));
    } else {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New enrollment created successfully!");
          },
        })
      );
    }
  };
  return (
    <div>
      <BreadBtn headerTitle="New Enrollment" items={breadcrumbitems} />
      <PageCard
        title="Enrollment Information"
        content={
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Row>
                {/* <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Academic Year</p>
                      {error && error.academic_year ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.academic_year})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      className="w-100"
                      size="large"
                      value={enrollment.academic_year}
                      onChange={(value) =>
                        setEnrollment((prev) => ({
                          ...prev,
                          academic_year: value,
                        }))
                      }
                      status={error && error.academic_year ? "error" : ""}
                    >
                      {academicYearData && academicYearData.length > 0
                        ? academicYearData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.year}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col> */}

                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Class</p>
                      {error && error.school_class ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.school_class})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      className="w-100"
                      size="large"
                      placeholder="Select Class"
                      value={enrollment.school_class}
                      onChange={(value) =>
                        setEnrollment((prev) => ({
                          ...prev,
                          school_class: value,
                        }))
                      }
                      status={error && error.school_class ? "error" : ""}
                    >
                      {classesData && classesData.length > 0
                        ? classesData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Section</p>
                      {error && error.section ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.section})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      className="w-100"
                      placeholder="Select Section"
                      size="large"
                      value={enrollment.section}
                      onChange={(value) =>
                        setEnrollment((prev) => ({
                          ...prev,
                          section: value,
                        }))
                      }
                      status={error && error.section ? "error" : ""}
                    >
                      {sectionsData && sectionsData.length > 0
                        ? sectionsData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Student</p>
                      {error && error.student ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.student})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      showSearch
                      className="w-100"
                      size="large"
                      placeholder="Select Student"
                      optionFilterProp="children"
                      value={enrollment.student || null}
                      onChange={(value) =>
                        setEnrollment((prev) => ({
                          ...prev,
                          student: value,
                        }))
                      }
                      allowClear
                      onClear={() =>
                        setEnrollment((prev) => ({
                          ...prev,
                          student: null,
                        }))
                      }
                      status={error && error.student ? "error" : ""}
                    >
                      {studentsData && studentsData.length > 0
                        ? studentsData.map((student) => (
                            <Option value={student.id} key={student.id}>
                              {student.user.first_name}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Status</p>
                      {error && error.status ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.status})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      className="w-100"
                      size="large"
                      value={enrollment.status}
                      onChange={(value) =>
                        setEnrollment((prev) => ({
                          ...prev,
                          status: value,
                        }))
                      }
                      status={error && error.status ? "error" : ""}
                    >
                      {enrollmentStatusData.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Roll Number</p>
                      {error && error.roll_number ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.roll_number})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Roll Number"
                      required={!id}
                      disabled={id}
                      value={enrollment.roll_number}
                      onChange={(e) =>
                        setEnrollment((prev) => ({
                          ...prev,
                          roll_number: e.target.value,
                        }))
                      }
                      status={error && error.roll_number ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Enrollment Date</p>
                      {error && error.enrollment_date ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.enrollment_date})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <DatePicker
                      className="w-100"
                      size="large"
                      placeholder="Enrollment Date"
                      required
                      value={
                        enrollment.enrollment_date
                          ? dayjs(enrollment.enrollment_date)
                          : null
                      }
                      onChange={(e) =>
                        setEnrollment((prev) => ({
                          ...prev,
                          enrollment_date: e
                            ? dayjs(e).format("YYYY-MM-DD")
                            : null,
                        }))
                      }
                      status={error && error.enrollment_date ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="my-3">
                    <Checkbox
                      checked={enrollment.is_active}
                      onChange={(e) =>
                        setEnrollment((prev) => ({
                          ...prev,
                          is_active: e.target.checked,
                        }))
                      }
                    >
                      Is Active ?
                    </Checkbox>
                  </div>
                </Col>
                <Col span={24}>
                  <Button
                    className="w-100 mt-3"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {id ? "Update" : "Submit"}
                  </Button>
                </Col>
              </Row>
            </form>
          </Spin>
        }
      />
    </div>
  );
};

export default EnrollmentForm;
