import {
  Breadcrumb,
  Button,
  Input,
  message,
  Modal,
  Pagination,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchPayrolls } from "../../../libs/redux/features/payroll/payrollsSlice";
import {
  fetchAll as fetchPayrollItems,
  postData,
  updateData,
} from "../../../libs/redux/features/payroll/payrollItemsSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  renderDescription,
} from "../../../components/utility";
import {
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import DataConversion from "../../../components/DataConversion";
import TextArea from "antd/es/input/TextArea";
import { Link } from "react-router-dom";

const { Option } = Select;

const PayrollItems = () => {
  usePageTitle("Payroll Items");

  const breadcrumbitems = [
    {
      title: (
        <Link to="/">
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: "Payroll Info",
    },
    { title: "Payroll Item" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const payrollItemsData = useSelector((state) => state.payrollItems.data);
  const totalItems = useSelector((state) => state.payrollItems.totalItems);
  const isLoading = useSelector((state) => state.payrollItems.isLoading);
  const error = useSelector((state) => state.payrollItems.error);

  const payrollsData = useSelector((state) => state.payrolls.data);

  const [modalData, setModalData] = useState({
    payroll: null,
    amount: "",
    item_type: "Bonus",
    description: "",
  });

  useEffect(() => {
    if (payrollsData.length === 0) {
      dispatch(fetchPayrolls());
    }
  }, [dispatch, payrollsData.length]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };

    dispatch(fetchPayrollItems(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => {
    const payrollInfo =
      payrollsData && payrollsData.find((entry) => entry.id === item.payroll);

    return {
      Serial: index + 1,
      Payroll: payrollInfo.id || "N/A",
      "Item Type": item.item_type || "N/A",
      Amount: item.amount || "N/A",
      Description: item.description || "N/A",
    };
  };

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Payroll", "Item Type", "Amount", "Description"];
    downloadPDF(
      payrollItemsData,
      columns,
      formatTimeSlotsData,
      "Payroll Items Data"
    );
  };

  const handleDownloadXLS = () => {
    downloadXLS(payrollItemsData, formatTimeSlotsData, "Payroll Items Data");
  };

  const [id, setId] = useState(null);

  const fetchSingleItem = (data) => {
    setModalData({
      payroll: data.payroll,
      amount: data.amount,
      item_type: data.item_type,
      description: data.description,
    });
    setId(data.id);
    setAddModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Payroll",
      dataIndex: "payroll",
      render: (_, data) => {
        const name =
          payrollsData &&
          payrollsData.find((item) => item.id === data.payroll)?.name;
        return name;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Item Type",
      dataIndex: "item_type",
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => renderDescription(text),
    },

    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Button className="me-4 p-2" onClick={() => fetchSingleItem(record)}>
            <EyeOutlined />
          </Button>
          <div
            onClick={() =>
              handleDelete(
                `amount ${record.amount}`,
                dispatch,
                `payroll/admin/payroll-items/${record.id}/`,
                fetchPayrollItems,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handleModalCancel = () => {
    setAddModal(false);
    if (id) {
      setId(null);
    }
    setModalData((prev) => ({
      ...prev,
      amount: "",
      item_type: "Bonus",
      description: "",
    }));
  };

  const handleModalOk = () => {
    const payload = modalData;
    if (!id) {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New payroll item added successfully!");
            handleModalCancel();
          },
        })
      );
    } else {
      dispatch(updateData({ id, payload }));
    }
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Payroll Item</h1>
          <Breadcrumb className="fs-5" items={breadcrumbitems} />
        </div>
        <div>
          <Button
            onClick={() => setAddModal(true)}
            type="primary"
            icon={<PlusOutlined />}
            size="large"
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3">
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={payrollItemsData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* add and edit modal */}
      {addModal && (
        <Modal
          title={<h2 className="fs-2 mb-3">Final Grade Form</h2>}
          open={addModal}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={isLoading}
              loading={isLoading}
            >
              {id ? "Update" : "Submit"}
            </Button>,
          ]}
          onCancel={handleModalCancel}
        >
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Payroll</p>
              {error && error.payroll ? (
                <p className="text-sm text-danger ms-3">({error.payroll})</p>
              ) : (
                ""
              )}
            </div>
            <Select
              showSearch
              className="w-100"
              size="large"
              placeholder="Select payroll"
              optionFilterProp="children"
              value={modalData.payroll || null}
              onChange={(value) =>
                setModalData((prev) => ({
                  ...prev,
                  payroll: value,
                }))
              }
              allowClear
              onClear={() =>
                setModalData((prev) => ({
                  ...prev,
                  payroll: null,
                }))
              }
              status={error && error.payroll ? "error" : ""}
            >
              {payrollsData && payrollsData.length > 0
                ? payrollsData.map((student) => (
                    <Option value={student.id} key={student.id}>
                      {student.user}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Item Type</p>
              {error && error.item_type ? (
                <p className="text-sm text-danger ms-3">({error.item_type})</p>
              ) : (
                ""
              )}
            </div>
            <Select
              className="w-100"
              size="large"
              value={modalData.item_type}
              onChange={(value) =>
                setModalData((prev) => ({
                  ...prev,
                  item_type: value,
                }))
              }
              status={error && error.item_type ? "error" : ""}
            >
              <Option value="Bonus">Bonus</Option>
              <Option value="Deduction">Deduction</Option>
              <Option value="Allowance">Allowance</Option>
            </Select>
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Amount</p>
              {error && error.amount ? (
                <p className="text-sm text-danger ms-3">({error.amount})</p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Amount"
              required
              value={modalData.amount}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  amount: e.target.value,
                }))
              }
              status={error && error.amount ? "error" : ""}
            />
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Description</p>
              {error && error.description ? (
                <p className="text-sm text-danger ms-3">
                  ({error.description})
                </p>
              ) : (
                ""
              )}
            </div>
            <TextArea
              className="w-100"
              autoSize={{ minRows: 2, maxRows: 5 }}
              placeholder="description"
              required
              value={modalData.description}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              status={error && error.description ? "error" : ""}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PayrollItems;
