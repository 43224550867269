import React, { useEffect, useState } from "react";
import usePageTitle from "../../components/PageTitle";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchClasses } from "../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchCourses } from "../../libs/redux/features/academic/coursesNewSlice";
import { fetchAll as fetchTeachers } from "../../libs/redux/features/teacher-info/teachersNewSlice";
import {
  fetchSingle,
  updateData,
  postData,
} from "../../libs/redux/features/learning/studyMaterialsSlice";
import BreadBtn from "../../components/BreadBtn";
import PageCard from "../../components/PageCard";
import { Button, Col, Input, message, Row, Select, Spin, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import { getDocIcon } from "../../components/utility";
import DocumentPopup from "../../components/DocumentPopup";

const { Option } = Select;

const StudyMaterialForm = () => {
  usePageTitle("Study Materials");

  const breadcrumbitems = [
    {
      title: "Learning",
    },
    { title: <Link to="/academic/study-materials">Study Materials</Link> },
  ];

  const { id } = useParams();
  const dispatch = useDispatch();

  const singleData = useSelector((state) => state.studyMaterials.singleData);
  const error = useSelector((state) => state.studyMaterials.error);
  const isLoading = useSelector((state) => state.studyMaterials.isLoading);

  const classesData = useSelector((state) => state.classesNew.data);
  const coursesData = useSelector((state) => state.coursesNew.data);
  const teachersData = useSelector((state) => state.teachersNew.data);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
    if (teachersData.length === 0) {
      dispatch(fetchTeachers());
    }
  }, [dispatch, classesData.length, teachersData.length]);

  const [studyMaterials, setStudyMaterials] = useState({
    title: "",
    description: "",
    academic_year: null,
    class_level: null,
    teacher: null,
    course: null,
    file: [],
  });

  useEffect(() => {
    if (studyMaterials.class_level) {
      dispatch(fetchCourses({ class_level: studyMaterials.class_level }));
    } else {
      dispatch(fetchCourses());
    }
  }, [dispatch, studyMaterials.class_level]);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingle(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setStudyMaterials({
        title: singleData.title,
        description: singleData.description,
        academic_year: singleData.academic_year,
        class_level: singleData.class_level,
        teacher: singleData.teacher,
        course: singleData.course,
        file: singleData.file,
      });
    }
  }, [id, singleData]);

  const [documentModal, setDocumentModal] = useState(false);

  const handleDocumentClose = (removedItem) => {
    setStudyMaterials((prev) => ({
      ...prev,
      file: prev.file.filter((item) => item.id !== removedItem.id),
    }));
  };

  const handleDocuments = (data) => {
    setStudyMaterials((prev) => ({
      ...prev,
      file: [...prev.file, ...data],
    }));
    setDocumentModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let payload = {
      ...studyMaterials,
      file: studyMaterials.file.map((item) => item.id),
      academic_year: 23,
    };
    console.log("payload", payload);
    if (id) {
      dispatch(updateData({ id, payload }));
    } else {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("Study material created successfully!");
            setStudyMaterials((prev) => ({
              ...prev,
              title: "",
              description: "",
              file: [],
            }));
          },
        })
      );
    }
  };

  return (
    <div>
      <BreadBtn headerTitle="Add Study Material" items={breadcrumbitems} />
      <PageCard
        title="Study Material Information"
        content={
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Title</p>
                      {error && error.title ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.title})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Title"
                      required
                      value={studyMaterials.title}
                      onChange={(e) =>
                        setStudyMaterials((prev) => ({
                          ...prev,
                          title: e.target.value,
                        }))
                      }
                      status={error && error.title ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Description</p>
                      {error && error.description ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.description})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <TextArea
                      className="w-100"
                      autoSize={{ minRows: 2, maxRows: 5 }}
                      placeholder="Description"
                      required
                      value={studyMaterials.description}
                      onChange={(e) =>
                        setStudyMaterials((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                      status={error && error.description ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Class</p>
                      {error && error.class_level ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.class_level})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      className="w-100"
                      size="large"
                      placeholder="Select Class"
                      value={studyMaterials.class_level || null}
                      onChange={(value) =>
                        setStudyMaterials((prev) => ({
                          ...prev,
                          class_level: value,
                        }))
                      }
                      status={error && error.class_level ? "error" : ""}
                    >
                      {classesData && classesData.length > 0
                        ? classesData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Course</p>
                      {error && error.course ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.course})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      className="w-100"
                      size="large"
                      placeholder="Select Course"
                      value={studyMaterials.course || null}
                      onChange={(value) =>
                        setStudyMaterials((prev) => ({
                          ...prev,
                          course: value,
                        }))
                      }
                      status={error && error.course ? "error" : ""}
                    >
                      {coursesData && coursesData.length > 0
                        ? coursesData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col>

                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Teacher</p>
                      {error && error.teacher ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.teacher})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      showSearch
                      className="w-100"
                      size="large"
                      placeholder="Select Teacher"
                      value={studyMaterials.teacher || null}
                      onChange={(value) =>
                        setStudyMaterials((prev) => ({
                          ...prev,
                          teacher: value,
                        }))
                      }
                      optionFilterProp="children"
                      allowClear
                      onClear={() =>
                        setStudyMaterials((prev) => ({
                          ...prev,
                          teacher: null,
                        }))
                      }
                      status={error && error.teacher ? "error" : ""}
                    >
                      {teachersData && teachersData.length > 0
                        ? teachersData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.user.first_name} {item.user.last_name}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col>
                <Col span={24} className="">
                  <div className="mb-2 flex aic">
                    <p className="ps-2 fw-6">* Files</p>
                    {error && error.file ? (
                      <p className="text-sm text-danger ms-3">({error.file})</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex aic">
                    <Button
                      onClick={() => setDocumentModal(true)}
                      className={`flex justify-center aic rounded-10`}
                      style={{ height: 100, width: 100 }}
                      danger={error && error.file ? true : false}
                    >
                      + Upload
                    </Button>
                    <div className="ms-3">
                      {studyMaterials.file && studyMaterials.file.length > 0
                        ? studyMaterials.file.map((item) => (
                            <Tag
                              key={item.id}
                              closable
                              onClose={() => handleDocumentClose(item)}
                              icon={getDocIcon(item.doc_type)}
                              className="m-1 p-2 flex aic"
                              color="success"
                            >
                              {item.title}
                            </Tag>
                          ))
                        : "No file selected"}
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <Button
                    className="w-100 mt-3"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {id ? "Update" : "Submits"}
                  </Button>
                </Col>
              </Row>
            </form>
          </Spin>
        }
      />
      {documentModal && (
        <DocumentPopup
          open={documentModal}
          onCancel={() => setDocumentModal(false)}
          onUploadSuccess={handleDocuments}
          docDescription={studyMaterials.title}
        />
      )}
    </div>
  );
};

export default StudyMaterialForm;
