import React, { useEffect, useState } from "react";
import BreadBtn from "../../../components/BreadBtn";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, message, Modal, Pagination, Table } from "antd";
import {
  fetchAll,
  postData,
  updateData,
} from "../../../libs/redux/features/exams-features/gradesSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../../components/utility";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import DataConversion from "../../../components/DataConversion";

const { Search } = Input;

const Grades = () => {
  usePageTitle("Grades");

  const breadcrumbitems = [
    {
      title: "Exams Data",
    },
    { title: "Grades" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const grades = useSelector((state) => state.grades.data);
  const totalItems = useSelector((state) => state.grades.totalItems);
  const isLoading = useSelector((state) => state.grades.isLoading);
  const error = useSelector((state) => state.grades.error);

  const [modalData, setModalData] = useState({
    letter: "",
    min_percentage: "",
    max_percentage: "",
    weight: "",
  });

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    search: "",
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };

    if (filters.search !== "") {
      params.search = filters.search;
    }
    dispatch(fetchAll(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Letter: item.letter || "N/A",
    "Max Percentage": item.max_percentage || "N/A",
    "Min Percentage": item.min_percentage || "N/A",
    "Grade Point": item.weight || "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Letter",
      "Max Percentage",
      "Min Percentage",
      "Grade Point",
    ];
    downloadPDF(grades, columns, formatTimeSlotsData, "Grades Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(grades, formatTimeSlotsData, "Grades Data");
  };

  const [id, setId] = useState(null);

  const fetchSingleItem = (data) => {
    setModalData({
      letter: data.letter,
      min_percentage: data.min_percentage,
      max_percentage: data.max_percentage,
      weight: data.weight,
    });
    setId(data.id);
    setAddModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Letter",
      dataIndex: "letter",
    },
    {
      title: "Min Percentage",
      dataIndex: "min_percentage",
    },
    {
      title: "Max Percentage",
      dataIndex: "max_percentage",
    },
    {
      title: "Grade Points",
      dataIndex: "weight",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Button className="me-4 p-2" onClick={() => fetchSingleItem(record)}>
            <EyeOutlined />
          </Button>
          <div
            onClick={() =>
              handleDelete(
                `${record.letter}`,
                dispatch,
                `exam/admin/grades/${record.id}/`,
                fetchAll,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handleModalCancel = () => {
    setAddModal(false);
    if (id) {
      setId(null);
    }
    setModalData({
      letter: "",
      min_percentage: "",
      max_percentage: "",
      weight: "",
    });
  };

  const handleModalOk = () => {
    const payload = { ...modalData, academic_year: 23 };
    if (!id) {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New grade added successfully!");
            handleModalCancel();
          },
        })
      );
    } else {
      dispatch(updateData({ id, payload }));
    }
  };

  return (
    <div>
      <BreadBtn
        headerTitle="Grades"
        items={breadcrumbitems}
        addButton={true}
        btnClick={() => setAddModal(true)}
      />
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search..."
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
            />
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>

        <Table
          columns={columns}
          dataSource={grades}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* add and edit modal */}
      {addModal && (
        <Modal
          title={<h2 className="fs-2 mb-3">Grade Data</h2>}
          open={addModal}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={isLoading}
              loading={isLoading}
            >
              {id ? "Update" : "Submit"}
            </Button>,
          ]}
          onCancel={handleModalCancel}
        >
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Letter</p>
              {error && error.letter ? (
                <p className="text-sm text-danger ms-3">({error.letter})</p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Letter"
              required
              value={modalData.letter}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  letter: e.target.value,
                }))
              }
              status={error && error.letter ? "error" : ""}
            />
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Min Percentage</p>
              {error && error.min_percentage ? (
                <p className="text-sm text-danger ms-3">
                  ({error.min_percentage})
                </p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Min Percentage"
              required
              value={modalData.min_percentage}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  min_percentage: e.target.value,
                }))
              }
              status={error && error.min_percentage ? "error" : ""}
            />
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Max Percentage</p>
              {error && error.max_percentage ? (
                <p className="text-sm text-danger ms-3">
                  ({error.max_percentage})
                </p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Max Percentage"
              required
              value={modalData.max_percentage}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  max_percentage: e.target.value,
                }))
              }
              status={error && error.max_percentage ? "error" : ""}
            />
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Grade Point</p>
              {error && error.weight ? (
                <p className="text-sm text-danger ms-3">({error.weight})</p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Weight"
              required
              value={modalData.weight}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  weight: e.target.value,
                }))
              }
              status={error && error.weight ? "error" : ""}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Grades;
