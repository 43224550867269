import React, { useCallback, useEffect, useState } from "react";
import { ImMenu } from "react-icons/im";
import {
  Button,
  Layout,
  Menu,
  theme,
  Dropdown,
  Spin,
  Badge,
  Avatar,
  Select,
  Tooltip,
} from "antd";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import ogo from "../assets/logo.png";
import { sidebrMenus } from "../assets/data/sidebarMenus";
import { HiBell } from "react-icons/hi2";
// import usePageTitle from "../components/PageTitle";
import { MdFullscreen } from "react-icons/md";
import {
  enterFullscreen,
  exitFullscreen,
  signOutFunc,
} from "../components/utility";
import { MdLightMode, MdNightlight } from "react-icons/md";
import { getData } from "../libs/axios/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../libs/redux/features/profile/profileSlice";
import { fetchAll as fetchAcademicYear } from "../libs/redux/features/academic/academicYearNewSlice";

const { Header, Sider, Content } = Layout;
const { Option } = Select;

const MainLayout = () => {
  const role = localStorage.getItem("role");

  const { data, isLoading } = useSelector((state) => state.profile);

  const dispatch = useDispatch();
  const academicData = useSelector((state) => state.academicYearsNew.data);
  const academicLoading = useSelector(
    (state) => state.academicYearsNew.isLoading
  );

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchAcademicYear());
  }, [dispatch]);

  const location = useLocation();
  const navigate = useNavigate();
  // usePageTitle("Home");

  const [academicYear, setAcademicYear] = useState({});

  const fetchAcademicYears = useCallback(async () => {
    try {
      const response = await getData("academic/admin/academic-years/current/");
      if (response.status === 200 || response.status === 201) {
        setAcademicYear(response.data);
        localStorage.setItem("academic_year", response.data.year);
      }
    } catch (error) {
      if (error.response?.data?.detail === "Invalid token.") {
        signOutFunc(navigate);
      }
    }
  }, [navigate]);

  useEffect(() => {
    fetchAcademicYears();
  }, [fetchAcademicYears]);

  const [collapsed, setCollapsed] = useState(window.innerWidth < 991);
  const [openKeys, setOpenKeys] = useState([]);

  const handleResize = () => {
    setCollapsed(window.innerWidth < 991);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const {
    token: { colorPrimary, colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [darkMode, setDarkMode] = useState(true);

  const getSelectedKeys = (pathname) => {
    let keys = [];
    sidebrMenus.forEach((menu) => {
      if (menu.children) {
        menu.children.forEach((subMenu) => {
          if (subMenu.children) {
            subMenu.children.forEach((item) => {
              if (
                item.label &&
                item.label.props &&
                pathname.startsWith(item.label.props.to)
              ) {
                keys = [item.key];
              }
            });
          } else if (
            subMenu.label.props &&
            pathname.startsWith(subMenu.label.props.to)
          ) {
            keys = [subMenu.key];
          }
        });
      } else if (menu.label.props && pathname.startsWith(menu.label.props.to)) {
        keys = [menu.key];
      }
    });
    return keys;
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (latestOpenKey) {
      const rootSubmenuKeys = sidebrMenus.map((item) => item.key);
      if (rootSubmenuKeys.includes(latestOpenKey)) {
        setOpenKeys([latestOpenKey]);
      } else {
        setOpenKeys(keys);
      }
    } else {
      setOpenKeys(keys);
    }
  };

  const handleClick = ({ key }) => {
    if (key === "1") {
      setOpenKeys([]);
    }
  };

  const notificationsData = {
    items: [
      {
        key: "1",
        label: (
          <Link to="/">
            <h5 className="fs-4">Notification Title</h5>
            <p>Lorem ipsum dolor sit amet.lorem eao od...</p>
          </Link>
        ),
      },
      {
        key: "2",
        label: (
          <Link to="/">
            <h5 className="fs-4">Notification Title</h5>
            <p>Lorem ipsum dolor sit amet.lorem eao od...</p>
          </Link>
        ),
      },
      {
        key: "3",
        label: (
          <Link to="/">
            <Button className="w-100">Go to All Notification</Button>
          </Link>
        ),
      },
    ],
  };

  const userNavMenus = {
    items: [
      {
        key: "1",
        label: (
          <Link
            to="/profile"
            className="flex aic py-1 border-b nav-user-menu tr-03"
          >
            <div className="line-height-0" style={{ minWidth: 120 }}>
              <h4 className="fw-7 fs-4 mb-0">
                {(data.first_name && data.first_name) || "name"}
              </h4>
              <p>
                {role === "0" ? "Admin" : role === "1" ? "Teacher" : "Guest"}
              </p>
            </div>
          </Link>
        ),
      },
      {
        key: "2",
        label: <Link className="w-100">Account</Link>,
      },
      {
        key: "3",
        label: (
          <div className="" onClick={() => signOutFunc(navigate)}>
            Sign out
          </div>
        ),
      },
    ],
  };

  const toggleFullscreen = () => {
    if (document.fullscreenElement) {
      exitFullscreen();
    } else {
      enterFullscreen();
    }
  };

  return (
    <Layout>
      <Sider
        theme={darkMode ? "dark" : "light"}
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={275}
        collapsedWidth={60}
        style={{
          height: "100vh",
          overflowY: "scroll",
          borderRight: "1px solid #f6eeee",
          boxShadow: "5px 0 3px -5px #ccc",
        }}
        className="custom-sider"
      >
        <>
          {collapsed ? (
            <Link to="/" className="demo-logo-vertical">
              <img src={ogo} alt="logo" />
            </Link>
          ) : (
            <div className="flex-justify-between mb-3">
              <Link to="/" className="flex p-1 aic">
                <div className="flex aic justify-center me-3 p-2 rounded-5">
                  <img
                    style={{ height: "3rem", width: "3rem" }}
                    src={ogo}
                    alt="logo"
                  />
                </div>
                <div className={darkMode ? "text-white" : "text-dark"}>
                  <h1 className="fs-3">WISDOM</h1>
                  <h5 className="text-xs lh-1 mb-2">INTERNATIONAL SCHOOL</h5>
                </div>
              </Link>
              <div
                className="c-pointer"
                onClick={() => setDarkMode((prev) => !prev)}
              >
                {darkMode ? (
                  <MdLightMode className="fs-3 text-white fw-7 me-4" />
                ) : (
                  <MdNightlight className="fs-3 text-prime fw-7 me-4" />
                )}
              </div>
            </div>
          )}
        </>
        <Menu
          theme={darkMode ? "dark" : "light"}
          mode="inline"
          selectedKeys={getSelectedKeys(location.pathname)}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          onClick={handleClick}
          items={sidebrMenus}
          style={{
            fontSize: "15.5px",
            fontWeight: "500",
            borderRadius: "6px",
            color: colorPrimary,
          }}
        />
      </Sider>
      <Layout>
        <Header
          className="navbar-header"
          style={{
            padding: 0,
            minHeight: 70,
            background: colorBgContainer,
            borderBottom: "1px dotted #ccc",
          }}
        >
          <div className="flex aic h-100">
            <Button
              className="nav-academic"
              type="text"
              icon={<ImMenu className="fs-4 collapse-btn" />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                width: 60,
                borderRadius: 0,
                height: "100%",
                marginRight: 10,
              }}
            />
            <div className="ms-3-r">
              <Spin spinning={academicLoading}>
                <Tooltip title="Current Academic Year" placement="right">
                  <Select
                    className="w-250 pe-2"
                    value={
                      (academicData &&
                        academicData?.find((item) => item.is_default)?.id) ||
                      academicYear ||
                      null
                    }
                    // onChange={handleStatusChangeDropdown}
                  >
                    {academicData &&
                      academicData.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.year}
                        </Option>
                      ))}
                  </Select>
                </Tooltip>
              </Spin>
            </div>
          </div>
          <div className="flex aic">
            <MdFullscreen
              className="fs-1 me-4 c-pointer full-scr-icon text-prime"
              onClick={toggleFullscreen}
            />

            <Dropdown menu={notificationsData}>
              <Badge count={1} className="me-5 c-pointer">
                <Avatar
                  style={{ backgroundColor: "#17223b" }}
                  shape="circle"
                  icon={<HiBell className="notification-icon" />}
                  size="small"
                />
              </Badge>
            </Dropdown>

            <div className="me-4">
              <Dropdown menu={userNavMenus} placement="bottomRight">
                {isLoading ? (
                  <Spin />
                ) : (
                  <div className="flex aic c-pointer nav-user-menu tr-03 me-3">
                    <div className="nav-user-img">
                      <img
                        src={data.image_url ? data.image_url : ogo}
                        alt={(data.first_name && data.first_name) || "name"}
                        loading="lazy"
                      />
                    </div>
                  </div>
                )}
              </Dropdown>
            </div>
          </div>
        </Header>
        <Content
          className="contents padding-24"
          style={{
            paddingTop: 0,
            minHeight: 280,
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
        </Content>
        <div
          className="w-100 text-center"
          style={{ marginTop: 9, fontWeight: 700 }}
        >
          DEVELOPED BY{" "}
          <a
            href="https://privateyebd.com/"
            target="_blank"
            rel="noreferrer"
            className="text-dark"
          >
            PRIVATEYE
          </a>
        </div>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
