import { Button, Col, Image, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchSections } from "../../../libs/redux/features/academic/sectionsNewSlice";

const { Option } = Select;

const PromotionModal = ({
  visible,
  onCancel,
  onSubmit,
  student,
  initialData,
  loading,
  error = {},
}) => {
  const [modalPromotion, setModalPromotion] = useState(initialData);

  const handleOk = () => {
    onSubmit(modalPromotion);
  };

  const classes = useSelector((state) => state.classesNew.data);
  const sections = useSelector((state) => state.sectionsNew.data);

  const dispatch = useDispatch();

  // Fetch classes on mount
  useEffect(() => {
    dispatch(fetchClasses());
  }, [dispatch]);

  // Fetch sections when from_class is selected
  useEffect(() => {
    if (modalPromotion.from_class) {
      dispatch(fetchSections({ class_level: modalPromotion.from_class }));
    }
  }, [dispatch, modalPromotion.from_class]);

  // Fetch sections when to_class is selected
  useEffect(() => {
    if (modalPromotion.to_class) {
      dispatch(fetchSections({ class_level: modalPromotion.to_class }));
    }
  }, [dispatch, modalPromotion.to_class]);

  // Handle onChange for `from_class` Select
  const handleFromClassChange = (value) => {
    setModalPromotion((prev) => ({
      ...prev,
      from_class: value,
      from_section: null, // Reset section when class changes
    }));
  };

  // Handle onChange for `to_class` Select
  const handleToClassChange = (value) => {
    setModalPromotion((prev) => ({
      ...prev,
      to_class: value,
      to_section: null, // Reset section when class changes
    }));
  };

  return (
    <Modal
      title={
        <h2 className="fs-3">
          {student?.user?.first_name || ""} {student?.user?.last_name || ""}
        </h2>
      }
      open={visible}
      onCancel={onCancel}
      footer={
        <Button
          type="primary"
          size="medium"
          onClick={handleOk}
          loading={loading}
        >
          Promote
        </Button>
      }
    >
      <Row className="responsive-row">
        <Col span={24} className="mb-3">
          <Image
            style={{ maxHeight: 120 }}
            src={student?.user?.image?.image}
            alt={student?.user?.image?.alt_text}
          />
        </Col>

        {/* From Class */}
        <Col span={24}>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* From Class</p>
              {error && error.from_class ? (
                <p className="text-sm text-danger ms-3">({error.from_class})</p>
              ) : (
                ""
              )}
            </div>
            <Select
              className="w-100"
              placeholder="Select from class"
              size="large"
              value={modalPromotion.from_class || null}
              onChange={handleFromClassChange} // Set selected class
              status={error && error.from_class ? "error" : ""}
            >
              {classes && classes.length > 0
                ? classes.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
        </Col>

        {/* From Section */}
        <Col span={24}>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* From Section</p>
              {error && error.from_section ? (
                <p className="text-sm text-danger ms-3">
                  ({error.from_section})
                </p>
              ) : (
                ""
              )}
            </div>
            <Select
              className="w-100"
              placeholder="Select from section"
              size="large"
              value={modalPromotion.from_section || null}
              onChange={(value) =>
                setModalPromotion((prev) => ({
                  ...prev,
                  from_section: value,
                }))
              }
              status={error && error.from_section ? "error" : ""}
            >
              {sections && sections.length > 0
                ? sections.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
        </Col>

        {/* To Class */}
        <Col span={24}>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* To Class</p>
              {error && error.to_class ? (
                <p className="text-sm text-danger ms-3">({error.to_class})</p>
              ) : (
                ""
              )}
            </div>
            <Select
              className="w-100"
              placeholder="Select to Class"
              size="large"
              value={modalPromotion.to_class || null}
              onChange={handleToClassChange} // Set selected class
              status={error && error.to_class ? "error" : ""}
            >
              {classes && classes.length > 0
                ? classes.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
        </Col>

        {/* To Section */}
        <Col span={24}>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* To Section</p>
              {error && error.to_section ? (
                <p className="text-sm text-danger ms-3">({error.to_section})</p>
              ) : (
                ""
              )}
            </div>
            <Select
              className="w-100"
              size="large"
              placeholder="Select to section"
              value={modalPromotion.to_section || null}
              onChange={(value) =>
                setModalPromotion((prev) => ({
                  ...prev,
                  to_section: value,
                }))
              }
              status={error && error.to_section ? "error" : ""}
            >
              {sections && sections.length > 0
                ? sections.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default PromotionModal;
