import {
  Modal,
  Tabs,
  Upload,
  Spin,
  Checkbox,
  Button,
  Input,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { createFormDataImage } from "./utility";
import { uploadImage } from "../libs/axios/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { fetchGallery } from "../libs/redux/features/website/gallerySlice";

const ImagePopup = ({ open, onCancel, onUploadSuccess }) => {
  const dispatch = useDispatch();
  const { isLoading, data, hasMore, offset, limit } = useSelector(
    (state) => state.gallery
  );

  useEffect(() => {
    if (data.length === 0) {
      dispatch(fetchGallery({ limit, offset }));
    }
  }, [dispatch, limit, offset, data.length]);

  const handleLoadMore = () => {
    if (hasMore) {
      dispatch(fetchGallery({ limit, offset }));
    }
  };

  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagePayload, setImagePayload] = useState({
    title: "",
    alt_text: "",
    caption: "",
    is_gallery_image: true,
  });

  const handleSelectImage = (imageObj) => {
    setSelectedImages([imageObj]);
  };

  const handleImages = ({ fileList: newImageList }) => {
    setImages(newImageList);
  };

  const handleRemove = (image) => {
    setImages((prevImages) =>
      prevImages.filter((item) => item.uid !== image.uid)
    );
  };

  const imageTabData = [
    {
      label: "Gallery",
      key: "gallery",
      children: (
        <div style={{ height: "100%" }}>
          <Spin spinning={isLoading}>
            <div className="image-preview-grid">
              {data &&
                data.map((item) => {
                  const isSelected = selectedImages.some(
                    (img) => img.id === item.id
                  );

                  return (
                    <div
                      key={item.id}
                      className={`upload-img-wrap ${
                        isSelected ? "border-dark shadow-sm" : ""
                      }`}
                      onClick={() => handleSelectImage(item)}
                    >
                      <img
                        src={item.image}
                        alt={item.alt_text}
                        loading="lazy"
                      />
                    </div>
                  );
                })}
            </div>
            {hasMore && (
              <div className="w-100 flex justify-center my-3">
                <Button type="primary" onClick={handleLoadMore}>
                  Load More
                </Button>
              </div>
            )}
          </Spin>
        </div>
      ),
    },
    {
      label: "This Device",
      key: "device",
      children: (
        <>
          <div className="image-upload rounded-5 h-100">
            <div className="mb-3 flex-justify-between-no-gap wrap image-upload-input-wrap">
              <div className="w-25-p">
                <Input
                  placeholder="Image Title"
                  value={imagePayload.title}
                  onChange={(e) =>
                    setImagePayload((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="w-25-p">
                <Input
                  placeholder="Image Caption"
                  value={imagePayload.caption}
                  onChange={(e) =>
                    setImagePayload((prev) => ({
                      ...prev,
                      caption: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="w-25-p">
                <Input
                  placeholder="Image Alt Text"
                  value={imagePayload.alt_text}
                  onChange={(e) =>
                    setImagePayload((prev) => ({
                      ...prev,
                      alt_text: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="w-25-p">
                <Checkbox
                  className="ms-3"
                  checked={imagePayload.is_gallery_image}
                  onChange={(value) =>
                    setImagePayload((prev) => ({
                      ...prev,
                      is_gallery_image: value.target.checked,
                    }))
                  }
                >
                  save into gallery ?
                </Checkbox>
              </div>
            </div>
            <Upload
              className="custom-upload"
              listType="picture-card"
              fileList={images}
              maxCount={1}
              showUploadList={false}
              accept="image/*"
              beforeUpload={() => false}
              onRemove={handleRemove}
              onChange={handleImages}
            >
              <div className="flex justify-center aic h-100 w-100">
                {images.length === 0 ? (
                  <h2 className="fs-2 text-center text-mute im-title">
                    + select <br />
                    or Drop files here
                  </h2>
                ) : (
                  <div className="image-preview-grid">
                    {images.map((image) => (
                      <img
                        key={image.uid}
                        className="rounded-5"
                        src={URL.createObjectURL(image.originFileObj)}
                        alt="upload"
                        style={{
                          width: "110px",
                          height: "110px",
                          margin: "5px",
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>
            </Upload>
          </div>
        </>
      ),
    },
  ];

  const handleImageUpload = async (e) => {
    e.preventDefault();

    let newImages = [];
    if (images && images.length > 0) {
      if (imagePayload.title === "") {
        message.warning("Image title is required");
      } else if (imagePayload.alt_text === "") {
        message.warning("Image alt text is required");
      } else {
        setLoading(true);
        try {
          const uploadPromises = images.map((image) => {
            const formData = createFormDataImage(
              image.originFileObj,
              imagePayload.title,
              imagePayload.caption,
              imagePayload.alt_text,
              imagePayload.is_gallery_image
            );
            return uploadImage(formData);
          });

          const uploadResponses = await Promise.all(uploadPromises);
          newImages = uploadResponses.map((response) => response.data);

          onUploadSuccess(newImages);
          setLoading(false);
        } catch (error) {
          console.log("error uploading images from popup", error);
          setLoading(false);
          return;
        }
      }
    } else if (selectedImages && selectedImages.length > 0) {
      onUploadSuccess(selectedImages);
      setLoading(false);
    } else {
      setLoading(false);
      message.warning("No Image is selected!");
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      width={1000}
      onOk={handleImageUpload}
      className="custom-modal"
    >
      <Spin spinning={loading}>
        <Tabs
          defaultActiveKey="gallery"
          centered
          items={imageTabData}
          style={{ height: "50vh" }}
        />
      </Spin>
    </Modal>
  );
};

export default ImagePopup;
