import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAll as fetchLeaveRequests,
  postData as postLeaveRequests,
  updateData,
} from "../../../libs/redux/features/payroll/leaveRequestSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../../components/utility";
import {
  EyeOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import BreadBtn from "../../../components/BreadBtn";
import dayjs from "dayjs";
import DataConversion from "../../../components/DataConversion";
import { fetchAll as fetchUsers } from "../../../libs/redux/features/users/usersSlice";
import { postData } from "../../../libs/axios/axiosInstance";

const { Option } = Select;
const { Search } = Input;

const LeaveRequests = () => {
  usePageTitle("Leave Requests");

  const breadcrumbitems = [
    {
      title: "Payroll Info",
    },
    { title: "Leave Requests" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const leaveRequestsData = useSelector((state) => state.leaveRequests.data);
  const totalItems = useSelector((state) => state.leaveRequests.totalItems);
  const isLoading = useSelector((state) => state.leaveRequests.isLoading);
  const error = useSelector((state) => state.leaveRequests.error);

  const usersData = useSelector((state) => state.users.data);

  const [modalData, setModalData] = useState({
    reason: "",
    start_date: "",
    end_date: "",
    user: null,
  });

  useEffect(() => {
    if (usersData.length === 0) {
      dispatch(fetchUsers());
    }
  }, [dispatch, usersData.length]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    start_date: "",
    end_date: "",
    search: "",
    status: "",
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };

    if (filters.start_date !== "") {
      params.start_date = filters.start_date;
    }
    if (filters.end_date !== "") {
      params.end_date = filters.end_date;
    }
    if (filters.search !== "") {
      params.search = filters.search;
    }
    if (filters.status !== "all") {
      params.status = filters.status;
    }
    console.log(params);

    dispatch(fetchLeaveRequests(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => {
    const userInfo =
      usersData && usersData.find((entry) => entry.id === item.user);

    return {
      Serial: index + 1,
      User: userInfo ? `${userInfo.first_name} ${userInfo.last_name}` : "N/A",
      Reason: item.reason || "N/A",
      "Start Date": item.start_date || "N/A",
      "End Date": item.end_date || "N/A",
      Status:
        item.status === "pending"
          ? "Pending"
          : item.status === "approved"
          ? "Approved"
          : item.status === "rejected"
          ? "Rejected"
          : "N/A",
    };
  };

  const handleDownloadPDF = () => {
    const columns = ["Serial", "User", "Start Date", "End Date", "Status"];
    downloadPDF(
      leaveRequestsData,
      columns,
      formatTimeSlotsData,
      "Leave Requests Data"
    );
  };

  const handleDownloadXLS = () => {
    downloadXLS(leaveRequestsData, formatTimeSlotsData, "Leave Requests Data");
  };

  const [leaveApprovalLoading, setApprovalLoading] = useState(null);

  const handleLeaveRequestApproval = async (id) => {
    setApprovalLoading(id);

    try {
      const aprroval = await postData(
        `payroll/admin/leave-requests/${id}/approve-reject/`,
        { is_approved: true }
      );
      console.log("approval", aprroval);

      if (aprroval.status === 201 || aprroval.status === 200) {
        message.success("Selected leave request status is updated to approved");
        dispatch(fetchLeaveRequests(filters));
      }
    } catch (error) {
      console.log("error approving leave request", error.response);
    } finally {
      setApprovalLoading(null);
    }
  };

  const handleLeaveRequestReject = async (id) => {
    setApprovalLoading(id);

    try {
      const aprroval = await postData(
        `payroll/admin/leave-requests/${id}/approve-reject/`,
        { is_approved: false }
      );

      if (aprroval.status === 201 || aprroval.status === 200) {
        message.success("Selected leave request status is updated to rejected");
        dispatch(fetchLeaveRequests(filters));
      }
    } catch (error) {
      console.log("error approving leave request", error.response);
    } finally {
      setApprovalLoading(null);
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "User",
      dataIndex: "user",
      render: (user) => {
        const userInfo =
          usersData && usersData.find((item) => item.id === user);
        return userInfo
          ? `${userInfo.first_name} ${userInfo.last_name}`
          : "Unknown";
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
    },

    {
      title: "End Date",
      dataIndex: "end_date",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, approved) =>
        approved.status === "approved" ? (
          <Tag color="#87d068">Approved</Tag>
        ) : approved.status === "pending" ? (
          <Spin spinning={leaveApprovalLoading === approved.id}>
            <Tag color="#2db7f5">Pending</Tag>
            <Popconfirm
              placement="topRight"
              title="Approve or Reject"
              description="You can either approve or reject the leave request"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              okText="Approve"
              cancelText="Reject"
              onConfirm={() => handleLeaveRequestApproval(approved.id)}
              onCancel={() => handleLeaveRequestReject(approved.id)}
            >
              <span className="text-sm approve-btn">Update status</span>
            </Popconfirm>
          </Spin>
        ) : approved.status === "rejected" ? (
          <>
            <Tag color="#f50">Rejected</Tag>
          </>
        ) : (
          "Unknown"
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Button className="me-4 p-2" onClick={() => fetchSingleItem(record)}>
            <EyeOutlined />
          </Button>
          <div
            onClick={() =>
              handleDelete(
                `${record.reason} for ${record.user}`,
                dispatch,
                `payroll/admin/leave-requests/${record.id}/`,
                fetchLeaveRequests,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const [id, setId] = useState(null);
  const fetchSingleItem = (data) => {
    setModalData({
      user: data.user,
      reason: data.reason,
      start_date: data.start_date
        ? dayjs(data.start_date).format("YYYY-MM-DD")
        : null,
      end_date: data.end_date
        ? dayjs(data.end_date).format("YYYY-MM-DD")
        : null,
    });
    setId(data.id);
    setAddModal(true);
  };

  const handleModalCancel = () => {
    setAddModal(false);
    if (id) {
      setId(null);
    }
    setModalData({
      reason: "",
      start_date: "",
      end_date: "",
      user: null,
    });
  };

  const handleModalOk = () => {
    const payload = {
      ...modalData,
      academic_year: 23,
    };
    if (!id) {
      dispatch(
        postLeaveRequests({
          payload,
          onSuccess: () => {
            message.success("New leave request added successfully!");
            handleModalCancel();
            setAddModal(false);
          },
        })
      );
    } else {
      dispatch(updateData({ id, payload }));
    }
  };

  return (
    <div>
      <BreadBtn
        headerTitle="Leave Requests"
        items={breadcrumbitems}
        addButton={true}
        btnClick={() => setAddModal(true)}
      />
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search by reason..."
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
            />
            <DatePicker
              placeholder="Start Date"
              className="w-200"
              value={filters.start_date ? dayjs(filters.start_date) : null}
              onChange={(value) =>
                handleFilterChange(
                  "start_date",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
            <DatePicker
              placeholder="End Date"
              className="w-200"
              value={filters.end_date ? dayjs(filters.end_date) : null}
              onChange={(value) =>
                handleFilterChange(
                  "end_date",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("status", value)}
            >
              <Option value="all">All Approval Status</Option>
              <Option value="pending">Pending</Option>
              <Option value="approved">Approved</Option>
              <Option value="rejected">Rejected</Option>
            </Select>
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={leaveRequestsData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* add and edit data modal */}
      {addModal && (
        <Modal
          title={<h2 className="fs-2 mb-3 text-dark">Leave Request</h2>}
          open={addModal}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={isLoading}
              loading={isLoading}
            >
              {id ? "Update" : "Submit"}
            </Button>,
          ]}
          onCancel={handleModalCancel}
        >
          <Row>
            <Col span={24}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* User</p>
                  {error && error.user ? (
                    <p className="text-sm text-danger ms-3">({error.user})</p>
                  ) : (
                    ""
                  )}
                </div>
                <Select
                  showSearch
                  className="w-100"
                  // size="large"
                  placeholder="Select User"
                  optionFilterProp="children"
                  value={modalData.user || null}
                  onChange={(value) =>
                    setModalData((prev) => ({
                      ...prev,
                      user: value,
                    }))
                  }
                  status={error && error.user ? "error" : ""}
                >
                  {usersData && usersData.length > 0
                    ? usersData.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.first_name} {item.last_name}
                        </Option>
                      ))
                    : ""}
                </Select>
              </div>
            </Col>
            <Col span={24}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Reason</p>
                  {error && error.reason ? (
                    <p className="text-sm text-danger ms-3">({error.reason})</p>
                  ) : (
                    ""
                  )}
                </div>
                <Input
                  className="w-100"
                  placeholder="Name"
                  required
                  value={modalData.reason}
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      reason: e.target.value,
                    }))
                  }
                  status={error && error.reason ? "error" : ""}
                />
              </div>
            </Col>
            <Col span={24}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Start Date</p>
                  {error && error.start_date ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.start_date})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <DatePicker
                  className="w-100"
                  placeholder="Start Date"
                  required
                  value={
                    modalData.start_date ? dayjs(modalData.start_date) : null
                  }
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      start_date: e ? dayjs(e).format("YYYY-MM-DD") : null,
                    }))
                  }
                  status={error && error.start_date ? "error" : ""}
                />
              </div>
            </Col>
            <Col span={24}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* End Date</p>
                  {error && error.end_date ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.end_date})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <DatePicker
                  className="w-100"
                  placeholder="End Date"
                  required
                  value={modalData.end_date ? dayjs(modalData.end_date) : null}
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      end_date: e ? dayjs(e).format("YYYY-MM-DD") : null,
                    }))
                  }
                  status={error && error.end_date ? "error" : ""}
                />
              </div>
            </Col>
          </Row>
        </Modal>
      )}
    </div>
  );
};

export default LeaveRequests;
