import React from "react";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";

const DataConversion = ({ handleDownloadPDF, handleDownloadXLS }) => {
  return (
    <div className="flex aic g-3">
      <div
        className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
        style={{ padding: "7px 10px" }}
        onClick={handleDownloadPDF}
      >
        <FaFilePdf className="export-icon tr-03 fs-5" />
      </div>
      <div
        onClick={handleDownloadXLS}
        className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
        style={{ padding: "7px 10px" }}
      >
        <PiFileXlsBold className="export-icon tr-03 fs-5" />
      </div>
    </div>
  );
};

export default DataConversion;
