import {
  Breadcrumb,
  Button,
  Switch,
  Table,
  DatePicker,
  Input,
  Pagination,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchNotices } from "../../../libs/redux/features/website/noticesSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  handleStatusChange,
} from "../../../components/utility";
import usePageTitle from "../../../components/PageTitle";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";

const { RangePicker } = DatePicker;
const { Search } = Input;
const { Option } = Select;

const Notice = () => {
  usePageTitle("Notices");
  const dispatch = useDispatch();
  const { data, isLoading, totalItems } = useSelector((state) => state.notices);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    is_active: "all",
    search: "",
    end_date: null,
    to_date: null,
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.is_active !== "all") {
      params.is_active = filters.is_active;
    }
    if (filters.search !== "") {
      params.search = filters.search;
    }
    if (filters.end_date) {
      params.end_date = filters.end_date;
    }
    if (filters.to_date) {
      params.to_date = filters.to_date;
    }

    dispatch(fetchNotices(params));
  }, [dispatch, filters]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Status",
      dataIndex: "is_active",

      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) =>
            handleStatusChange(
              checked,
              record,
              dispatch,
              `website/notice/${record.id}/`,
              fetchNotices,
              filters
            )
          }
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link
            to={`/website/components/notices/${record.id}`}
            className="me-4"
          >
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.title}`,
                dispatch,
                `website/notice/${record.id}/`,
                fetchNotices,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Title: item.title || "N/A",
    Date: item.date || "N/A",
    Description: item.description || "N/A",
    Status: item.is_active ? "Active" : "Inactive",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Title", "Date", "Description", "Status"];
    downloadPDF(data, columns, formatTimeSlotsData, "Notices");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Notices");
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2">Notices</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    <HomeOutlined />
                  </Link>
                ),
              },
              { title: "Website" },
              { title: "Components" },
              { title: "Notices" },
            ]}
          />
        </div>
        <div>
          <Link to="/website/components/notices/add-notice">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <RangePicker
              className="w-100-r"
              format="YYYY-MM-DD"
              placeholder={["End Date", "To Date"]}
              onChange={(dates, dateStrings) => {
                handleFilterChange("end_date", dateStrings[0] || null);
                handleFilterChange("to_date", dateStrings[1] || null);
              }}
              onClear={() => {
                handleFilterChange("end_date", null);
                handleFilterChange("to_date", null);
              }}
              allowClear
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("is_active", value)}
            >
              <Option value="all">Filter by All Status</Option>
              <Option value={true}>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
            <Search
              placeholder="Search Title"
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
            />
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Notice;
