import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

// Fetch designations with optional filters
export const fetchDesignations = createAsyncThunk(
  "designations/fetchDesignations",
  async (
    { name = "", limit = 10, offset = 0, is_active } = {},
    { rejectWithValue }
  ) => {
    try {
      const params = {
        limit,
        offset,
        name, // Use 'name' as query param
      };

      if (is_active !== undefined) {
        params.is_active = is_active;
      }

      const response = await getData("teacher/admin/designation/", params);
      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch a single designation by ID
export const fetchSingleDesignation = createAsyncThunk(
  "designations/fetchSingleDesignation",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`teacher/admin/designation/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Post a new designation
export const postDesignation = createAsyncThunk(
  "designations/postDesignation",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await postData("teacher/admin/designation/", payload);
      if (response.status === 200 || 201) {
        message.success("Designation created successfully!");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.name?.[0] ||
          error.response.statusText ||
          "Failed to post designation.";
        message.error(errorMessage);
      } else {
        message.error("Failed to post designation.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Update an existing designation by ID
export const putDesignation = createAsyncThunk(
  "designations/putDesignation",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await putData(
        `teacher/admin/designation/${id}/`,
        payload
      );
      if (response.status === 200 || 201) {
        message.success("Designation updated successfully!");
        fetchSingleDesignation(id);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.name?.[0] ||
          error.response.statusText ||
          "Failed to update designation.";
        message.error(errorMessage);
      } else {
        message.error("Failed to update designation.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Designations slice
const designationsSlice = createSlice({
  name: "designations",
  initialState: {
    data: [],
    singleData: {},
    isLoading: false,
    offset: 0,
    limit: 10,
    totalItems: 0,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDesignations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDesignations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchDesignations.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = action.payload;
      })
      .addCase(fetchSingleDesignation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleDesignation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleData = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleDesignation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postDesignation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postDesignation.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postDesignation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(putDesignation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putDesignation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(putDesignation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default designationsSlice.reducer;
