import React, { useState } from "react";
import { MailOutlined, KeyOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../libs/redux/features/authentication/authSlice";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import bgImage from "../../assets/Cloudy.png";
import usePageTitle from "../../components/PageTitle";

export default function Signin() {
  usePageTitle("Sign-in");
  const { isLoading, errors } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [SignInData, setSignData] = useState({
    mobile_or_email: "",
    password: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loginUser({ SignInData, navigate }));
  };

  return (
    <div className="signin-page" style={{ backgroundImage: `url(${bgImage})` }}>
      {errors.mobile_or_email || errors.password ? (
        <div className="mb-4 error-section text-center text-white">
          {errors.mobile_or_email || errors.password}
        </div>
      ) : (
        ""
      )}
      <form className="signin-card" onSubmit={handleSubmit}>
        <div className="flex justify-center mb-3">
          <img
            style={{ height: 100, width: 100 }}
            src={logo}
            alt="Wisdom International School"
          />
        </div>
        <h2 className="mb-5 text-center fs-4 text-dark">
          Wisdom International School
        </h2>
        <div className="mb-3">
          <Input
            size="large"
            placeholder="Email or Mobile"
            value={SignInData.mobile_or_email}
            onChange={(e) =>
              setSignData((prev) => ({
                ...prev,
                mobile_or_email: e.target.value,
              }))
            }
            prefix={<MailOutlined />}
            required
            status={errors.mobile_or_email ? "error" : ""}
            help={errors.mobile_or_email}
          />
        </div>
        <div className="mb-4">
          <Input.Password
            size="large"
            placeholder="Password"
            value={SignInData.password}
            onChange={(e) =>
              setSignData((prev) => ({
                ...prev,
                password: e.target.value,
              }))
            }
            prefix={<KeyOutlined />}
            required
            status={errors.password ? "error" : ""}
            help={errors.password}
          />
        </div>
        <div className="flex-justify-between mt-3">
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            disabled={isLoading}
            loading={isLoading}
          >
            Sign in
          </Button>
          <p className="text-dark">Forgot Password ?</p>
        </div>
      </form>
    </div>
  );
}
