import {
  Button,
  Col,
  DatePicker,
  Empty,
  Input,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { postData } from "../../../libs/axios/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll } from "../../../libs/redux/features/exams-features/examTypeSlice";
import { fetchAll as fetchSections } from "../../../libs/redux/features/academic/sectionsNewSlice";
import { fetchAll as fetchCourses } from "../../../libs/redux/features/academic/coursesNewSlice";
import { Link } from "react-router-dom";
import { IoIosClose } from "react-icons/io";

const { Option } = Select;

const ExamBulkUpload = ({ open, onCancel, onSuccess }) => {
  const dispatch = useDispatch();
  const classesData = useSelector((state) => state.classesNew.data);
  const coursesData = useSelector((state) => state.coursesNew.data);
  const sectionsData = useSelector((state) => state.sectionsNew.data);
  const examTypesData = useSelector((state) => state.examTypes.data);

  const [modalData, setModalData] = useState({
    exam_type: null,
    class_level: null,
    section: null,
    exams: [],
  });

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
    if (examTypesData.length === 0) {
      dispatch(fetchAll());
    }
  }, [dispatch, classesData.length, examTypesData.length]);
  console.log(modalData);

  useEffect(() => {
    if (modalData.class_level) {
      dispatch(fetchSections({ class_level: modalData.class_level }));
      dispatch(fetchCourses({ class_level: modalData.class_level }));
    }
    //  else {
    //   dispatch(fetchSections());
    //   dispatch(fetchCourses());
    // }
  }, [dispatch, modalData.class_level]);

  useEffect(() => {
    setModalData((prev) => ({
      ...prev,
      exams:
        (coursesData &&
          coursesData.map((item) => ({
            course: item.id,
            duration: "01:00:00",
            total_marks: 100,
            date: "",
          }))) ||
        [],
    }));
  }, [coursesData]);

  // Initialize errors as an object to handle field-specific errors
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // Function to remove an exam from the exams array
  const removeExam = (examIndex) => {
    if (modalData.exams.length > 1) {
      setModalData((prevData) => ({
        ...prevData,
        exams: prevData.exams.filter((_, index) => index !== examIndex),
      }));
    } else {
      message.warning("At least one exam is required.");
    }
  };

  // Function to handle changes in top-level modalData fields (class_level, exam_type, section, date)
  const handleInputChange = (key, value) => {
    setModalData((prevData) => ({
      ...prevData,
      [key]: value,
    }));

    // Clear any existing errors for this field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [key]: null,
    }));
  };

  // Function to handle changes within individual exams (course, duration, total_marks)
  const handleExamChange = (examIndex, key, value) => {
    setModalData((prevData) => ({
      ...prevData,
      exams: prevData.exams.map((exam, idx) =>
        idx === examIndex ? { ...exam, [key]: value } : exam
      ),
    }));
  };

  // Function to handle modal cancellation
  const handleModalCancel = () => {
    onCancel();
  };

  // Function to handle form submission
  const handleModalOk = async () => {
    setIsLoading(true);
    console.log("payload", modalData);

    try {
      const response = await postData(
        "exam/admin/exams/bulk-create/",
        modalData
      );
      if (response.status === 201) {
        message.success("Exams added successfully!");
        // Reset modalData to initial state after successful submission
        setModalData({
          exam_type: examTypesData?.[0]?.id || null,
          class_level: classesData?.[0]?.id || null,
          section: sectionsData?.[0]?.id || "all",
          date: "",
          exams: [
            {
              course: coursesData?.[0]?.id || null,
              duration: "",
              total_marks: 100,
            },
          ],
        });
        if (onSuccess) {
          onSuccess();
        }
        setErrors({});
        onCancel(); // Close the modal
      }
    } catch (error) {
      if (error.response?.data) {
        const errorData = error.response.data;
        message.error(
          errorData.non_field_errors?.[0] || "Error uploading exam data!"
        );
        // Assuming errorData contains field-specific errors
        setErrors(errorData);
      } else {
        message.error("An unexpected error occurred.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={handleModalCancel}
      width={1200}
      title={<h2 className="fs-2 mb-3">Exam Bulk Upload</h2>}
      footer={[
        <Button key="cancel" onClick={handleModalCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleModalOk}
          disabled={modalData.exams && modalData.exams.length === 0}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      {/* Top-Level Fields: Class, Exam Type, Date */}
      <Row className="responsive-row mb-3">
        {/* Class Field */}
        <Col span={24} md={8}>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Class</p>
              {errors && errors.class_level && (
                <p className="text-danger">({errors.class_level})</p>
              )}
            </div>
            <Select
              className="w-100"
              value={modalData.class_level || null}
              placeholder="Select Class"
              onChange={(value) => handleInputChange("class_level", value)}
              status={errors.class_level ? "error" : ""}
            >
              {classesData.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={24} md={8}>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Section</p>
              {errors && errors.section && (
                <p className="text-danger">({errors.section})</p>
              )}
            </div>
            <Select
              className="w-100"
              value={modalData.section || null}
              placeholder="Select Section"
              onChange={(value) => handleInputChange("section", value)}
              status={errors.section ? "error" : ""}
            >
              {sectionsData &&
                sectionsData.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </div>
        </Col>

        {/* Exam Type Field */}
        <Col span={24} md={8}>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Exam Type</p>
              {errors.exam_type && (
                <p className="text-danger">({errors.exam_type})</p>
              )}
            </div>
            <Select
              className="w-100"
              value={modalData.exam_type || null}
              placeholder="Select Exam Type"
              onChange={(value) => handleInputChange("exam_type", value)}
              status={errors.exam_type ? "error" : ""}
            >
              {examTypesData.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>

      {/* Exams Array */}
      <Row className="responsive-row">
        {modalData.exams && modalData.exams.length > 0 ? (
          modalData.exams.map((exam, index) => (
            <Col span={24} className="main-column mb-3" key={index}>
              <div className="bg-white p-3 border shadow rounded-5 relative">
                {/* Exam Header with Remove Button */}

                {modalData.exams && modalData.exams.length > 1 && (
                  <Button
                    onClick={() => removeExam(index)}
                    className="p-0 absolute"
                    danger
                    style={{
                      height: 20,
                      width: 20,
                      right: 0,
                      top: 0,
                      borderRadius: 4,
                    }}
                  >
                    <IoIosClose />
                  </Button>
                )}

                {/* Exam Fields: Duration, Total Marks, Course */}
                <Row>
                  <Col span={24} md={6}>
                    <div>
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">Course</p>
                      </div>
                      <Input
                        className="w-100"
                        placeholder="Course"
                        value={
                          coursesData.find((item) => item.id === exam.course)
                            ?.name
                        }
                        disabled
                      />
                    </div>
                  </Col>
                  {/* Date Field */}
                  <Col span={24} md={6}>
                    <div className="">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Date</p>
                        {errors.exams?.[index]?.date && (
                          <p className="text-danger">
                            ({errors.exams[index].date})
                          </p>
                        )}
                      </div>
                      <DatePicker
                        className="w-100"
                        placeholder="Date"
                        value={exam.date ? dayjs(exam.date) : null}
                        onChange={(e) =>
                          handleExamChange(
                            index,
                            "date",
                            e ? dayjs(e).format("YYYY-MM-DD") : null
                          )
                        }
                        status={errors.exams?.[index]?.date ? "error" : ""}
                      />
                    </div>
                  </Col>
                  {/* Duration Field */}
                  <Col span={24} sm={6}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">Duration</p>
                        {errors.exams?.[index]?.duration && (
                          <p className="text-danger">
                            ({errors.exams[index].duration})
                          </p>
                        )}
                      </div>
                      <Input
                        className="w-100"
                        placeholder="Format - 01:00:00"
                        value={exam.duration}
                        onChange={(e) =>
                          handleExamChange(index, "duration", e.target.value)
                        }
                        status={errors.exams?.[index]?.duration ? "error" : ""}
                      />
                    </div>
                  </Col>

                  {/* Total Marks Field */}
                  <Col span={24} md={6}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Total Marks</p>
                        {errors.exams?.[index]?.total_marks && (
                          <p className="text-danger">
                            ({errors.exams[index].total_marks})
                          </p>
                        )}
                      </div>
                      <Input
                        className="w-100"
                        type="number"
                        placeholder="Total Marks"
                        value={exam.total_marks}
                        onChange={(e) =>
                          handleExamChange(index, "total_marks", e.target.value)
                        }
                        status={
                          errors.exams?.[index]?.total_marks ? "error" : ""
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          ))
        ) : (
          <div className="w-100 d-flex aic justify-center">
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={
                <div>There is no courses for the selected class</div>
              }
            >
              <Link type="primary" to="/academic/courses/add-course">
                <Button type="primary">Create Now</Button>
              </Link>
            </Empty>
          </div>
        )}
      </Row>
    </Modal>
  );
};

export default ExamBulkUpload;
