import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ProtectedRoutes = (props) => {
  const { Component } = props;
  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/sign-in");
    }
  }, [navigate]);

  return (
    <div>
      <Component />
    </div>
  );
};
