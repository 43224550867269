import React, { useEffect, useState } from "react";
import BreadBtn from "../../../components/BreadBtn";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchtudents } from "../../../libs/redux/features/student-info/studentsSlice";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchSections } from "../../../libs/redux/features/academic/sectionsNewSlice";
import {
  Button,
  Image,
  Input,
  message,
  Modal,
  Pagination,
  Select,
  Table,
  Tooltip,
} from "antd";
import { TbReportAnalytics } from "react-icons/tb";
import { MdAssignmentAdd } from "react-icons/md";
import { postData } from "../../../libs/axios/axiosInstance";

const { Search } = Input;
const { Option } = Select;

const StudentReports = () => {
  const breadcrumbitems = [
    {
      title: "Exam Data",
    },
    {
      title: "Student Reports",
    },
  ];

  usePageTitle("Student Reports");
  const dispatch = useDispatch();
  const studentData = useSelector((state) => state.students.data);
  const isLoading = useSelector((state) => state.students.isLoaing);
  const totalItems = useSelector((state) => state.students.totalItems);

  const classesData = useSelector((state) => state.classesNew.data);
  const sectionsData = useSelector((state) => state.sectionsNew.data);

  const [filters, setFilters] = useState({
    search: "",
    limit: 10,
    offset: 0,
    school_class: null,
    section: null,
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.search !== "") {
      params.search = filters.search;
    }
    if (filters.school_class !== null) {
      params.school_class = filters.school_class;
    }
    if (filters.section !== null) {
      params.section = filters.section;
    }
    dispatch(fetchtudents(params));
  }, [dispatch, filters]);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
  }, [dispatch, classesData.length]);

  useEffect(() => {
    if (filters.school_class) {
      dispatch(fetchSections({ class_level: filters.school_class }));
    } else {
      dispatch(fetchSections());
    }
  }, [dispatch, filters.school_class]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const [reportModals, setReportModals] = useState({
    progressReport: false,
    reportGeneration: false,
  });
  const [reportsLoading, setReportsLoading] = useState({
    progressReport: null,
    reportGeneration: false,
  });

  const handleProgressReport = async (id) => {
    setReportsLoading((prev) => ({
      ...prev,
      progressReport: id,
    }));

    try {
      const progress_report = await postData(
        `exam/admin/bulk/progress-report/${id}/`
      );
      if (progress_report.status === 201) {
        setReportModals((prev) => ({
          ...prev,
          progressReport: true,
        }));
      }
    } catch (error) {
      console.log("Error progress report", error.response);
      message.error("Internal server error!");
    } finally {
      setReportsLoading((prev) => ({
        ...prev,
        progressReport: null,
      }));
    }
  };

  const handleReportCardGeneration = async (id) => {
    setReportsLoading((prev) => ({
      ...prev,
      reportGeneration: id,
    }));

    try {
      const report_generation = await postData(
        `exam/admin/bulk/report-card/${id}/`
      );
      if (report_generation.status === 201) {
        setReportModals((prev) => ({
          ...prev,
          reportGeneration: true,
        }));
      }
    } catch (error) {
      console.log("report card generation error", error.response);
      message.error("Internal server error!");
    } finally {
      setReportsLoading((prev) => ({
        ...prev,
        reportGeneration: null,
      }));
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Name",
      dataIndex: "user",
      render: (user) => (
        <div className="flex aic">
          <Image
            src={user.image.image}
            alt={user.image.alt_text}
            style={{
              width: 50,
              height: 50,
              borderRadius: 10,
              marginRight: 10,
              objectFit: "cover",
            }}
          />
          <span>{`${user.first_name} ${user.last_name}`}</span>
        </div>
      ),
    },
    {
      title: "Student ID",
      dataIndex: "student_id",
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (_, user) => user.user.email,
    },

    {
      title: "Reports",
      dataIndex: "action",
      // width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Tooltip title="Progress Report">
            <Button
              loading={reportsLoading.progressReport === record.id}
              onClick={() => handleProgressReport(record.id)}
              className="me-3"
              icon={<TbReportAnalytics />}
            />
          </Tooltip>
          <Tooltip title="Generate Report Card">
            <Button
              loading={reportsLoading.reportGeneration === record.id}
              onClick={() => handleReportCardGeneration(record.id)}
              icon={<MdAssignmentAdd />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <BreadBtn headerTitle="Student Reports" items={breadcrumbitems} />
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search.."
              className="w-200"
              onSearch={(value) => handleFilterChange("search", value)}
              allowClear
              onClear={() => handleFilterChange("search", "")}
            />
            <Select
              showSearch
              className="w-200"
              placeholder="Select Class"
              optionFilterProp="children"
              value={filters.school_class || null}
              onChange={(value) => handleFilterChange("school_class", value)}
              allowClear
              onClear={() => handleFilterChange("school_class", null)}
            >
              {classesData &&
                classesData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
            <Select
              showSearch
              className="w-200"
              placeholder="Select Section"
              optionFilterProp="children"
              value={filters.section || null}
              onChange={(value) => handleFilterChange("section", value)}
              allowClear
              onClear={() => handleFilterChange("section", null)}
            >
              {sectionsData &&
                sectionsData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={studentData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* progress report modal */}
      {reportModals.progressReport && (
        <Modal
          title="Progress Report"
          open={reportModals.progressReport}
          onCancel={() =>
            setReportModals((prev) => ({
              ...prev,
              progressReport: false,
            }))
          }
        >
          {" "}
          progress report modal
        </Modal>
      )}
      {/* report card generation modal */}
      {reportModals.reportGeneration && (
        <Modal
          title="Report Card Generation"
          open={reportModals.reportGeneration}
          onCancel={() =>
            setReportModals((prev) => ({
              ...prev,
              reportGeneration: false,
            }))
          }
        >
          {" "}
          Report card generation modal
        </Modal>
      )}
    </div>
  );
};

export default StudentReports;
