import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

// Fetch departments with optional filters
export const fetchDepartments = createAsyncThunk(
  "departments/fetchDepartments",
  async (
    { name = "", limit = 10, offset = 0, is_active } = {},
    { rejectWithValue }
  ) => {
    try {
      const params = {
        limit,
        offset,
        name, // Use 'name' as query param
      };

      if (is_active !== undefined) {
        params.is_active = is_active;
      }

      const response = await getData("teacher/admin/department/", params);
      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch a single department by ID
export const fetchSingleDepartment = createAsyncThunk(
  "departments/fetchSingleDepartment",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`teacher/admin/department/${id}/`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Post a new department
export const postDepartment = createAsyncThunk(
  "departments/postDepartment",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await postData("teacher/admin/department/", payload);
      if (response.status === 200 || 201) {
        message.success("Department created successfully!");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.name?.[0] ||
          error.response.statusText ||
          "Failed to post department.";
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Update an existing department by ID
export const putDepartment = createAsyncThunk(
  "departments/putDepartment",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await putData(
        `teacher/admin/department/${id}/`,
        payload
      );
      if (response.status === 200 || 201) {
        message.success("Department updated successfully!");
        fetchSingleDepartment(id);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.name?.[0] ||
          error.response.statusText ||
          "Failed to update department.";
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Departments slice
const departmentsSlice = createSlice({
  name: "departments",
  initialState: {
    data: [],
    singleData: {},
    isLoading: false,
    offset: 0,
    limit: 10,
    totalItems: 0,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchDepartments.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = action.payload;
      })
      .addCase(fetchSingleDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleData = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postDepartment.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(putDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(putDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default departmentsSlice.reducer;
