import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "./ProtectedRoutes";
import LeaveRequests from "../view/payroll-info/leave-request/LeaveRequests";
import PayrollSchedules from "../view/payroll-info/pay-schedule/PayrollSchedules";
import PayrollItems from "../view/payroll-info/payroll-items/PayrollItems";
import Payrolls from "../view/payroll-info/payrolls/Payrolls";
import SalarySlip from "../view/payroll-info/salary-slip/SalarySlip";

const PayrollRoutes = () => {
  return (
    <Routes>
      <Route
        path="/leave-requests"
        element={<ProtectedRoutes Component={LeaveRequests} />}
      />
      <Route
        path="/pay-schedules"
        element={<ProtectedRoutes Component={PayrollSchedules} />}
      />
      <Route
        path="/payrolls"
        element={<ProtectedRoutes Component={Payrolls} />}
      />
      <Route
        path="/payroll-items"
        element={<ProtectedRoutes Component={PayrollItems} />}
      />
      <Route
        path="/salary-slips"
        element={<ProtectedRoutes Component={SalarySlip} />}
      />
    </Routes>
  );
};

export default PayrollRoutes;
