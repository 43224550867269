import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Image,
  Input,
  message,
  Row,
  Spin,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import UploadButton from "../../../components/UploadButton";
import ImagePopup from "../../../components/ImagePopup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingle as fetchSingleFacility,
  postData as postFacility,
  updateData as putFacility,
} from "../../../libs/redux/features/website/facilitieSlice";
import usePageTitle from "../../../components/PageTitle";
import { HomeOutlined } from "@ant-design/icons";

const SchoolFacilityForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isLoading, singleFacility } = useSelector(
    (state) => state.facilities
  );

  const [facility, setFacility] = useState({
    title: "",
    description: "",
    list_items: "",
    is_active: true,
    image: {},
  });

  useEffect(() => {
    if (!isNaN(id)) {
      dispatch(fetchSingleFacility(id));
    }
  }, [id, dispatch]);

  usePageTitle(!isNaN(id) ? singleFacility.title : "Add Facility");

  useEffect(() => {
    if (
      !isNaN(id) &&
      singleFacility &&
      Object.keys(singleFacility).length > 0
    ) {
      setFacility(singleFacility);
    }
  }, [id, singleFacility]);

  const [imageModal, setImageModal] = useState(false);
  const handleImageUploadSuccess = (newImage) => {
    setFacility((prev) => ({
      ...prev,
      image: newImage[0],
    }));
    setImageModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isNaN(id)) {
      const payload = { ...facility, image: facility.image.id };
      dispatch(putFacility({ id: id, payload: payload }));
    } else {
      const image = facility.image.id;
      if (image) {
        const payload = { ...facility, image: image };
        dispatch(
          postFacility({
            payload,
            onSuccess: () => {
              message.success("New facility created successfully!");
              setFacility({
                title: "",
                description: "",
                list_items: "",
                is_active: true,
                image: {},
              });
            },
          })
        );
      } else {
        message.error("No image detected!");
      }
    }
  };

  return (
    <div>
      <div className="breadcrumb p-4">
        <div>
          <h1 className="fs-2 text-dark">
            {!isNaN(id) ? singleFacility.title : "Add Facility"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    <HomeOutlined />
                  </Link>
                ),
              },
              {
                title: "Website",
              },
              {
                title: "Components",
              },
              {
                title: (
                  <Link to="/website/components/school-facilities">
                    School Facilities
                  </Link>
                ),
              },
              {
                title: !isNaN(id) ? `${singleFacility.title}` : "Add Facility",
              },
            ]}
          />
        </div>
      </div>
      <div className="bg-white rounded-10 mb-5">
        <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
          <h2 className="fs-4">Page Information</h2>
        </div>
        <div className="p-4" style={{ paddingTop: 10 }}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">* Title</p>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Title"
                      value={facility.title}
                      onChange={(e) =>
                        setFacility((prev) => ({
                          ...prev,
                          title: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">* Description</p>
                    <TextArea
                      className="w-100"
                      autoSize={{ minRows: 2, maxRows: 4 }}
                      placeholder="Description"
                      value={facility.description}
                      onChange={(e) =>
                        setFacility((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">
                      * List Items (comma separated)
                    </p>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="List Item"
                      value={facility.list_items}
                      onChange={(e) =>
                        setFacility((prev) => ({
                          ...prev,
                          list_items: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-5">
                    <p className="mb-2 ps-2 fw-6">* Image</p>
                    <div className="image-preview-grid">
                      {facility.image &&
                      Object.keys(facility.image).length > 0 ? (
                        <Image
                          height={100}
                          width={100}
                          className="object-cover rounded-5"
                          alt={facility.image.alt_text}
                          src={facility.image.image}
                        />
                      ) : (
                        ""
                      )}
                      <UploadButton
                        onClick={() => setImageModal(true)}
                        height={100}
                        width={100}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-5">
                    <Checkbox
                      checked={facility.is_active}
                      onChange={(e) =>
                        setFacility((prev) => ({
                          ...prev,
                          is_active: e.target.checked,
                        }))
                      }
                    >
                      Is Active ?
                    </Checkbox>
                  </div>
                </Col>
                <Col span={24}>
                  <Button
                    className="w-100 mt-3"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </form>
          </Spin>
        </div>
      </div>
      {imageModal && (
        <ImagePopup
          open={imageModal}
          onCancel={() => setImageModal(false)}
          onUploadSuccess={handleImageUploadSuccess}
          cropAspectRatio={16 / 9}
          imageTitle={`${facility.title} - image`}
        />
      )}
    </div>
  );
};

export default SchoolFacilityForm;
