import { useEffect, useState } from "react";

const useScrollEffect = (scrollContainerSelector = ".ant-layout-content") => {
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    const scrollContainer = document.querySelector(scrollContainerSelector);

    const handleScroll = () => {
      const pageContentSection = document.querySelector(
        ".page-content-section"
      );
      if (!pageContentSection || !scrollContainer) return;

      const rect = pageContentSection.getBoundingClientRect();

      if (rect.top <= scrollContainer.offsetTop) {
        setIsAtTop(false);
      } else {
        setIsAtTop(true);
      }
    };

    scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [scrollContainerSelector]);

  return isAtTop;
};

export default useScrollEffect;
