import { Input, Pagination, Select, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchCourses } from "../../../libs/redux/features/academic/coursesNewSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  handleStatusChange,
} from "../../../components/utility";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";
import BreadBtn from "../../../components/BreadBtn";

const { Search } = Input;
const { Option } = Select;

const Courses = () => {
  usePageTitle("Courses");

  const dispatch = useDispatch();
  const data = useSelector((state) => state.coursesNew.data);
  const isLoading = useSelector((state) => state.coursesNew.isLoading);
  const totalItems = useSelector((state) => state.coursesNew.totalItems);

  const classesData = useSelector((state) => state.classesNew.data);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
  }, [classesData, dispatch]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    name: "",
    class_level: null,
    is_active: "all",
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.name !== "") {
      params.name = filters.name;
    }
    if (filters.class_level !== null) {
      params.class_level = filters.class_level;
    }
    if (filters.is_active !== "all") {
      params.is_active = filters.is_active;
    }
    dispatch(fetchCourses(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 200,
    },
    {
      title: "Total Marks",
      dataIndex: "total_marks",
    },
    {
      title: "Class Level",
      dataIndex: "class_level",
      render: (classId) => {
        const classData = classesData.find((item) => item.id === classId);
        return classData ? classData.name : "Unknown";
      },
    },
    {
      title: "Status",
      dataIndex: "is_active",
      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) =>
            handleStatusChange(
              checked,
              record,
              dispatch,
              `academic/admin/courses/${record.id}/`,
              fetchCourses,
              filters
            )
          }
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/academic/courses/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.name}`,
                dispatch,
                `academic/admin/courses/${record.id}/`,
                fetchCourses,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => {
    const classId = classesData.find((items) => items.id === item.class_level);

    const className = classId ? classId.name : "N/A";
    return {
      Serial: index + 1,
      Name: item.name || "N/A",
      "Total Marks": item.total_marks || "N/A",
      "Class Level": className || "N/A",
      Status: item.is_active ? "Active" : "Inactive",
    };
  };

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Name", "Total Marks", "Class Level", "Status"];
    downloadPDF(data, columns, formatTimeSlotsData, "Courses Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Courses Data");
  };
  const breadcrumbitems = [{ title: "Academic" }, { title: "Courses" }];
  return (
    <div>
      <BreadBtn
        headerTitle="Courses"
        addPath="/academic/courses/add-course"
        items={breadcrumbitems}
      />

      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search by name"
              onSearch={(value) => handleFilterChange("name", value)}
              className="w-200"
              allowClear
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("is_active", value)}
            >
              <Option value="all">Filter by All Status</Option>
              <Option value="true">Active</Option>
              <Option value="false">Inactive</Option>
            </Select>
            <Select
              showSearch
              className="w-200"
              placeholder="Select Class"
              optionFilterProp="children"
              value={filters.class_level || null}
              onChange={(value) => handleFilterChange("class_level", value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              allowClear
              onClear={() => handleFilterChange("class_level", null)}
            >
              {classesData &&
                classesData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Courses;
