import { RiHome3Fill } from "react-icons/ri";
import { PiStudentFill } from "react-icons/pi";
import { SiMicrosoftacademic } from "react-icons/si";
import { Link } from "react-router-dom";
import { PiChalkboardTeacherBold, PiExamFill } from "react-icons/pi";
import { CgWebsite } from "react-icons/cg";
import { MdMenuBook, MdAccountTree, MdOutlinePayment } from "react-icons/md";
import { FaUsersCog } from "react-icons/fa";

export const sidebrMenus = [
  {
    key: "1",
    icon: <RiHome3Fill />,
    label: <Link to="/">Home</Link>,
  },
  {
    key: "2",
    icon: <SiMicrosoftacademic />,
    label: "Academic",
    children: [
      {
        key: "21",
        label: <Link to="/academic/academic-years">Academic Years</Link>,
      },
      {
        key: "22",
        label: <Link to="/academic/classes">Classes</Link>,
      },
      {
        key: "23",
        label: <Link to="/academic/sections">Sections</Link>,
      },
      {
        key: "24",
        label: <Link to="/academic/time-slots">Time Slots</Link>,
      },
      {
        key: "26",
        label: <Link to="/academic/courses">Courses</Link>,
      },
      {
        key: "25",
        label: <Link to="/academic/class-routines">Class Routines</Link>,
      },
      {
        key: "27",
        label: <Link to="/academic/calendars">Calendars</Link>,
      },
      {
        key: "28",
        label: <Link to="/academic/assignments">Assignments</Link>,
      },
      {
        key: "29",
        label: (
          <Link to="/academic/assignment-submissions">
            Assignment Submissions
          </Link>
        ),
      },
      {
        key: "230",
        label: <Link to="/academic/homeworks">Homeworks</Link>,
      },
      {
        key: "231",
        label: (
          <Link to="/academic/homework-submissions">Homework Submissions</Link>
        ),
      },
    ],
  },
  {
    key: "3",
    icon: <PiChalkboardTeacherBold />,
    label: "Teachers Info",
    children: [
      {
        key: "511",
        label: <Link to="/teachers-info/departments">Departments</Link>,
      },
      {
        key: "512",
        label: <Link to="/teachers-info/designations">Designations</Link>,
      },
      {
        key: "513",
        label: <Link to="/teachers-info/teachers">Teachers</Link>,
      },
      {
        key: "514",
        label: (
          <Link to="/teachers-info/teacher-attendance">Teacher Attendance</Link>
        ),
      },
    ],
  },
  {
    key: "4",
    icon: <PiStudentFill />,
    label: "Student Data",
    children: [
      {
        key: "31",
        label: <Link to="/student-data/students">Students</Link>,
      },
      {
        key: "32",
        label: (
          <Link to="/student-data/student-attendances">
            Student Attendances
          </Link>
        ),
      },
      {
        key: "33",
        label: <Link to="/student-data/enrollments">Enrollments</Link>,
      },
      {
        key: "34",
        label: (
          <Link to="/student-data/promotion-records">Promotion Records</Link>
        ),
      },
    ],
  },
  {
    key: "5",
    icon: <MdMenuBook />,
    label: "Learning",
    children: [
      {
        key: "661",
        label: <Link to="/learning/online-classes">Online Classees</Link>,
      },
      {
        key: "662",
        label: <Link to="/learning/study-materials">Study Materials</Link>,
      },
    ],
  },
  {
    key: "6",
    icon: <PiExamFill />,
    label: "Exams Data",
    children: [
      {
        key: "771",
        label: <Link to="/exam-data/exam-types">Exam Types</Link>,
      },
      {
        key: "772",
        label: <Link to="/exam-data/grades">Grades</Link>,
      },
      {
        key: "773",
        label: <Link to="/exam-data/final-grades">Final Grades</Link>,
      },
      {
        key: "774",
        label: <Link to="/exam-data/exams">Exams</Link>,
      },
      {
        key: "775",
        label: <Link to="/exam-data/exam-results">Exam Results</Link>,
      },
      {
        key: "776",
        label: <Link to="/exam-data/student-reports">Student Reports</Link>,
      },
    ],
  },
  {
    key: "7",
    icon: <MdAccountTree />,
    label: "Accounting",
    children: [
      {
        key: "881",
        label: <Link to="/accounting/budgets">Budgets</Link>,
      },
      {
        key: "882",
        label: <Link to="/accounting/expense-types">Expense Types</Link>,
      },
      {
        key: "883",
        label: <Link to="/accounting/expenses">Expenses</Link>,
      },
      {
        key: "884",
        label: <Link to="/accounting/fees-structure">Fees Structure</Link>,
      },
      {
        key: "885",
        label: <Link to="/accounting/fees-payment">Fees Payment</Link>,
      },
      {
        key: "886",
        label: <Link to="/accounting/ledgers">Ledgers</Link>,
      },
      {
        key: "887",
        label: <Link to="/accounting/transactions">Transactions</Link>,
      },
    ],
  },
  {
    key: "10",
    icon: <MdOutlinePayment />,
    label: "Payroll Info",
    children: [
      {
        key: "101",
        label: <Link to="/payroll-info/leave-requests">Leave Requests</Link>,
      },
      {
        key: "102",
        label: <Link to="/payroll-info/pay-schedules">Pay Schedules</Link>,
      },
      {
        key: "103",
        label: <Link to="/payroll-info/payrolls">payrolls</Link>,
      },
      {
        key: "104",
        label: <Link to="/payroll-info/payroll-items">Payroll Items</Link>,
      },
      {
        key: "105",
        label: <Link to="/payroll-info/salary-slips">salary Slips</Link>,
      },
    ],
  },
  {
    key: "8",
    icon: <FaUsersCog />,
    label: <Link to="/users">Users</Link>,
  },
  {
    key: "9",
    icon: <CgWebsite />,
    label: "Website",
    children: [
      {
        key: "56",
        label: "Components",
        children: [
          {
            key: "43",
            label: <Link to="/website/components/careers">Careers</Link>,
          },
          {
            key: "47",
            label: <Link to="/website/components/events">Events</Link>,
          },
          {
            key: "52",
            label: (
              <Link to="/website/components/school-schedules">
                School Schedules
              </Link>
            ),
          },
          {
            key: "53",
            label: (
              <Link to="/website/components/image-gallery">Image Gallery</Link>
            ),
          },

          {
            key: "55",
            label: (
              <Link to="/website/components/school-facilities">
                School Facilities
              </Link>
            ),
          },
          {
            key: "561",
            label: (
              <Link to="/website/components/school-specialities">
                School Specialities
              </Link>
            ),
          },
          {
            key: "562",
            label: <Link to="/website/components/sliders">Sliders</Link>,
          },
          {
            key: "563",
            label: (
              <Link to="/website/components/testimonials">Testimonials</Link>
            ),
          },
          {
            key: "564",
            label: <Link to="/website/components/notices">Notices</Link>,
          },
          {
            key: "46",
            label: (
              <Link to="/website/components/contact-us-messages">
                Contact Us Messages
              </Link>
            ),
          },
        ],
      },
      {
        key: "57",
        label: "Pages",
        children: [
          {
            key: "49",
            label: <Link to="/website/pages/home">Home</Link>,
          },
          {
            key: "41",
            label: <Link to="/website/pages/about">About</Link>,
          },
          {
            key: "42",
            label: <Link to="/website/pages/admission">Admission</Link>,
          },
          {
            key: "44",
            label: <Link to="/website/pages/career">Career</Link>,
          },
          {
            key: "50",
            label: <Link to="/website/pages/notice">Notice</Link>,
          },
          {
            key: "48",
            label: <Link to="/website/pages/gallery">Gallery</Link>,
          },
          {
            key: "45",
            label: <Link to="/website/pages/contact-us">Contact Us</Link>,
          },
          {
            key: "51",
            label: (
              <Link to="/website/pages/principals-message">
                Principal's Message
              </Link>
            ),
          },
          {
            key: "571",
            label: <Link to="/website/pages/teachers">Teachers</Link>,
          },
          {
            key: "572",
            label: (
              <Link to="/website/pages/schools-policy">Schools Policy</Link>
            ),
          },
          {
            key: "573",
            label: (
              <Link to="/website/pages/rules-regulations">
                Rules & Regulations
              </Link>
            ),
          },
          {
            key: "574",
            label: <Link to="/website/pages/curriculums">Curriculums</Link>,
          },
          {
            key: "575",
            label: <Link to="/website/pages/class-routine">Class Routine</Link>,
          },
          {
            key: "576",
            label: (
              <Link to="/website/pages/academic-calendar">
                Academic Calendar
              </Link>
            ),
          },
          {
            key: "577",
            label: <Link to="/website/pages/events">Events</Link>,
          },
        ],
      },
      {
        key: "58",
        label: "Settings",
        children: [
          {
            key: "581",
            label: (
              <Link to="/website/settings/global-setting">Global Setting</Link>
            ),
          },
          {
            key: "582",
            label: <Link to="/website/settings/admin-pages">Admin Pages</Link>,
          },
        ],
      },
    ],
  },
];
