import { createSliceFactory } from "../feature-components/sliceFactory";

const { reducer, fetchAll, fetchSingle, postData, patchData, error } =
  createSliceFactory({
    name: "studentAttendance",
    url: "student/admin/attendance/",
    fetchParams: (params) => {
      return params;
    },
  });

export { fetchAll, fetchSingle, postData, patchData, error };
export default reducer;
