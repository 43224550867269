import { Image, Modal } from "antd";
import React from "react";

const PromoteModal = ({ open, onClose, data }) => {
  const handleCancel = () => {
    onClose();
  };
  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      title={<p className="text-dark fw-6 fs-4">Promote Student</p>}
    >
      <div
        style={{ maxHeight: 150 }}
        className="flex aic justify-center pro-image"
      >
        <Image
          //   style={{ height: "100%", objectFit: "cover" }}
          src={data.user.image.image}
          alt={data.user.first_name}
        />
      </div>
    </Modal>
  );
};

export default PromoteModal;
