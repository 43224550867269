import { axiosInstance } from "./axiosBase";

// getting token

const tokens = localStorage.getItem("token");
console.log("token from axiosInstance", tokens);

// post document instance
export const uploadDocument = async (formData) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post("auth/documents/", formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("document upload response", response);

    return response;
  } catch (error) {
    console.log(error);

    const errorMessage =
      error.response && error.response.data
        ? error.response.data.message || "Error uploading document"
        : "Error uploading document";

    return errorMessage;
  }
};

// post image instance
export const uploadImage = async (formData) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post("website/image/", formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("image upload response", response.data);

    return response;
  } catch (error) {
    const errorMessage =
      error.response && error.response.data
        ? error.response.data.message || "Error uploading document"
        : "Error uploading document";

    return errorMessage;
  }
};

// post general data
export const postData = async (url, dataSet) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post(url, dataSet, {
      headers: { Authorization: token },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// get general data
export const getData = async (url, params = {}, navigate) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.get(url, {
      headers: { Authorization: token },
      params: params,
    });
    return response;
  } catch (err) {
    if (err.response.data === "Invalid token.") {
      localStorage.removeItem("token");
      navigate("/sing-in");
    }

    throw err;
  }
};

// delete genaral data
export const deleteData = async (url) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.delete(url, {
      headers: { Authorization: token },
    });
    return response;
  } catch (err) {
    console.error("Error deleting data:", err);
    throw err;
  }
};

// patch general data
export const patchData = async (url, dataSet) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.patch(url, dataSet, {
      headers: { Authorization: token },
    });
    return response;
  } catch (err) {
    console.error("Error updating data:", err.response.data);
    throw err;
  }
};

// put general data
export const putData = async (url, dataSet) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.put(url, dataSet, {
      headers: { Authorization: token },
    });
    return response;
  } catch (err) {
    console.error("Error updating data:", err);
    throw err;
  }
};
