import { createSliceFactory } from "../feature-components/sliceFactory";

const { reducer, fetchAll, fetchSingle, postData, patchData } =
  createSliceFactory({
    name: "students",
    url: "student/admin/students/",
    fetchParams: (params) => {
      return params;
    },
  });

export { fetchAll, fetchSingle, postData, patchData };
export default reducer;
