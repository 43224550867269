import React from "react";
import { Route, Routes } from "react-router-dom";
import Budget from "../view/accounting/budget/Budget";
import { ProtectedRoutes } from "./ProtectedRoutes";
import Expense from "../view/accounting/expense/Expense";
import ExpenseType from "../view/accounting/expense/ExpenseType";
import FeesStructure from "../view/accounting/fees/FeesStructure";
import FeesPayment from "../view/accounting/fees/FeesPayment";
import Ledger from "../view/accounting/ledger/Ledger";
import Transactions from "../view/accounting/transaction/Transactions";

const AccountingRoutes = () => {
  return (
    <Routes>
      <Route path="/budgets" element={<ProtectedRoutes Component={Budget} />} />
      <Route
        path="/expense-types"
        element={<ProtectedRoutes Component={ExpenseType} />}
      />
      <Route
        path="/expenses"
        element={<ProtectedRoutes Component={Expense} />}
      />
      <Route
        path="/fees-structure"
        element={<ProtectedRoutes Component={FeesStructure} />}
      />
      <Route
        path="/fees-payment"
        element={<ProtectedRoutes Component={FeesPayment} />}
      />
      <Route path="/ledgers" element={<ProtectedRoutes Component={Ledger} />} />
      <Route
        path="/transactions"
        element={<ProtectedRoutes Component={Transactions} />}
      />
    </Routes>
  );
};

export default AccountingRoutes;
