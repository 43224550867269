import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  message,
  Row,
  Spin,
  TimePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getData, postData } from "../../../libs/axios/axiosInstance";
import dayjs from "dayjs";
import usePageTitle from "../../../components/PageTitle";
import { HomeOutlined } from "@ant-design/icons";

const SchoolSchedule = () => {
  usePageTitle("School Schedules");
  const dayConstants = [
    { title: "Saturday", value: 0 },
    { title: "Sunday", value: 1 },
    { title: "Monday", value: 2 },
    { title: "Tuesday", value: 3 },
    { title: "Wednesday", value: 4 },
    { title: "Thursday", value: 5 },
    { title: "Friday", value: 6 },
  ];

  const [schedules, setSchedules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSchedules = async () => {
    setIsLoading(true);
    try {
      const fetchReq = await getData("website/school-schedule/");
      if (fetchReq.status === 200 || 201) {
        setSchedules(fetchReq.data);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSchedules();
  }, []);

  const handleTimeChange = (time, timeString, id, type) => {
    const updatedSchedules = schedules.map((item) =>
      item.id === id ? { ...item, [type]: timeString } : item
    );
    setSchedules(updatedSchedules);
  };

  const handleHolidayChange = (e, id) => {
    const updatedSchedules = schedules.map((item) =>
      item.id === id ? { ...item, is_holiday: e.target.checked } : item
    );
    setSchedules(updatedSchedules);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await postData("website/school-schedule/", schedules);
      if (response.status === 200 || 201) {
        message.success("Schedules updated successfully!");
      }
    } catch (error) {
      console.log("Error updating schedules:", error);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div className="breadcrumb p-4">
        <div>
          <h1 className="fs-2 text-dark">School Schedules</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    <HomeOutlined />
                  </Link>
                ),
              },
              { title: "Website" },
              { title: "components" },
              { title: "School Schedules" },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <Row gutter={12}>
              {schedules &&
                schedules.map((item) => (
                  <Col xs={24} sm={24} md={12} lg={8} xl={8} key={item.id}>
                    <div className="rounded-10 shadow-sm mb-3">
                      <Card
                        title={
                          <span className="fs-3">
                            {dayConstants[item.day].title}
                          </span>
                        }
                        extra={
                          <Checkbox
                            checked={item.is_holiday}
                            onChange={(e) => handleHolidayChange(e, item.id)}
                          >
                            Holiday?
                          </Checkbox>
                        }
                        bordered={false}
                      >
                        <div className="flex-justify-between">
                          <div>
                            <p className="mb-2">Opening Time</p>
                            <TimePicker
                              className="w-100-r"
                              value={
                                item.opening_time
                                  ? dayjs(item.opening_time, "HH:mm:ss")
                                  : null
                              }
                              format="HH:mm:ss"
                              onChange={(time, timeString) =>
                                handleTimeChange(
                                  time,
                                  timeString,
                                  item.id,
                                  "opening_time"
                                )
                              }
                              disabled={item.is_holiday}
                            />
                          </div>
                          <div>
                            <p className="mb-2">Closing Time</p>
                            <TimePicker
                              value={
                                item.closing_time
                                  ? dayjs(item.closing_time, "HH:mm:ss")
                                  : null
                              }
                              format="HH:mm:ss"
                              onChange={(time, timeString) =>
                                handleTimeChange(
                                  time,
                                  timeString,
                                  item.id,
                                  "closing_time"
                                )
                              }
                              disabled={item.is_holiday}
                            />
                          </div>
                        </div>
                      </Card>
                    </div>
                  </Col>
                ))}
            </Row>
          </Spin>
          <Button
            className="w-100 mt-3"
            type="primary"
            size="large"
            htmlType="submit"
            loading={isLoading}
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default SchoolSchedule;
