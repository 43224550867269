import React, { useEffect, useState } from "react";
import BreadBtn from "../../../components/BreadBtn";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Input, message, Row, Select, Spin, Tag } from "antd";
import UserInfo from "../../../components/UserInfo";
import { useDispatch, useSelector } from "react-redux";
import PageCard from "../../../components/PageCard";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchSections } from "../../../libs/redux/features/academic/sectionsNewSlice";
import { getDocIcon } from "../../../components/utility";
import DocumentPopup from "../../../components/DocumentPopup";
import {
  fetchSingle,
  patchData,
  postData,
} from "../../../libs/redux/features/student-info/studentsSlice";

const { Option } = Select;

const StudentForm = () => {
  const breadcrumbitems = [
    { title: "Student Data" },
    { title: <Link to="/student-data/students">Students</Link> },
    { title: "Add Student" },
  ];

  const { id } = useParams();
  const dispatch = useDispatch();

  const singleData = useSelector((state) => state.students.singleData);
  const error = useSelector((state) => state.students.error);
  const isLoading = useSelector((state) => state.students.isLoading);
  const classesData = useSelector((state) => state.classesNew.data);
  const sectionsData = useSelector((state) => state.sectionsNew.data);

  const [student, setStudent] = useState({
    user: {
      first_name: "",
      last_name: "",
      image: {},
      dob: "",
      bio: "",
      email: "",
      mobile: "",
      password: "",
      confirm_password: "",
      gender: 0,
    },
    school_class: null,
    section: null,
    address: "",
    previous_school: "",
    additional_notes: "",
    documents: [],
  });

  useEffect(() => {
    if (id) {
      dispatch(fetchSingle(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setStudent({
        user: singleData.user,
        school_class: singleData.current_class,
        section: singleData.current_section,
        address: singleData.address,
        previous_school: singleData.previous_school,
        additional_notes: singleData.additional_notes,
        documents: singleData.documents,
      });
    }
  }, [id, singleData]);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
  }, [dispatch, classesData.length]);

  useEffect(() => {
    if (student.school_class) {
      dispatch(fetchSections({ class_level: student.school_class }));
    } else {
      dispatch(fetchSections());
    }
  }, [student.school_class, dispatch]);

  const [userImageModal, setUserImageModal] = useState(false);

  const handleUserChange = (update) => {
    setStudent((prev) => ({
      ...prev,
      user: update(prev.user),
    }));
  };

  const handleGenderChange = (gender) => {
    setStudent((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        gender: gender,
      },
    }));
  };

  const handleUserImageUploadSuccess = (userImg) => {
    setStudent((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        image: userImg[0],
      },
    }));
    setUserImageModal(false);
  };

  const [documentModal, setDocumentModal] = useState(false);

  const handleDocumentClose = (removedItem) => {
    setStudent((prev) => ({
      ...prev,
      documents: prev.documents.filter((item) => item.id !== removedItem.id),
    }));
  };

  const handleDocuments = (data) => {
    setStudent((prev) => ({
      ...prev,
      documents: [...prev.documents, ...data],
    }));
    setDocumentModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      ...student,
      user: {
        ...student.user,
        image: student.user.image.id,
      },
      documents: student.documents.map((item) => item.id),
    };
    console.log("student payload", payload);

    if (id) {
      dispatch(patchData({ id, payload }));
    } else {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New student created successfully!");
            setStudent({
              user: {
                first_name: "",
                last_name: "",
                image: {},
                dob: "",
                bio: "",
                email: "",
                mobile: "",
                password: "",
                confirm_password: "",
                gender: 0,
              },
              school_class: null,
              section: null,
              address: "",
              previous_school: "",
              additional_notes: "",
              documents: [],
            });
          },
        })
      );
    }
  };

  return (
    <div>
      <BreadBtn headerTitle="Add Student" items={breadcrumbitems} />
      <div className="page-content-section">
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <UserInfo
              user={student.user}
              onUserChange={handleUserChange}
              onGenderChange={handleGenderChange}
              userImageModal={userImageModal}
              setUserImageModal={setUserImageModal}
              onUserImageUploadSuccess={handleUserImageUploadSuccess}
              error={error && error.user}
              id={id}
            />
            <PageCard
              title="General Informations"
              content={
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Class</p>
                        {error && error.school_class ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.school_class})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        showSearch
                        placeholder="Select Class"
                        optionFilterProp="children"
                        className="w-100"
                        size="large"
                        onChange={(value) =>
                          setStudent((prev) => ({
                            ...prev,
                            school_class: value,
                          }))
                        }
                        value={student.school_class || null}
                        allowClear
                        onClear={() =>
                          setStudent((prev) => ({
                            ...prev,
                            school_class: null,
                          }))
                        }
                        status={error && error.school_class ? "error" : ""}
                      >
                        {classesData && classesData.length > 0
                          ? classesData.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))
                          : ""}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6"> Section</p>
                        {error && error.section ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.section})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        showSearch
                        placeholder="Select Section"
                        optionFilterProp="children"
                        className="w-100"
                        size="large"
                        onChange={(value) =>
                          setStudent((prev) => ({
                            ...prev,
                            section: value,
                          }))
                        }
                        value={student.section || null}
                        allowClear
                        onClear={() =>
                          setStudent((prev) => ({
                            ...prev,
                            section: null,
                          }))
                        }
                        status={error && error.section ? "error" : ""}
                      >
                        {sectionsData && sectionsData.length > 0
                          ? sectionsData.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))
                          : "No section for the selected class"}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Address</p>
                        {error && error.address ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.address})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Address"
                        value={student.address}
                        onChange={(e) =>
                          setStudent((prev) => ({
                            ...prev,
                            address: e.target.value,
                          }))
                        }
                        required
                        status={error && error.address ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Previous School</p>
                        {error && error.previous_school ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.previous_school})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Previous School"
                        value={student.previous_school}
                        onChange={(e) =>
                          setStudent((prev) => ({
                            ...prev,
                            previous_school: e.target.value,
                          }))
                        }
                        required
                        status={error && error.previous_school ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Additional Notes</p>
                        {error && error.additional_notes ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.additional_notes})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Additional Notes"
                        value={student.additional_notes}
                        onChange={(e) =>
                          setStudent((prev) => ({
                            ...prev,
                            additional_notes: e.target.value,
                          }))
                        }
                        required
                        status={error && error.additional_notes ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24} className="">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Documents</p>
                      {error && error.documents ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.documents})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex aic">
                      <Button
                        onClick={() => setDocumentModal(true)}
                        className={`flex justify-center aic rounded-10`}
                        style={{ height: 100, width: 100 }}
                        danger={error && error.documents ? true : false}
                      >
                        + Upload
                      </Button>
                      <div className="ms-3">
                        {student.documents && student.documents.length > 0
                          ? student.documents.map((item) => (
                              <Tag
                                key={item.id}
                                closable
                                onClose={() => handleDocumentClose(item)}
                                icon={getDocIcon(item.doc_type)}
                                className="m-1 p-2 flex aic"
                                color="success"
                              >
                                {item.title}
                              </Tag>
                            ))
                          : "No file selected"}
                      </div>
                    </div>
                  </Col>
                </Row>
              }
            />
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="w-100 my-5"
            >
              Submit
            </Button>
          </form>
        </Spin>
      </div>
      {/* documents */}
      {documentModal && (
        <DocumentPopup
          open={documentModal}
          onCancel={() => setDocumentModal(false)}
          onUploadSuccess={handleDocuments}
          docDescription={`${student.user.first_name}-${student.user.last_name}-${student.user.dob}`}
        />
      )}
    </div>
  );
};

export default StudentForm;
