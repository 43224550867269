import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "./ProtectedRoutes";
import ExamType from "../view/exams-data/exam-type/ExamType";
import Grades from "../view/exams-data/grades/Grades";
import Exams from "../view/exams-data/exams/Exams";
import FinalGrades from "../view/exams-data/final-grades/FinalGrades";
import ExamResults from "../view/exams-data/exam-results/ExamResults";
import StudentReports from "../view/exams-data/student-report/StudentReports";

const ExamRoutes = () => {
  return (
    <Routes>
      <Route
        path="/exam-types"
        element={<ProtectedRoutes Component={ExamType} />}
      />
      <Route path="/grades" element={<ProtectedRoutes Component={Grades} />} />
      <Route
        path="/final-grades"
        element={<ProtectedRoutes Component={FinalGrades} />}
      />
      <Route path="/exams" element={<ProtectedRoutes Component={Exams} />} />
      <Route
        path="/exam-results"
        element={<ProtectedRoutes Component={ExamResults} />}
      />
      <Route
        path="/student-reports"
        element={<ProtectedRoutes Component={StudentReports} />}
      />
    </Routes>
  );
};

export default ExamRoutes;
