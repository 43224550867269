import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../axios/axiosBase";

export const fetchCountries = createAsyncThunk(
  "countries/fetchCountries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("auth/country/");
      console.log("fetched country response", response.data);
      return response.data.data.results;
    } catch (error) {
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.message || "Error fetching countries"
          : "Error fetching countries";
      return rejectWithValue(errorMessage);
    }
  }
);

const countrySlice = createSlice({
  name: "countries",
  initialState: {
    isLoading: false,
    data: [],
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
        console.log("Updated state with fetched country:", state.data); // Debug log
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = action.payload || action.error.message;
      });
  },
});

export default countrySlice.reducer;
