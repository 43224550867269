import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

// Fetch classes with optional filters
export const fetchClasses = createAsyncThunk(
  "classes/fetchClasses",
  async (
    { name = "", limit = 10, offset = 0, is_active } = {},
    { rejectWithValue }
  ) => {
    try {
      const params = {
        limit,
        offset,
        name, // Use 'name' as query param
      };

      if (is_active !== undefined) {
        params.is_active = is_active;
      }

      const response = await getData("academic/admin/school-classes/", params);
      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch a single class by ID
export const fetchSingleClassItem = createAsyncThunk(
  "classes/fetchSingleClassItem",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`academic/admin/school-classes/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Post a new class
export const postClass = createAsyncThunk(
  "classes/postClass",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    console.log("payload", payload);

    try {
      const response = await postData(
        "academic/admin/school-classes/",
        payload
      );
      if (response.status === 200 || 201) {
        message.success("Class created successfully!");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.name?.[0] ||
          error.response.statusText ||
          "Failed to post class.";
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Update an existing class by ID
export const putClass = createAsyncThunk(
  "classes/putClass",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      console.log("put payload", payload);

      const response = await putData(
        `academic/admin/school-classes/${id}/`,
        payload
      );
      console.log("put res", response);

      if (response.status === 200 || 201) {
        message.success("Class updated successfully!");
        fetchSingleClassItem(id);
      }
      return response.data;
    } catch (error) {
      // console.log(error.response.data);

      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.name?.[0] ||
          error.response.data.non_field_errors?.[0] ||
          error.response.statusText ||
          "Failed to update class.";
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Classes slice
const classesSlice = createSlice({
  name: "classes",
  initialState: {
    data: [],
    singleData: {},
    isLoading: false,
    offset: 0,
    limit: 10,
    totalItems: 0,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClasses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchClasses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchClasses.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = action.payload;
      })
      .addCase(fetchSingleClassItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleClassItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleData = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleClassItem.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postClass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postClass.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postClass.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(putClass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putClass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(putClass.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default classesSlice.reducer;
