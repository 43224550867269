import {
  Breadcrumb,
  Button,
  Col,
  Image,
  Input,
  message,
  Row,
  Spin,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UploadButton from "../../../components/UploadButton";
import ImagePopup from "../../../components/ImagePopup";
import { getData, postData } from "../../../libs/axios/axiosInstance";
import useScrollEffect from "../../../components/scroEffect";
import usePageTitle from "../../../components/PageTitle";

const CareerPage = () => {
  usePageTitle("Career Page");
  const isAtTop = useScrollEffect();
  const [careerPage, setCareerPage] = useState({
    seo: {
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    },
    page_title: "",
    featured_image: {},
  });
  const [featuredImageModal, setFeaturedImageModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleImageUploadSuccess = (newImg) => {
    setCareerPage((prev) => ({
      ...prev,
      featured_image: newImg[0],
    }));
    setFeaturedImageModal(false);
  };

  const fetchCareerPage = async () => {
    setIsLoading(true);
    try {
      const res = await getData("website/careerpage/");
      if (res.status === 200) {
        const data = res.data;
        setCareerPage({
          seo: data.seo,
          page_title: data.page_title,
          featured_image: data.featured_image,
        });
        setIsLoading(false);
      } else {
        message.error("error getting career page data");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error fetching career page", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCareerPage();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
      let payload = careerPage;
      payload.featured_image = careerPage.featured_image.id;
      const postCareerPage = await postData("website/careerpage/", payload);

      if (postCareerPage.status === 200 || 201) {
        message.success("Career page added successfully!");
        fetchCareerPage();
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  return (
    <div>
      <div
        className={`breadcrumb flex-justify-between ${
          !isAtTop ? "sticky" : ""
        }`}
      >
        <div>
          <h1 className={`fs-2 text-dark ${!isAtTop ? "lh-1" : ""}`}>
            Career Page
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Website" },
              { title: "Career Page" },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <div className="bg-white rounded-10 mb-5">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">SEO Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row className="mb-5">
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Meta Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Title"
                        value={careerPage.seo.meta_title}
                        onChange={(e) =>
                          setCareerPage((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_title: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Meta Descriptions</p>
                      <TextArea
                        className="w-100"
                        placeholder="Meta Descriptions"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        value={careerPage.seo.meta_description}
                        onChange={(e) =>
                          setCareerPage((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_description: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="">
                      <p className="mb-2 ps-2 fw-6">
                        Meta Keywords (comma separated)
                      </p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Keywords"
                        value={careerPage.seo.meta_keywords}
                        onChange={(e) =>
                          setCareerPage((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_keywords: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Page Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Page Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Page Title"
                        value={careerPage.page_title}
                        onChange={(e) =>
                          setCareerPage((prev) => ({
                            ...prev,
                            page_title: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Featured Image</p>
                      <div className="image-preview-grid">
                        {careerPage.featured_image &&
                        Object.keys(careerPage.featured_image).length > 0 ? (
                          <Image
                            height={100}
                            width={100}
                            className="object-cover rounded-5"
                            alt={careerPage.featured_image.alt_text}
                            src={careerPage.featured_image.image}
                          />
                        ) : (
                          ""
                        )}
                        <UploadButton
                          onClick={() => setFeaturedImageModal(true)}
                          height={100}
                          width={100}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className="w-100 my-5"
                      loading={isLoading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </form>
        </Spin>
      </div>
      {featuredImageModal && (
        <ImagePopup
          imgLimit={1}
          existingImgs={[careerPage.featured_image]}
          open={featuredImageModal}
          onCancel={() => setFeaturedImageModal(false)}
          onUploadSuccess={handleImageUploadSuccess}
          cropAspectRatio={16 / 9}
          imageTitle={careerPage.page_title}
        />
      )}
    </div>
  );
};

export default CareerPage;
