import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Image,
  Spin,
  Input,
  Modal,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGallery,
  fetchSingleImage,
  patchSingleImage,
} from "../../../libs/redux/features/website/gallerySlice";
import { handleDelete } from "../../../components/utility";
import usePageTitle from "../../../components/PageTitle";
import AddImage from "./AddImage";

const { Search } = Input;
const { Meta } = Card;

const ImageGallery = () => {
  usePageTitle("Image Gallery");
  const dispatch = useDispatch();
  const {
    data,
    isLoading,
    hasMore,
    offset,
    limit,
    singleImage,
    singleImageLoading,
  } = useSelector((state) => state.gallery);

  const [addImageModal, setAddImageModal] = useState(false);

  useEffect(() => {
    if (data.length === 0) {
      dispatch(fetchGallery({ limit, offset: 0 }));
    }
  }, [dispatch, data.length, limit]);

  const handleSearch = (value) => {
    dispatch(fetchGallery({ search: value }));
  };

  const handleLoadMore = () => {
    if (hasMore) {
      const newOffset = offset + limit;
      dispatch(fetchGallery({ limit, offset: newOffset }));
    }
  };

  const [imageModal, setImageModal] = useState(false);
  const [imagePatch, setImagePatch] = useState({
    title: "",
    caption: "",
    alt_text: "",
  });

  const modalOpen = (id) => {
    dispatch(fetchSingleImage(id));
    setImageModal(true);
  };

  useEffect(() => {
    if (singleImage) {
      setImagePatch({
        title: singleImage.title,
        caption: singleImage.caption,
        alt_text: singleImage.alt_text,
      });
    }
  }, [singleImage]);

  const imageModalCancel = () => {
    setImageModal(false);
  };

  const handleImagePatch = () => {
    dispatch(patchSingleImage({ id: singleImage.id, payload: imagePatch }));
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between p-4">
        <div>
          <h1 className="fs-2 text-dark">Image Gallery</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Website" },
              { title: "Components" },
              { title: "Image Gallery" },
            ]}
          />
        </div>
        <div>
          <Button
            onClick={() => setAddImageModal(true)}
            type="primary"
            icon={<PlusOutlined />}
            size="large"
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <form className="mb-5" onSubmit={(e) => e.preventDefault()}>
          <Search
            placeholder="Search Image"
            enterButton="Search"
            size="large"
            onSearch={handleSearch}
            loading={isLoading}
          />
        </form>
        <Spin spinning={isLoading}>
          <Row gutter={12}>
            {data &&
              data.map((item, index) => (
                <Col
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={4}
                  key={`${item.id}-index-${index}`}
                  className="mb-3"
                >
                  <div className="shadow-sm rounded-10">
                    <Card
                      cover={
                        <Image
                          loading="lazy"
                          alt={item.alt_text}
                          src={item.image}
                          style={{
                            borderRadius: "10px 10px 0 0",
                            height: 200,
                            objectFit: "cover",
                          }}
                        />
                      }
                      actions={[
                        <DeleteOutlined
                          className="text-danger c-pointer delete-btn"
                          key="delete"
                          onClick={() =>
                            handleDelete(
                              `${item.title}`,
                              dispatch,
                              `website/image/${item.id}/`,
                              fetchGallery,
                              { limit: limit, offset: 0 } // Reset offset after delete
                            )
                          }
                        />,
                        <EditOutlined
                          key="edit"
                          className="delete-btn c-pointer"
                          onClick={() => modalOpen(item.id)}
                        />,
                      ]}
                    >
                      <Meta title={item.title} description={item.caption} />
                    </Card>
                  </div>
                </Col>
              ))}
          </Row>
          {hasMore && (
            <div className="w-100 flex justify-center my-3">
              <Button type="primary" onClick={handleLoadMore}>
                Load More
              </Button>
            </div>
          )}
        </Spin>
      </div>
      {/* image modal */}
      <Modal
        title="Image detail"
        centered
        open={imageModal}
        width={700}
        onCancel={imageModalCancel}
        loading={singleImageLoading}
        onOk={handleImagePatch}
      >
        <Spin spinning={singleImageLoading}>
          <div className="modal-image-wrap mb-3">
            <img
              src={singleImage.image}
              alt={singleImage.alt_text}
              loading="lazy"
            />
          </div>
          <form>
            <div className="mb-3">
              <p className="mb-2">Image Title</p>
              <Input
                type="text"
                placeholder="Image Title"
                value={imagePatch.title}
                onChange={(e) =>
                  setImagePatch((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }))
                }
              />
            </div>
            <div className="mb-3">
              <p className="mb-2">Image Caption</p>
              <Input
                type="text"
                placeholder="Image Caption"
                value={imagePatch.caption}
                onChange={(e) =>
                  setImagePatch((prev) => ({
                    ...prev,
                    caption: e.target.value,
                  }))
                }
              />
            </div>
            <div className="mb-4">
              <p className="mb-2">Image Alternative Title</p>
              <Input
                type="text"
                placeholder="Image Alternative Title"
                value={imagePatch.alt_text}
                onChange={(e) =>
                  setImagePatch((prev) => ({
                    ...prev,
                    alt_text: e.target.value,
                  }))
                }
              />
            </div>
          </form>
        </Spin>
      </Modal>
      {/* add image modal */}
      <AddImage
        open={addImageModal}
        onCancel={() => setAddImageModal(false)}
        onUploadSuccess={() => {
          message.success("Image successfully added!");
          dispatch(fetchGallery({ limit, offset: 0 }));
          setAddImageModal(false);
        }}
      />
    </div>
  );
};

export default ImageGallery;
