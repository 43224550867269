import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

export const fetchAcademicYear = createAsyncThunk(
  "academicYear/fetchAcademicYear",
  async (
    { start_date, end_date, is_active, limit = 10, offset = 0 } = {},
    { rejectWithValue }
  ) => {
    try {
      const params = {
        limit,
        offset,
      };

      if (start_date) params.start_date = start_date;
      if (end_date) params.end_date = end_date;
      if (is_active !== undefined) params.is_active = is_active;

      const response = await getData(`academic/admin/academic-years/`, params);

      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postAcademicYear = createAsyncThunk(
  "academicYear/postAcademicYear",

  async ({ academicData, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await postData(
        "academic/admin/academic-years/",
        academicData
      );
      // console.log("post academic", response);
      if (response.data.status === 200 || 201) {
        message.success("Academic year added successfully");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      if (error.response.data.non_field_errors) {
        let errorMessage = error.response.data.non_field_errors[0];
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSingleAcademicYear = createAsyncThunk(
  "academicYear/fetchSingleAcademicYear",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`academic/admin/academic-years/${id}/`);

      return response.data;
    } catch (error) {
      if (error.response.data.non_field_errors) {
        let errorMessage = error.response.data.non_field_errors[0];
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAcademicYear = createAsyncThunk(
  "academicYear/updateAcademicYear",
  async ({ id, Data }, { rejectWithValue }) => {
    try {
      const response = await putData(
        `academic/admin/academic-years/${id}/`,
        Data
      );
      // console.log("put response", response);

      if (response.status === 200) {
        message.success("successfully updated!");
      }
      return response.data;
    } catch (error) {
      if (error.response.data.non_field_errors) {
        let errorMessage = error.response.data.non_field_errors[0];
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const academicYearSlice = createSlice({
  name: "academicYear",
  initialState: {
    isLoading: false,
    singleDataLoading: false,
    data: [],
    singleData: {},
    postResponse: {},
    totalItems: 0,
    error: null,
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAcademicYear.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAcademicYear.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchAcademicYear.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.totalItems = 0;
        state.error = action.payload;
      })
      .addCase(postAcademicYear.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postAcademicYear.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postResponse = action.payload;
        state.error = null;
        // console.log("posted state with new academic:", state.postResponse); // Debug log
      })
      .addCase(postAcademicYear.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getSingleAcademicYear.pending, (state) => {
        state.singleDataLoading = true;
      })
      .addCase(getSingleAcademicYear.fulfilled, (state, action) => {
        state.singleDataLoading = false;
        state.singleData = action.payload;
        state.error = null;
        // console.log("fetched single data", state.singleData);
      })
      .addCase(getSingleAcademicYear.rejected, (state, action) => {
        state.singleDataLoading = false;
        state.singleData = {};
        state.error = action.payload;
      })
      .addCase(updateAcademicYear.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAcademicYear.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleData = action.payload;
        state.data = state.data.map((item) =>
          item.id === action.payload.id ? action.payload : item
        );
        state.error = null;
      })
      .addCase(updateAcademicYear.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default academicYearSlice.reducer;
