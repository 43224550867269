import {
  Breadcrumb,
  Button,
  DatePicker,
  Input,
  Modal,
  Pagination,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll } from "../../../libs/redux/features/accounting/transactionSlice";
import {
  downloadPDF,
  downloadXLS,
  generatePDFReport,
  renderDescription,
} from "../../../components/utility";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import DataConversion from "../../../components/DataConversion";
import { getData } from "../../../libs/axios/axiosInstance";
import TransactionModal from "./TransactionModal";

const { Search } = Input;

const Transactions = () => {
  usePageTitle("Transactions");

  const breadcrumbitems = [
    {
      title: "Accounting",
    },
    { title: "Transactions" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const transactionsData = useSelector((state) => state.transactions.data);
  const totalItems = useSelector((state) => state.transactions.totalItems);
  const isLoading = useSelector((state) => state.transactions.isLoading);

  const [contentTypes, setContentTypes] = useState([]);

  const fetchContentTypes = async () => {
    try {
      const response = await getData("accounting/admin/content-types/");
      if (response.status === 200) {
        setContentTypes(response.data);
      }
    } catch (error) {
      console.log("error getting content types", error.response);
    }
  };

  useEffect(() => {
    fetchContentTypes();
  }, []);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    start_date: "",
    end_date: "",
    search: "",
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };

    if (filters.start_date !== "") {
      params.start_date = filters.start_date;
    }
    if (filters.end_date !== "") {
      params.end_date = filters.end_date;
    }
    if (filters.search !== "") {
      params.search = filters.search;
    }

    dispatch(fetchAll(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Amount: item.amount || "N/A",
    "Transaction Date": item.transaction_date || "N/A",
    "Object Id": item.object_id || "N/A",
    Description: item.description || "N/A",
    "Content Type": item.content_type || "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Amount",
      "Transaction Date",
      "Object Id",
      "Description",
      "Content Type",
    ];
    downloadPDF(
      transactionsData,
      columns,
      formatTimeSlotsData,
      "Transaction Data"
    );
  };

  const handleDownloadXLS = () => {
    downloadXLS(transactionsData, formatTimeSlotsData, "Transaction Data");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Object Id",
      dataIndex: "object_id",
    },

    {
      title: "Amount",
      dataIndex: "amount",
    },

    {
      title: "Transaction Date",
      dataIndex: "transaction_date",
    },
    {
      title: "Content Type",
      dataIndex: "content_type",
      render: (typeId) =>
        contentTypes && contentTypes.length > 0
          ? contentTypes.find((item) => item.id === typeId)?.model
          : "Unknown",
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => renderDescription(text),
    },
  ];

  const handleModalCancel = () => {
    setAddModal(false);
  };

  const [reportModal, setReportModal] = useState(false);
  const [reportModalData, setReportModalData] = useState({});
  const [reportLoading, setReportLoading] = useState(false);

  const reportModalClick = async () => {
    setReportLoading(true);
    try {
      const response = await getData(
        "accounting/admin/transactions/total-transactions-report/"
      );
      if (response.status === 200) {
        setReportModalData(response.data);
        setReportLoading(false);
        setReportModal(true);
      }
    } catch (error) {
      console.log("error getting all expenses", error.response);
      setReportLoading(false);
    }
  };

  const handleReportModalCancel = () => {
    setReportModal(false);
    setReportLoading(false);
    setReportModalData({});
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Transactions</h1>
          <Breadcrumb className="fs-5" items={breadcrumbitems} />
        </div>
        <div>
          <Button
            className="me-3"
            size="large"
            onClick={reportModalClick}
            loading={reportLoading}
          >
            Expenses Report
          </Button>
          <Button
            onClick={() => setAddModal(true)}
            type="primary"
            icon={<PlusOutlined />}
            size="large"
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search..."
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
            />
            <DatePicker
              placeholder="Start Date"
              className="w-200"
              value={filters.start_date ? dayjs(filters.start_date) : null}
              onChange={(value) =>
                handleFilterChange(
                  "start_date",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
            <DatePicker
              placeholder="End Date"
              className="w-200"
              value={filters.end_date ? dayjs(filters.end_date) : null}
              onChange={(value) =>
                handleFilterChange(
                  "end_date",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={transactionsData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* add and edit modal */}
      {addModal && (
        <TransactionModal open={addModal} onClose={handleModalCancel} />
      )}

      {reportModal && (
        <Modal
          title={
            <h2 className="fs-3 mb-3 text-dark">Total Transaction Report</h2>
          }
          open={reportModal}
          onCancel={handleReportModalCancel}
          onOk={() =>
            generatePDFReport("Total Transaction Report", reportModalData)
          }
          okText="Download"
        >
          <table
            style={{
              marginTop: 30,
              width: "100%",
              borderCollapse: "collapse",
              textAlign: "left",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "#f0f0f0",
                  borderBottom: "2px solid #ccc",
                }}
              >
                <th style={{ padding: 10, border: "1px solid #ccc" }}>Field</th>
                <th style={{ padding: 10, border: "1px solid #ccc" }}>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(reportModalData).length > 0 ? (
                Object.entries(reportModalData).map(([key, value]) => (
                  <tr key={key}>
                    <td
                      style={{
                        padding: 10,
                        border: "1px solid #ccc",
                        textTransform: "capitalize",
                      }}
                    >
                      {key.replace(/_/g, " ")}
                    </td>
                    <td style={{ padding: 10, border: "1px solid #ccc" }}>
                      {value}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      padding: 10,
                      textAlign: "center",
                      border: "1px solid #ccc",
                    }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal>
      )}
    </div>
  );
};

export default Transactions;
