import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll } from "../../../libs/redux/features/student-info/enrollmentSlice";
import BreadBtn from "../../../components/BreadBtn";
import usePageTitle from "../../../components/PageTitle";
import { fetchAll as fetchAcademicYear } from "../../../libs/redux/features/academic/academicYearNewSlice";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchSections } from "../../../libs/redux/features/academic/sectionsNewSlice";
import { fetchAll as fetchStudents } from "../../../libs/redux/features/student-info/studentsSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  handleStatusChange,
} from "../../../components/utility";
import { Pagination, Select, Switch, Table } from "antd";
import DataConversion from "../../../components/DataConversion";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Option } = Select;

const Enrollments = () => {
  usePageTitle("Enrollments");
  const breadcrumbitems = [
    {
      title: "Student Data",
    },
    { title: "Enrollments" },
  ];
  const dispatch = useDispatch();
  const enrollments = useSelector((state) => state.enrollments.data);
  const isLoading = useSelector((state) => state.enrollments.isLoading);
  const totalItems = useSelector((state) => state.enrollments.totalItems);

  const academicYearData = useSelector((state) => state.academicYearsNew.data);
  const classesData = useSelector((state) => state.classesNew.data);
  const sectionsData = useSelector((state) => state.sectionsNew.data);
  const studentsData = useSelector((state) => state.students.data);

  useEffect(() => {
    if (academicYearData.length === 0) {
      dispatch(fetchAcademicYear());
    }
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
  }, [dispatch, academicYearData.length, classesData.length]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    academic_year: "all",
    school_class: "all",
    section: "all",
    status: "all",
    student: null,
  });

  useEffect(() => {
    if (filters.school_class !== "all") {
      dispatch(fetchSections({ class_level: filters.school_class }));
      dispatch(fetchStudents({ school_class: filters.school_class }));
    } else {
      dispatch(fetchSections());
      dispatch(fetchStudents());
    }
  }, [dispatch, filters.school_class]);

  useEffect(() => {
    const params = {
      limit: filters.limit,
      offset: (filters.offset - 1) * filters.limit,
    };
    if (filters.academic_year !== "all") {
      params.academic_year = filters.academic_year;
    }
    if (filters.school_class !== "all") {
      params.school_class = filters.school_class;
    }
    if (filters.section !== "all") {
      params.section = filters.section;
    }
    if (filters.status !== "all") {
      params.status = filters.status;
    }
    if (filters.student !== null) {
      params.student = filters.student;
    }

    dispatch(fetchAll(params));
  }, [filters, dispatch]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => {
    const className =
      classesData &&
      classesData.find((classEntry) => classEntry.id === item.school_class)
        ?.name;
    return {
      Serial: index + 1,
      Student: item.student_name || "N/A",
      Class: className || "N/A",
      Section: item.section_name || "N/A",
      "Roll Number": item.roll_number || "N/A",
      "Enrollment Date": item.enrollment_date || "N/A",
      Status: item.is_active && item.is_active ? "Yes" : "No" || "N/A",
    };
  };

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Student",
      "Class",
      "Section",
      "Roll Number",
      "Enrollment Date",
      "Status",
    ];
    downloadPDF(enrollments, columns, formatTimeSlotsData, "Enrollments Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(enrollments, formatTimeSlotsData, "Enrollments Data");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Student",
      dataIndex: "student_name",
    },
    {
      title: "Enrollment Data",
      dataIndex: "enrollment_date",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) =>
            handleStatusChange(
              checked,
              record,
              dispatch,
              `student/admin/enrollments/${record.id}/`,
              fetchAll,
              filters
            )
          }
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/student-data/enrollments/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.student_name}`,
                dispatch,
                `student/admin/enrollments/${record.id}/`,
                fetchAll,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <BreadBtn
        headerTitle="Enrollments"
        items={breadcrumbitems}
        addPath="/student-data/enrollments/new-enrollment"
        addTitle="New Enrollment"
      />
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("academic_year", value)}
            >
              <Option value="all">All Academic Years</Option>
              {academicYearData && academicYearData.length > 0
                ? academicYearData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.year}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("school_class", value)}
            >
              <Option value="all">All Classes</Option>
              {classesData && classesData.length > 0
                ? classesData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("section", value)}
            >
              <Option value="all">All Sections</Option>
              {sectionsData && sectionsData.length > 0
                ? sectionsData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              showSearch
              className="w-200"
              placeholder="Select Student"
              optionFilterProp="children"
              onChange={(value) => handleFilterChange("student", value || null)}
              allowClear
              onClear={() => handleFilterChange("student", null)}
            >
              {studentsData && studentsData.length > 0
                ? studentsData.map((student) => (
                    <Option value={student.id} key={student.id}>
                      {student.user.first_name} {student.user.last_name}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={enrollments}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Enrollments;
