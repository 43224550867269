import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchAll } from "../../../libs/redux/features/student-info/studentsSlice";
import usePageTitle from "../../../components/PageTitle";
import { Button, Checkbox, Col, Input, message, Row, Select, Spin } from "antd";
import PageCard from "../../../components/PageCard";
import BreadBtn from "../../../components/BreadBtn";
import { fetchAll as fetchHomeworks } from "../../../libs/redux/features/academic/homeworksNewSlice";
import {
  fetchSingle as fetchSingleHomeworkSubmission,
  postData as postHomeworkSubmission,
  updateData as putHomeworkSubmission,
} from "../../../libs/redux/features/academic/homeworkSubmissionsSlice";

const { Option } = Select;

const HomeworkSubmissionForm = () => {
  const breadcrumbitems = [
    { title: "Academic" },
    {
      title: (
        <Link to="/academic/homework-submissions">Homework Submissions</Link>
      ),
    },
    { title: "Add Homework Submission" },
  ];

  const { id } = useParams();
  const dispatch = useDispatch();

  // const { singleData, isLoading, error } = useSelector(
  //   (state) => state.homeworkSubmissions
  // );
  const singleData = useSelector(
    (state) => state.homeworkSubmissions.singleData
  );
  const isLoading = useSelector((state) => state.homeworkSubmissions.isLoading);
  const error = useSelector((state) => state.homeworkSubmissions.error);

  const homeworksData = useSelector((state) => state.homeworksNew.data);
  const studentsData = useSelector((state) => state.students.data);

  useEffect(() => {
    if (homeworksData.length === 0) {
      dispatch(fetchHomeworks());
    }
    if (studentsData.length === 0) {
      dispatch(fetchAll());
    }
  }, [dispatch, homeworksData.length, studentsData.length]);

  const [assignmentSubmission, setAssignmentSubmission] = useState({
    is_graded: true,
    grade: "",
    feedback: "",
    student: null,
    homework: null,
  });

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleHomeworkSubmission(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setAssignmentSubmission({
        is_graded: singleData.is_graded,
        grade: singleData.grade,
        feedback: singleData.feedback,
        student: singleData.student,
        homework: singleData.homework,
      });
    }
  }, [id, singleData]);

  usePageTitle(id ? "Homework Submission Detail" : "Homework Submissions");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("payload", assignmentSubmission);
    if (id) {
      dispatch(putHomeworkSubmission({ id, payload: assignmentSubmission }));
    } else {
      dispatch(
        postHomeworkSubmission({
          payload: assignmentSubmission,
          onSuccess: () => {
            message.success("New homework submission created successfully!");
            setAssignmentSubmission((prev) => ({
              ...prev,
              feedback: "",
              grade: "",
            }));
          },
        })
      );
    }
  };
  return (
    <div>
      <BreadBtn headerTitle="Add Homework Submission" items={breadcrumbitems} />
      <PageCard
        title="Homework submission data"
        content={
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Student</p>
                      {error && error.student ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.student})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      showSearch
                      className="w-100"
                      size="large"
                      placeholder="Select Student"
                      optionFilterProp="children"
                      value={assignmentSubmission.student}
                      onChange={(value) =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          student: value,
                        }))
                      }
                      allowClear
                      onClear={() =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          student: null,
                        }))
                      }
                      status={error && error.student ? "error" : ""}
                    >
                      {studentsData && studentsData.length > 0
                        ? studentsData.map((student) => (
                            <Option value={student.id} key={student.id}>
                              {student.user.first_name} {student.user.last_name}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Homework</p>
                      {error && error.homework ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.homework})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      showSearch
                      className="w-100"
                      size="large"
                      placeholder="Select Homework"
                      optionFilterProp="children"
                      value={assignmentSubmission.homework}
                      onChange={(value) =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          homework: value,
                        }))
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      allowClear
                      onClear={() =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          homework: null,
                        }))
                      }
                      status={error && error.homework ? "error" : ""}
                    >
                      {homeworksData && homeworksData.length > 0
                        ? homeworksData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.title}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Feedback</p>
                      {error && error.feedback ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.feedback})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Feedback"
                      required
                      value={assignmentSubmission.feedback}
                      onChange={(e) =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          feedback: e.target.value,
                        }))
                      }
                      status={error && error.feedback ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Grade</p>
                      {error && error.grade ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.grade})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Grade"
                      required
                      value={assignmentSubmission.grade}
                      onChange={(e) =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          grade: e.target.value,
                        }))
                      }
                      status={error && error.grade ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="my-3">
                    <Checkbox
                      checked={assignmentSubmission.is_graded}
                      onChange={(e) =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          is_graded: e.target.checked,
                        }))
                      }
                    >
                      Is Graded ?
                    </Checkbox>
                  </div>
                </Col>
                <Col span={24}>
                  <Button
                    className="w-100 mt-3"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {id ? "Update" : "Submit"}
                  </Button>
                </Col>
              </Row>
            </form>
          </Spin>
        }
      />
    </div>
  );
};

export default HomeworkSubmissionForm;
