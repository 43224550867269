import { Button, DatePicker, Input, message, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../../libs/axios/axiosInstance";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { postData } from "../../../libs/redux/features/accounting/transactionSlice";

const { Option } = Select;

const TransactionModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.transactions.isLoading);
  const error = useSelector((state) => state.transactions.error);

  const [contentTypes, setContentTypes] = useState([]);

  const fetchContentTypes = async () => {
    try {
      const response = await getData("accounting/admin/content-types/");
      if (response.status === 200) {
        setContentTypes(response.data);
      }
    } catch (error) {
      console.log("error getting content types", error.response);
    }
  };

  useEffect(() => {
    fetchContentTypes();
  }, []);

  const [modalData, setModalData] = useState({
    content_type: null,
    object_id: "",
    description: "",
    amount: "",
    transaction_date: null,
  });

  const handleModalCancel = () => {
    onClose();
    setModalData({
      content_type: null,
      object_id: "",
      description: "",
      amount: "",
      transaction_date: null,
    });
  };
  const handleModalOk = () => {
    const payload = {
      ...modalData,
      transaction_date:
        dayjs(modalData.transaction_date).format("YYYY-MM-DD") || null,
      academic_year: 23,
    };

    dispatch(
      postData({
        payload,
        onSuccess: () => {
          message.success("New transaction added successfully!");
          handleModalCancel();
        },
      })
    );
  };
  return (
    <Modal
      title={<h2 className="fs-2 mb-3 text-dark">Add New Transaction</h2>}
      open={open}
      footer={[
        <Button key="cancel" onClick={handleModalCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleModalOk}
          disabled={isLoading}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
      onCancel={handleModalCancel}
    >
      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">* Amount</p>
          {error && error.amount ? (
            <p className="text-sm text-danger ms-3">({error.amount})</p>
          ) : (
            ""
          )}
        </div>
        <Input
          className="w-100"
          size="large"
          placeholder="Amount"
          required
          value={modalData.amount}
          onChange={(e) =>
            setModalData((prev) => ({
              ...prev,
              amount: e.target.value,
            }))
          }
          status={error && error.amount ? "error" : ""}
        />
      </div>
      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">* Transaction Date</p>
          {error && error.transaction_date ? (
            <p className="text-sm text-danger ms-3">
              ({error.transaction_date})
            </p>
          ) : (
            ""
          )}
        </div>
        <DatePicker
          className="w-100"
          size="large"
          placeholder="Transaction Date"
          required
          value={modalData.transaction_date}
          onChange={(value) =>
            setModalData((prev) => ({
              ...prev,
              transaction_date: value,
            }))
          }
          status={error && error.transaction_date ? "error" : ""}
        />
      </div>
      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">* Content Type</p>
          {error && error.content_type ? (
            <p className="text-sm text-danger ms-3">({error.content_type})</p>
          ) : (
            ""
          )}
        </div>
        <Select
          className="w-100"
          size="large"
          placeholder="Select Content Type"
          value={modalData.content_type || null}
          onChange={(value) =>
            setModalData((prev) => ({
              ...prev,
              content_type: value,
            }))
          }
          status={error && error.content_type ? "error" : ""}
        >
          {contentTypes && contentTypes.length > 0
            ? contentTypes.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.model}
                </Option>
              ))
            : ""}
        </Select>
      </div>
      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">* Object Id</p>
          {error && error.object_id ? (
            <p className="text-sm text-danger ms-3">({error.object_id})</p>
          ) : (
            ""
          )}
        </div>
        <Input
          className="w-100"
          size="large"
          placeholder="Object Id"
          required
          value={modalData.object_id}
          onChange={(e) =>
            setModalData((prev) => ({
              ...prev,
              object_id: e.target.value,
            }))
          }
          status={error && error.object_id ? "error" : ""}
        />
      </div>

      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">* Description</p>
          {error && error.description ? (
            <p className="text-sm text-danger ms-3">({error.description})</p>
          ) : (
            ""
          )}
        </div>
        <TextArea
          className="w-100"
          autoSize={{ minRows: 2, maxRows: 4 }}
          placeholder="Description"
          required
          value={modalData.description}
          onChange={(e) =>
            setModalData((prev) => ({
              ...prev,
              description: e.target.value,
            }))
          }
          status={error && error.description ? "error" : ""}
        />
      </div>
    </Modal>
  );
};

export default TransactionModal;
