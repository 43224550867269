import {
  Breadcrumb,
  Button,
  Switch,
  Table,
  Input,
  Select,
  Pagination,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  handleStatusChange,
  signOutFunc,
} from "../../../components/utility";
import usePageTitle from "../../../components/PageTitle";
import { fetchAll as fetchDesignations } from "../../../libs/redux/features/teacher-info/designationsNewSlice";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";

const { Search } = Input;
const { Option } = Select;

const Designation = () => {
  usePageTitle("Designations");

  const dispatch = useDispatch();

  const data = useSelector((state) => state.designationsNew.data);
  const isLoading = useSelector((state) => state.designationsNew.isLoading);
  const totalItems = useSelector((state) => state.designationsNew.totalItems);
  const error = useSelector((state) => state.designationsNew.error);

  const navigate = useNavigate();

  useEffect(() => {
    if (error && error.detail === "Invalid token.") {
      signOutFunc(navigate);
    }
  }, [error, navigate]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    is_active: "all",
    search: "",
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.search !== "") {
      params.search = filters.search;
    }
    if (filters.is_active !== "all") {
      params.is_active = filters.is_active;
    }
    dispatch(fetchDesignations(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Designation Name",
      dataIndex: "name",
    },
    {
      title: "Sequence",
      dataIndex: "sequence",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) =>
            handleStatusChange(
              checked,
              record,
              dispatch,
              `teacher/admin/designation/${record.id}/`,
              fetchDesignations,
              filters
            )
          }
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link
            to={`/teachers-info/designations/${record.id}`}
            className="me-4"
          >
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.name}`,
                dispatch,
                `teacher/admin/designation/${record.id}/`,
                fetchDesignations,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Name: item.name || "N/A",
    Sequence: item.sequence || "N/A",
    Status: item.is_active ? "Active" : "Inactive",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Name", "Sequence", "Status"];
    downloadPDF(data, columns, formatTimeSlotsData, "Designations Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Designations Data");
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between p-4">
        <div>
          <h1 className="fs-2 text-dark">Designations</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    {" "}
                    <HomeOutlined />
                  </Link>
                ),
              },
              { title: "Teachers Info" },
              { title: "Designations" },
            ]}
          />
        </div>
        <div>
          <Link to="/teachers-info/designations/add-designation">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search by designation"
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
              onClear={() => handleFilterChange("search", "")}
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("is_active", value)}
            >
              <Option value="all">Filter by All Status</Option>
              <Option value={true}>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Designation;
