import {
  Select,
  Button,
  Breadcrumb,
  Table,
  Pagination,
  message,
  Input,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchPayrolls } from "../../../libs/redux/features/payroll/payrollsSlice";
import {
  fetchAll as fetchSalarySlips,
  postData,
  updateData,
} from "../../../libs/redux/features/payroll/salarySlipsSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../../components/utility";
import { EyeOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import DataConversion from "../../../components/DataConversion";
import usePageTitle from "../../../components/PageTitle";

const { Option } = Select;

const SalarySlip = () => {
  usePageTitle("Salary Slips");

  const breadcrumbitems = [
    {
      title: "Payroll Info",
    },
    { title: "Salary Slips" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const salarySlipsData = useSelector((state) => state.salarySlips.data);
  const totalItems = useSelector((state) => state.salarySlips.totalItems);
  const isLoading = useSelector((state) => state.salarySlips.isLoading);
  const error = useSelector((state) => state.salarySlips.error);

  const payrollsData = useSelector((state) => state.payrolls.data);

  const [modalData, setModalData] = useState({
    payroll: null,
    slip_content: "",
  });

  useEffect(() => {
    if (payrollsData.length === 0) {
      dispatch(fetchPayrolls());
    }
  }, [dispatch, payrollsData.length]);

  useEffect(() => {
    if (payrollsData && payrollsData.length > 0) {
      setModalData((prev) => ({
        ...prev,
        payroll: payrollsData[0].id,
      }));
    }
  }, [payrollsData]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };

    dispatch(fetchSalarySlips(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Payroll: item.payroll || "N/A",
    "Slip Content": item.slip_content || "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Payroll", "Slip Content"];
    downloadPDF(
      salarySlipsData,
      columns,
      formatTimeSlotsData,
      "Salary Slip Data"
    );
  };

  const handleDownloadXLS = () => {
    downloadXLS(salarySlipsData, formatTimeSlotsData, "Salary Slip Data");
  };

  const [id, setId] = useState(null);

  const fetchSingleItem = (data) => {
    setModalData({
      payroll: data.payroll,
      slip_content: data.slip_content,
    });
    setId(data.id);
    setAddModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) =>
        filters.offset * filters.limit + index + 1,
    },
    {
      title: "Payroll",
      dataIndex: "payroll",
      render: (_, data) => {
        const name =
          payrollsData &&
          payrollsData.find((item) => item.id === data.payroll)?.name;
        return name;
      },
    },
    {
      title: "Slip Content",
      dataIndex: "slip_content",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Button className="me-4 p-2" onClick={() => fetchSingleItem(record)}>
            <EyeOutlined />
          </Button>
          <div
            onClick={() =>
              handleDelete(
                `${record.slip_content}`,
                dispatch,
                `payroll/admin/salary-slips/${record.id}/`,
                fetchSalarySlips
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handleModalCancel = () => {
    setAddModal(false);
    if (id) {
      setId(null);
    }
    setModalData((prev) => ({
      ...prev,
      slip_content: "",
    }));
  };

  const handleModalOk = () => {
    const payload = modalData;
    if (!id) {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New salary slip added successfully!");
            handleModalCancel();
            setAddModal(false);
          },
        })
      );
    } else {
      dispatch(updateData({ id, payload }));
    }
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Salary Slips</h1>
          <Breadcrumb className="fs-5" items={breadcrumbitems} />
        </div>
        <div>
          <Button
            onClick={() => setAddModal(true)}
            type="primary"
            icon={<PlusOutlined />}
            size="large"
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3">
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={salarySlipsData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset * filters.limit + 1;
            const endItem = Math.min(
              (filters.offset + 1) * filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={filters.offset}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    handleFilterChange("offset", page);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* add and edit modal */}
      {addModal && (
        <Modal
          title={<h2 className="fs-2 mb-3">Final Grade Form</h2>}
          open={addModal}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={isLoading}
              loading={isLoading}
            >
              {id ? "Update" : "Submit"}
            </Button>,
          ]}
          onCancel={handleModalCancel}
        >
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Payroll</p>
              {error && error.payroll ? (
                <p className="text-sm text-danger ms-3">({error.payroll})</p>
              ) : (
                ""
              )}
            </div>
            <Select
              showSearch
              className="w-100"
              size="large"
              placeholder="Select payroll"
              optionFilterProp="children"
              value={modalData.payroll || null}
              onChange={(value) =>
                setModalData((prev) => ({
                  ...prev,
                  payroll: value,
                }))
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              allowClear
              onClear={() =>
                setModalData((prev) => ({
                  ...prev,
                  payroll: null,
                }))
              }
              status={error && error.payroll ? "error" : ""}
            >
              {payrollsData && payrollsData.length > 0
                ? payrollsData.map((student) => (
                    <Option value={student.id} key={student.id}>
                      {student.user}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>

          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Slip Content</p>
              {error && error.slip_content ? (
                <p className="text-sm text-danger ms-3">
                  ({error.slip_content})
                </p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Slip Content"
              required
              value={modalData.slip_content}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  slip_content: e.target.value,
                }))
              }
              status={error && error.slip_content ? "error" : ""}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SalarySlip;
