import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchSinglePage,
  postPage,
  putPage,
} from "../../../../libs/redux/features/website/pagesSlice";
import usePageTitle from "../../../../components/PageTitle";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Image,
  Input,
  message,
  Row,
  Select,
  Spin,
  Tag,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { getDocIcon, pageTypeData } from "../../../../components/utility";
import UploadButton from "../../../../components/UploadButton";
import ImagePopup from "../../../../components/ImagePopup";
import DocumentPopup from "../../../../components/DocumentPopup";

const { Option } = Select;

const AdminPageForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleData, isLoading, error } = useSelector((state) => state.pages);

  useEffect(() => {
    if (!isNaN(id)) {
      dispatch(fetchSinglePage(id));
    }
  }, [dispatch, id]);

  usePageTitle(id && singleData.title ? singleData.title : "Add Page");
  const [page, setPage] = useState({
    title: "",
    desc: "",
    video_url: "",
    page_type: 0,
    is_active: true,
    thumbnail: {},
    attachment: [],
  });

  useEffect(() => {
    if (!isNaN(id) && Object.keys(singleData).length > 0) {
      setPage({
        title: singleData.title,
        desc: singleData.desc,
        video_url: singleData.video_url,
        page_type: singleData.page_type,
        is_active: singleData.is_active,
        thumbnail: {
          image: singleData.thumbnail_url,
          alt_text: "",
        },
        attachment: singleData.attachment,
      });
    }
  }, [id, singleData, dispatch]);

  const [thumbModal, setThumbModal] = useState(false);
  const [docModal, setDocModal] = useState(false);

  const handleImageUploadSuccess = (newImg) => {
    setPage((prev) => ({
      ...prev,
      thumbnail: newImg[0],
    }));
    setThumbModal(false);
  };

  const handleDocs = (data) => {
    setPage((prev) => ({
      ...prev,
      attachment: [...prev.attachment, ...data],
    }));
    setDocModal(false);
  };

  const handleClose = (removedItem) => {
    setPage((prev) => ({
      ...prev,
      attachment: prev.attachment.filter((item) => item.id !== removedItem.id),
    }));
  };
  console.log("singleData", singleData);

  console.log("page", page);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (page.attachment.length > 0 && Object.keys(page.thumbnail).length > 0) {
      const payload = {
        ...page,
        thumbnail: page.thumbnail.id,
        attachment: page.attachment[0].id,
      };
      console.log("page payload", payload);
      if (!isNaN(id)) {
        dispatch(putPage({ id, payload }));
      } else {
        dispatch(
          postPage({
            payload,
            onSuccess: () => {
              setPage({
                title: "",
                desc: "",
                video_url: "",
                page_type: 0,
                is_active: true,
                thumbnail: {},
                attachment: [],
              });
            },
          })
        );
      }
    } else {
      message.error("Attachments & thumbnails are required");
    }
  };

  return (
    <div>
      <div className="breadcrumb">
        <div>
          <h1 className="fs-2 text-dark">
            {!isNaN(id) && singleData.title ? singleData.title : "Add Page"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: <Link to="/">Home</Link>,
              },
              {
                title: "Website",
              },
              {
                title: "Settings",
              },
              {
                title: (
                  <Link to="/website/settings/admin-pages">Admin Pages</Link>
                ),
              },
              {
                title:
                  !isNaN(id) && singleData.title
                    ? singleData.title
                    : "Add Page",
              },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Page Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Title</p>
                        {error && error.title ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.title})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Title"
                        required
                        value={page.title}
                        onChange={(e) =>
                          setPage((prev) => ({
                            ...prev,
                            title: e.target.value,
                          }))
                        }
                        status={error && error.title ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Description</p>
                        {error && error.desc ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.desc})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <TextArea
                        className="w-100"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        placeholder="Description"
                        required
                        value={page.desc}
                        onChange={(e) =>
                          setPage((prev) => ({
                            ...prev,
                            desc: e.target.value,
                          }))
                        }
                        status={error && error.desc ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Video URL</p>
                        {error && error.video_url ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.video_url})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Video URL"
                        required
                        value={page.video_url}
                        onChange={(e) =>
                          setPage((prev) => ({
                            ...prev,
                            video_url: e.target.value,
                          }))
                        }
                        status={error && error.video_url ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Page Type</p>
                        {error && error.page_type ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.page_type})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        className="w-100"
                        size="large"
                        value={page.page_type}
                        onChange={(value) =>
                          setPage((prev) => ({
                            ...prev,
                            page_type: value,
                          }))
                        }
                        status={error && error.class_level ? "error" : ""}
                      >
                        {pageTypeData.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.title}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Thumbnail</p>
                      <div className="image-preview-grid">
                        {page &&
                        page.thumbnail &&
                        Object.keys(page.thumbnail).length > 0 ? (
                          <Image
                            height={100}
                            width={100}
                            className="object-cover rounded-5"
                            alt={page.thumbnail.alt_text}
                            src={page.thumbnail.image}
                          />
                        ) : (
                          ""
                        )}
                        <UploadButton
                          onClick={() => setThumbModal(true)}
                          height={100}
                          width={100}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col span={24} className="mb-3">
                    <p className="mb-2">* Attachment</p>
                    <div className="flex aic">
                      <Button
                        onClick={() => setDocModal(true)}
                        className={`flex justify-center aic rounded-10 ${
                          error && error.attachment
                            ? "border-dashed-double"
                            : "border-dashed"
                        }`}
                        style={{ height: 100, width: 100 }}
                        danger={error && error.attachment ? true : false}
                      >
                        + Upload
                      </Button>
                      <div className="ms-3">
                        {page.attachment && page.attachment.length > 0 ? (
                          page.attachment.map((item) => {
                            return (
                              <Tag
                                key={item.id}
                                closable
                                onClose={() => handleClose(item)}
                                icon={getDocIcon(item.doc_type)}
                                className="m-1 p-2 flex aic"
                                color="success"
                              >
                                {item.title}
                              </Tag>
                            );
                          })
                        ) : error && error.attachments ? (
                          <p className="text-danger">{error.attachments}</p>
                        ) : (
                          "No file selected"
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="my-5">
                      <Checkbox
                        checked={page.is_active}
                        onChange={(e) =>
                          setPage((prev) => ({
                            ...prev,
                            is_active: e.target.checked,
                          }))
                        }
                      >
                        Is Active ?
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className="w-100 my-5"
                      loading={isLoading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
      {thumbModal && (
        <ImagePopup
          open={thumbModal}
          onCancel={() => setThumbModal(false)}
          onUploadSuccess={handleImageUploadSuccess}
          cropAspectRatio={16 / 9}
        />
      )}
      {docModal && (
        <DocumentPopup
          open={docModal}
          onCancel={() => setDocModal(false)}
          onUploadSuccess={handleDocs}
          docDescription={page.desc}
          single={true}
        />
      )}
    </div>
  );
};

export default AdminPageForm;
