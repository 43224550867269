import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Row, Segmented, Spin } from "antd";
import usePageTitle from "../../components/PageTitle";
import { GiTeacher } from "react-icons/gi";
import { PiStudentFill, PiTrafficSignBold } from "react-icons/pi";
import { FaUsers } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { MdWebStories } from "react-icons/md";
import { Link } from "react-router-dom";
import {
  CardSm,
  EarningsChart,
  earningsData,
  ExpensesChart,
  expensesData,
  quick_links_data,
  QuickLinkCard,
} from "./charts";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchUsers } from "../../libs/redux/features/users/usersSlice";
import { fetchAll as fetchStudentAttendances } from "../../libs/redux/features/student-info/studentAttendanceSlice";
import { fetchAll as fetchTeacherAttendances } from "../../libs/redux/features/teacher-info/teacherAttendanceSlice";
import { getData } from "../../libs/axios/axiosInstance";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import FeesPaymentModal from "../accounting/fees/FeesPaymentModal";
import ExpenseModal from "../accounting/expense/ExpenseModal";
import TransactionModal from "../accounting/transaction/TransactionModal";

const Homepage = () => {
  usePageTitle("Home");

  const [selectedChart, setSelectedChart] = useState("Earnings");

  const renderChart = () => {
    if (selectedChart === "Earnings") {
      return <EarningsChart data={earningsData} />;
    } else {
      return <ExpensesChart data={expensesData} />;
    }
  };

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.data);
  const userLoading = useSelector((state) => state.users.isLoading);

  const teachers = (users && users.filter((item) => item.role === 1)) || [];
  const students = (users && users.filter((item) => item.role === 2)) || [];
  const staffs = (users && users.filter((item) => item.role === 3)) || [];

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const [pagesInfo, setPagesInfo] = useState({
    page_view_count: 0,
    page_view_loading: false,
    visitor_count: 0,
    visitor_count_loading: false,
  });

  const fetchPageViewCount = async () => {
    try {
      setPagesInfo((prev) => ({
        ...prev,
        page_view_loading: true,
      }));

      const response = await getData("analytics/page_view_count/");
      if (response.status === 200) {
        setPagesInfo((prev) => ({
          ...prev,
          page_view_count: response.data.page_view_count,
        }));
      }
    } catch (error) {
      console.log("error getting page view count", error.response);
    } finally {
      setPagesInfo((prev) => ({
        ...prev,
        page_view_loading: false,
      }));
    }
  };

  const fetchVisitorCount = async () => {
    try {
      setPagesInfo((prev) => ({
        ...prev,
        visitor_count_loading: true,
      }));

      const response = await getData("analytics/visitor_count/");
      if (response.status === 200) {
        setPagesInfo((prev) => ({
          ...prev,
          visitor_count: response.data.visitor_count,
        }));
      }
    } catch (error) {
      console.log("error getting visitors count", error.response);
    } finally {
      setPagesInfo((prev) => ({
        ...prev,
        visitor_count_loading: false,
      }));
    }
  };

  useEffect(() => {
    fetchPageViewCount();
    fetchVisitorCount();
  }, []);

  const studentAttendances = useSelector(
    (state) => state.studentAttendance.totalItems
  );
  const studentAttendancesLoading = useSelector(
    (state) => state.studentAttendance.isLoading
  );

  useEffect(() => {
    dispatch(fetchStudentAttendances({ date: dayjs().format("YYYY-MM-DD") }));
  }, [dispatch]);

  const teacherAttendances = useSelector(
    (state) => state.teacherAttendance.totalItems
  );
  const teacherAttendacesLoading = useSelector(
    (state) => state.teacherAttendance.isLoading
  );

  useEffect(() => {
    dispatch(fetchTeacherAttendances({ date: dayjs().format("YYYY-MM-DD ") }));
  }, [dispatch]);

  const [paymentsData, setPaymentsData] = useState({
    total_expense: 0,
    total_expense_loading: false,
    total_transaction: 0,
    total_transaction_loading: false,
    total_fees: 0,
    total_fees_loading: false,
  });

  const fetchTotalExpense = async () => {
    setPaymentsData((prev) => ({
      ...prev,
      total_expense_loading: true,
    }));

    try {
      const response = await getData(
        "accounting/admin/expenses/total-expenses/"
      );
      if (response.status === 200) {
        setPaymentsData((prev) => ({
          ...prev,
          total_expense: response.data.total_expenses,
        }));
      }
    } catch (error) {
      console.log("error fetching total expenses", error.response);
    } finally {
      setPaymentsData((prev) => ({
        ...prev,
        total_expense_loading: false,
      }));
    }
  };

  const fetchTotalPayments = async () => {
    setPaymentsData((prev) => ({
      ...prev,
      total_fees_loading: true,
    }));

    try {
      const response = await getData(
        "accounting/admin/fees/payments/total-fees-report/"
      );
      if (response.status === 200) {
        setPaymentsData((prev) => ({
          ...prev,
          total_fees: response.data.total_collected_fees,
        }));
      }
    } catch (error) {
      console.log("error fetching total fees", error.response);
    } finally {
      setPaymentsData((prev) => ({
        ...prev,
        total_fees_loading: false,
      }));
    }
  };

  const fetchTotalTransactions = async () => {
    setPaymentsData((prev) => ({
      ...prev,
      total_transaction_loading: true,
    }));

    try {
      const response = await getData(
        "accounting/admin/transactions/total-transactions-report/"
      );
      if (response.status === 200) {
        setPaymentsData((prev) => ({
          ...prev,
          total_transaction: response.data.total_transactions,
        }));
      }
    } catch (error) {
      console.log("error fetching total transactions", error.response);
    } finally {
      setPaymentsData((prev) => ({
        ...prev,
        total_transaction_loading: false,
      }));
    }
  };

  useEffect(() => {
    fetchTotalExpense();
    fetchTotalPayments();
    fetchTotalTransactions();
  }, []);

  const [feesPaymentModal, setFeesPaymentModal] = useState(false);
  const handleModalClose = () => {
    setFeesPaymentModal(false);
  };

  const [expenseModal, setExpenseModal] = useState(false);
  const handleExpenseModalClose = () => {
    setExpenseModal(false);
  };

  const [transactionModal, setTransactionModal] = useState(false);
  const handleTransactionModalClose = () => {
    setTransactionModal(false);
  };

  return (
    <div>
      <div className="mt-5">
        <Row gutter={16} className="mb-4">
          <Col span={24} md={16}>
            <Row className="bg-white rounded-10 p-2 mb-2 shadow-sm-2">
              <Col xs={24} sm={24} md={12} lg={8}>
                <CardSm
                  Icon={GiTeacher}
                  title={"Total Teachers"}
                  value={teachers.length}
                  loading={userLoading}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <CardSm
                  Icon={PiStudentFill}
                  title={"Total Students"}
                  value={students.length}
                  loading={userLoading}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <CardSm
                  Icon={FaUsers}
                  title={"Total Staffs"}
                  value={staffs.length}
                  loading={userLoading}
                />
              </Col>
            </Row>
            <Row className="bg-white rounded-10 p-2 shadow-sm-2">
              <Col xs={24} sm={24} md={12} lg={8}>
                <CardSm
                  Icon={CgWebsite}
                  title={"Website Visitor Count"}
                  value={pagesInfo.visitor_count}
                  loading={pagesInfo.visitor_count_loading}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <CardSm
                  Icon={MdWebStories}
                  title={"Page View Count"}
                  value={pagesInfo.page_view_count}
                  loading={pagesInfo.page_view_loading}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <CardSm
                  Icon={PiTrafficSignBold}
                  title={"Average Traffic"}
                  value={20}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24} md={8}>
            <div className="bg-dark-2 text-white p-3 h-100 rounded-10 flex flex-column aic">
              <p className="fw-7">Today's Student Attendance</p>
              <Spin
                indicator={<LoadingOutlined />}
                spinning={studentAttendancesLoading}
                size="small"
              >
                <p className="fs-1 fw-6 text-center">{studentAttendances}</p>
              </Spin>
              <Button className="border-0 rounded-10 text-dark">
                <Link to="/student-data/student-attendances">
                  Goto Student Attendance
                </Link>
              </Button>
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="mb-4">
          <Col span={24} md={16}>
            <div className="bg-white p-3 rounded-10">
              <div className="w-100 flex justify-center">
                <Segmented
                  options={["Earnings", "Expenses"]}
                  onChange={(value) => setSelectedChart(value)}
                  defaultValue="Earnings"
                />
              </div>
              <div className="mt-2" style={{ minHeight: 360 }}>
                {renderChart()}
              </div>
            </div>
          </Col>
          <Col span={24} md={8}>
            <Badge.Ribbon text="Quick Links" placement="start">
              <div className="bg-white p-4 pb-0 h-100 rounded-10 shadow-sm-2">
                <div className="p-2 mt-4">
                  <Row gutter={16}>
                    {quick_links_data.map((item, index) => (
                      <Col span={8} className="mb-3" key={index}>
                        <QuickLinkCard
                          path={item.path}
                          Icon={item.icon}
                          title={item.title}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </Badge.Ribbon>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={16}>
            <Row gutter={16}>
              <Col span={24} md={8}>
                <div className="bg-secondary text-dark p-3 rounded-10 flex flex-column aic shadow-sm-2">
                  <p className="fw-7">Total Fees Payment</p>
                  <Spin
                    indicator={<LoadingOutlined />}
                    spinning={paymentsData.total_fees_loading}
                    size="small"
                  >
                    <p className="fs-1 fw-6 text-center">
                      {paymentsData.total_fees}
                    </p>
                  </Spin>
                  <Button
                    onClick={() => setFeesPaymentModal(true)}
                    className="rounded-10"
                  >
                    + New Payment
                  </Button>
                </div>
              </Col>
              <Col span={24} md={8}>
                <div className="bg-secondary text-dark p-3 rounded-10 flex flex-column aic shadow-sm-2">
                  <p className="fw-7">Total Expenses</p>
                  <Spin
                    indicator={<LoadingOutlined />}
                    spinning={paymentsData.total_expense_loading}
                    size="small"
                  >
                    <p className="fs-1 fw-6 text-center">
                      {paymentsData.total_expense}
                    </p>
                  </Spin>
                  <Button
                    onClick={() => setExpenseModal(true)}
                    className="rounded-10"
                  >
                    + New Expense
                  </Button>
                </div>
              </Col>
              <Col span={24} md={8}>
                <div className="bg-secondary text-dark p-3 rounded-10 flex flex-column aic shadow-sm-2">
                  <p className="fw-7">Total No. of Transactions</p>
                  <Spin
                    indicator={<LoadingOutlined />}
                    spinning={paymentsData.total_transaction_loading}
                    size="small"
                  >
                    <p className="fs-1 fw-6 text-center">
                      {paymentsData.total_transaction}
                    </p>{" "}
                  </Spin>
                  <Button
                    onClick={() => setTransactionModal(true)}
                    className="rounded-10"
                  >
                    + New Transaction
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24} md={8}>
            <div className="bg-dark-2 text-white p-3 h-100 rounded-10 flex flex-column aic">
              <p className="fw-7">Today's Teacher Attendance</p>
              <Spin
                indicator={<LoadingOutlined />}
                spinning={teacherAttendacesLoading}
                size="small"
              >
                <p className="fs-1 fw-6 text-center">{teacherAttendances}</p>
              </Spin>
              <Button className="border-0 rounded-10 text-dark">
                <Link to="/teachers-info/teacher-attendance">
                  Goto Teacher Attendance
                </Link>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <div></div>
      {/*  */}
      {feesPaymentModal && (
        <FeesPaymentModal open={feesPaymentModal} onClose={handleModalClose} />
      )}
      {/*  */}
      {expenseModal && (
        <ExpenseModal open={expenseModal} onClose={handleExpenseModalClose} />
      )}
      {/*  */}
      {transactionModal && (
        <TransactionModal
          open={transactionModal}
          onClose={handleTransactionModalClose}
        />
      )}
    </div>
  );
};

export default Homepage;
