import { Row, Col, Card, Typography, Divider, Table } from "antd";
import logo from "../../../assets/logo.png";
import "./salaryslip.css";

const { Title, Text } = Typography;

const SalarySlip = () => {
  // Sample Data - Replace with dynamic data as needed
  const company = {
    name: "Your Company Name",
    address: "1234 Main St, City, Country",
    logo: logo,
  };

  const employee = {
    name: "John Doe",
    id: "EMP12345",
    department: "Engineering",
    designation: "Senior Developer",
    bankAccount: "1234-5678-9012",
    paymentDate: "2024-10-31",
    payPeriod: "October 2024",
  };

  const salaryDetails = {
    basic: 5000,
    hra: 1000,
    conveyance: 200,
    medical: 300,
    other: 100,
    gross: 6600,
    deductions: 600,
    net: 6000,
  };

  const deductionsDetails = [
    { key: "1", type: "Tax", amount: 400 },
    { key: "2", type: "Provident Fund", amount: 200 },
  ];

  // Columns for the deductions table
  const columns = [
    {
      title: "Deduction Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Amount ($)",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => amount.toFixed(2),
    },
  ];

  return (
    <div className="salary-slip-container">
      <Card>
        <div className="salary-slip">
          {/* Header Section */}
          <Row justify="space-between" align="middle">
            <Col>
              <img
                src={company.logo}
                alt="Company Logo"
                className="company-logo"
              />
            </Col>
            <Col>
              <Title level={3} className="company-name">
                {company.name}
              </Title>
              <Text>{company.address}</Text>
            </Col>
          </Row>

          <Divider />

          {/* Employee Information */}
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Title level={4}>Employee Information</Title>
              <div className="info-item">
                <Text strong>Name:</Text> <Text>{employee.name}</Text>
              </div>
              <div className="info-item">
                <Text strong>Employee ID:</Text> <Text>{employee.id}</Text>
              </div>
              <div className="info-item">
                <Text strong>Department:</Text>{" "}
                <Text>{employee.department}</Text>
              </div>
              <div className="info-item">
                <Text strong>Designation:</Text>{" "}
                <Text>{employee.designation}</Text>
              </div>
            </Col>
            <Col span={12}>
              <Title level={4}>Payment Information</Title>
              <div className="info-item">
                <Text strong>Bank Account:</Text>{" "}
                <Text>{employee.bankAccount}</Text>
              </div>
              <div className="info-item">
                <Text strong>Payment Date:</Text>{" "}
                <Text>{employee.paymentDate}</Text>
              </div>
              <div className="info-item">
                <Text strong>Pay Period:</Text>{" "}
                <Text>{employee.payPeriod}</Text>
              </div>
            </Col>
          </Row>

          <Divider />

          {/* Salary Details */}
          <Row>
            <Col span={24}>
              <Title level={4}>Salary Details</Title>
              <Table
                dataSource={[
                  {
                    key: "1",
                    type: "Basic Salary",
                    amount: salaryDetails.basic,
                  },
                  {
                    key: "2",
                    type: "House Rent Allowance (HRA)",
                    amount: salaryDetails.hra,
                  },
                  {
                    key: "3",
                    type: "Conveyance Allowance",
                    amount: salaryDetails.conveyance,
                  },
                  {
                    key: "4",
                    type: "Medical Allowance",
                    amount: salaryDetails.medical,
                  },
                  {
                    key: "5",
                    type: "Other Allowances",
                    amount: salaryDetails.other,
                  },
                  {
                    key: "6",
                    type: "Gross Salary",
                    amount: salaryDetails.gross,
                  },
                ]}
                columns={[
                  { title: "Component", dataIndex: "type", key: "type" },
                  {
                    title: "Amount ($)",
                    dataIndex: "amount",
                    key: "amount",
                    render: (amount) => `$${amount.toFixed(2)}`,
                  },
                ]}
                pagination={false}
                bordered
                style={{ marginBottom: "20px" }}
              />
            </Col>
          </Row>

          {/* Deductions Details */}
          <Row>
            <Col span={24}>
              <Title level={4}>Deductions</Title>
              <Table
                dataSource={deductionsDetails}
                columns={columns}
                pagination={false}
                bordered
              />
            </Col>
          </Row>

          {/* Net Salary */}
          <Row justify="end" style={{ marginTop: 20 }}>
            <Col>
              <Title level={4}>
                Net Salary: ${salaryDetails.net.toFixed(2)}
              </Title>
            </Col>
          </Row>

          {/* Footer Section */}
          <Row justify="space-between" style={{ marginTop: 40 }}>
            <Col>
              <Text>Authorized Signature</Text>
            </Col>
            <Col>
              <Text>Employee Signature</Text>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default SalarySlip;
