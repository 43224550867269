// UserInfo.js
import React from "react";
import { Row, Col, Input, DatePicker, Select, Image } from "antd";
import dayjs from "dayjs";
import UploadButton from "./UploadButton";
import ImagePopup from "./ImagePopup";

const { Option } = Select;

const UserInfo = ({
  user,
  onUserChange,
  onGenderChange,
  userImageModal,
  setUserImageModal,
  onUserImageUploadSuccess,
  error,
  id,
}) => {
  return (
    <div className="bg-white rounded-10 mb-5">
      <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
        <h2 className="fs-4">User Information</h2>
      </div>
      <div className="p-4" style={{ paddingTop: 10, paddingBottom: 5 }}>
        <Row>
          <Col span={24}>
            <div className="mb-3">
              <div className="mb-2 flex aic">
                <p className="ps-2 fw-6">* First Name</p>
                {error && error.first_name ? (
                  <p className="text-sm text-danger ms-3">
                    ({error.first_name})
                  </p>
                ) : (
                  ""
                )}
              </div>

              <Input
                className="w-100"
                size="large"
                placeholder="First Name"
                value={user.first_name}
                onChange={(e) =>
                  onUserChange((prev) => ({
                    ...prev,
                    first_name: e.target.value,
                  }))
                }
                required
                status={error && error.first_name ? "error" : ""}
              />
            </div>
          </Col>
          <Col span={24}>
            <div className="mb-3">
              <div className="mb-2 flex aic">
                <p className="ps-2 fw-6">* Last Name</p>
                {error && error.last_name ? (
                  <p className="text-sm text-danger ms-3">
                    ({error.last_name})
                  </p>
                ) : (
                  ""
                )}
              </div>
              <Input
                className="w-100"
                size="large"
                placeholder="Last Name"
                value={user.last_name}
                onChange={(e) =>
                  onUserChange((prev) => ({
                    ...prev,
                    last_name: e.target.value,
                  }))
                }
                required
                status={error && error.last_name ? "error" : ""}
              />
            </div>
          </Col>
          <Col span={24}>
            <div className="mb-3">
              <p className="mb-2 ps-2 fw-6">* DOB</p>
              <DatePicker
                size="large"
                className="w-100 rounded-10"
                onChange={(e) =>
                  onUserChange((prev) => ({
                    ...prev,
                    dob: e ? dayjs(e).format("YYYY-MM-DD") : null,
                  }))
                }
                format="YYYY-MM-DD"
                value={user.dob ? dayjs(user.dob, "YYYY-MM-DD") : null}
                placeholder="Select DOB"
                required
              />
            </div>
          </Col>
          <Col span={24}>
            <div className="mb-3">
              <div className="mb-2 flex aic">
                <p className="ps-2 fw-6">* Email</p>
                {error && error.email ? (
                  <p className="text-sm text-danger ms-3">({error.email})</p>
                ) : (
                  ""
                )}
              </div>
              <Input
                className="w-100"
                type="email"
                size="large"
                placeholder="Email"
                value={user.email}
                onChange={(e) =>
                  onUserChange((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
                required
                status={error && error.email ? "error" : ""}
              />
            </div>
          </Col>
          <Col span={24}>
            <div className="mb-3">
              <div className="mb-2 flex aic">
                <p className="ps-2 fw-6">* Mobile</p>
                {error && error.mobile ? (
                  <p className="text-sm text-danger ms-3">({error.mobile})</p>
                ) : (
                  ""
                )}
              </div>
              <Input
                className="w-100"
                size="large"
                placeholder="Mobile"
                value={user.mobile}
                onChange={(e) =>
                  onUserChange((prev) => ({
                    ...prev,
                    mobile: e.target.value,
                  }))
                }
                required
                status={error && error.mobile ? "error" : ""}
              />
            </div>
          </Col>
          <Col span={24}>
            <div className="mb-3">
              <p className="mb-2 ps-2 fw-6">* Bio</p>
              <Input
                className="w-100"
                size="large"
                placeholder="Bio"
                value={user.bio}
                onChange={(e) =>
                  onUserChange((prev) => ({
                    ...prev,
                    bio: e.target.value,
                  }))
                }
                required
              />
            </div>
          </Col>
          <Col span={24}>
            <div className="mb-3">
              <div className="mb-2 flex aic">
                <p className="ps-2 fw-6">* Password</p>
                {error && error.password ? (
                  <p className="text-sm text-danger ms-3">({error.password})</p>
                ) : (
                  ""
                )}
              </div>
              <Input
                className="w-100"
                size="large"
                placeholder="Password"
                value={user.password}
                onChange={(e) =>
                  onUserChange((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                required={!id}
                disabled={id}
                status={error && error.password ? "error" : ""}
              />
            </div>
          </Col>
          <Col span={24}>
            <div className="mb-3">
              <div className="mb-2 flex aic">
                <p className="ps-2 fw-6">* Confirm Password</p>
                {error && error.confirm_password ? (
                  <p className="text-sm text-danger ms-3">
                    ({error.confirm_password})
                  </p>
                ) : (
                  ""
                )}
              </div>
              <Input
                className="w-100"
                size="large"
                placeholder="Confirm Password"
                value={user.confirm_password}
                onChange={(e) =>
                  onUserChange((prev) => ({
                    ...prev,
                    confirm_password: e.target.value,
                  }))
                }
                required={!id}
                disabled={id}
                status={error && error.confirm_password ? "error" : ""}
              />
            </div>
          </Col>
          <Col span={24}>
            <div className="mb-3">
              <p className="mb-2 ps-2 fw-6">* Gender</p>
              <Select
                size="large"
                className="w-100"
                defaultValue={0}
                onChange={onGenderChange}
              >
                <Option value={0}>Male</Option>
                <Option value={1}>Female</Option>
                <Option value={2}>Other</Option>
              </Select>
            </div>
          </Col>
          <Col span={24}>
            <div className="mb-3">
              <div className="mb-2 flex aic">
                <p className="ps-2 fw-6">* User Image</p>
                {error && error.image ? (
                  <p className="text-sm text-danger ms-3">({error.image})</p>
                ) : (
                  ""
                )}
              </div>
              <div className="image-preview-grid">
                {user.image && Object.keys(user.image).length > 0 ? (
                  <Image
                    height={100}
                    width={100}
                    className="object-cover rounded-5"
                    alt={user.image.alt_text}
                    src={user.image.image}
                  />
                ) : (
                  ""
                )}
                <UploadButton
                  danger={error && error.image ? true : false}
                  onClick={() => setUserImageModal(true)}
                  height={100}
                  width={100}
                />
              </div>
            </div>
          </Col>
        </Row>
        {userImageModal && (
          <ImagePopup
            open={userImageModal}
            onCancel={() => setUserImageModal(false)}
            onUploadSuccess={onUserImageUploadSuccess}
            cropAspectRatio={16 / 9}
          />
        )}
      </div>
    </div>
  );
};

export default UserInfo;
