import { Button, Col, DatePicker, Image, Modal, Row, Select } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";

const { Option } = Select;

const StudentAttendanceModal = ({
  visible,
  onCancel,
  onSubmit,
  student,
  initialAttendance,
  loading,
  classes,
  sections,
  teachers,
}) => {
  const [modalAttendance, setModalAttendance] = useState(initialAttendance);
  console.log("modal attendance", modalAttendance);

  const handleInputChange = (key, value) => {
    setModalAttendance((prev) => ({
      ...prev,
      [key]: key === "date" ? value : value,
    }));
  };

  const handleOk = () => {
    const formattedPayload = {
      ...modalAttendance,
      date: dayjs(modalAttendance.date).format("YYYY-MM-DD"),
    };
    onSubmit(formattedPayload);
  };
  return (
    <Modal
      title={`${student?.user?.first_name || ""} ${
        student?.user?.last_name || ""
      }`}
      open={visible}
      onCancel={onCancel}
      footer={
        <Button
          type="primary"
          size="medium"
          onClick={handleOk}
          loading={loading}
        >
          Submit
        </Button>
      }
    >
      <Row>
        <Col span={24} className="mb-3">
          <Image
            style={{ maxHeight: 120 }}
            src={student?.user?.image?.image}
            alt={student?.user?.image?.alt_text}
          />
        </Col>
        <Col span={24} className="mb-3">
          <DatePicker
            className="w-100"
            value={dayjs(modalAttendance.date)}
            onChange={(value) => handleInputChange("date", value)}
            showTime
          />
        </Col>
        <Col span={24} className="mb-3">
          <Select
            value={modalAttendance.status}
            className="w-100"
            onChange={(value) => handleInputChange("status", value)}
          >
            <Option value="Present">Present</Option>
            <Option value="Absent">Absent</Option>
            <Option value="Late">Late</Option>
            <Option value="Excused">Excused</Option>
          </Select>
        </Col>
        <Col span={24} className="mb-3">
          <Select
            className="w-100"
            value={modalAttendance.class_attended}
            onChange={(value) => handleInputChange("class_attended", value)}
          >
            {classes && classes.length > 0
              ? classes.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))
              : ""}
          </Select>
        </Col>
        <Col span={24} className="mb-3">
          <Select
            className="w-100"
            value={modalAttendance.section_attended}
            onChange={(value) => handleInputChange("section_attended", value)}
          >
            {sections && sections.length > 0
              ? sections.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))
              : ""}
          </Select>
        </Col>
        <Col span={24} className="mb-3">
          <Select
            className="w-100"
            value={modalAttendance.teacher}
            onChange={(value) => handleInputChange("teacher", value)}
          >
            {teachers && teachers.length > 0
              ? teachers.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.user.first_name} {item.user.last_name}
                  </Option>
                ))
              : ""}
          </Select>
        </Col>
      </Row>
    </Modal>
  );
};

export default StudentAttendanceModal;
