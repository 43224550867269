import {
  Breadcrumb,
  Button,
  DatePicker,
  Input,
  Modal,
  Pagination,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll } from "../../../libs/redux/features/accounting/ledgerSlice";
import {
  downloadPDF,
  downloadXLS,
  generatePDFReport,
} from "../../../components/utility";
import dayjs from "dayjs";
import DataConversion from "../../../components/DataConversion";
import { getData } from "../../../libs/axios/axiosInstance";

const { Search } = Input;

const Ledger = () => {
  usePageTitle("Ledgers");

  const breadcrumbitems = [
    {
      title: "Accounting",
    },
    { title: "Ledgers" },
  ];

  const dispatch = useDispatch();
  const ledgersData = useSelector((state) => state.ledgers.data);
  const totalItems = useSelector((state) => state.ledgers.totalItems);
  const isLoading = useSelector((state) => state.ledgers.isLoading);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    search: "",
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.search !== "") {
      params.search = filters.search;
    }
    if (filters.start_date !== "") {
      params.start_date = filters.start_date;
    }
    if (filters.end_date !== "") {
      params.end_date = filters.end_date;
    }

    dispatch(fetchAll(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Transaction: item.transaction || "N/A",
    "Transaction Date": item.transaction_date || "N/A",
    Amount: item.amount || "N/A",
    Description: item.description || "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Transaction",
      "Transaction Date",
      "Amount",
      "Description",
    ];
    downloadPDF(ledgersData, columns, formatTimeSlotsData, "Ledgers Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(ledgersData, formatTimeSlotsData, "Ledgers Data");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
    },
    {
      title: "Debit",
      dataIndex: "debit",
    },
    {
      title: "Credit",
      dataIndex: "credit",
    },
    {
      title: "Balance",
      dataIndex: "balance",
    },

    {
      title: "Transaction",
      dataIndex: "transaction",
    },
  ];

  const [modals, setModals] = useState({
    balanceSheet: false,
    incomeStatement: false,
  });
  const [modalLoading, setModalLoading] = useState({
    balanceSheet: false,
    incomeStatement: false,
  });

  const [modalData, setModalData] = useState({
    balanceSheet: {},
    incomeStatement: {},
  });

  const balanceSheetModalClick = async () => {
    setModalLoading((prev) => ({
      ...prev,
      balanceSheet: true,
    }));

    try {
      const response = await getData("accounting/admin/ledgers/balance-sheet/");
      if (response.status === 200) {
        setModalData((prev) => ({
          ...prev,
          balanceSheet: response.data,
        }));
        setModalLoading((prev) => ({
          ...prev,
          balanceSheet: false,
        }));
        setModals((prev) => ({
          ...prev,
          balanceSheet: true,
        }));
      }
    } catch (error) {
      console.log("error getting balancesheet", error.response);
      setModalLoading((prev) => ({
        ...prev,
        balanceSheet: false,
      }));
      setModals((prev) => ({
        ...prev,
        balanceSheet: false,
      }));
      setModalData((prev) => ({
        ...prev,
        balanceSheet: {},
      }));
    }
  };

  const handleBalancesheetModalCancel = () => {
    setModals((prev) => ({
      ...prev,
      balanceSheet: false,
    }));
    setModalData((prev) => ({
      ...prev,
      balanceSheet: {},
    }));
  };

  const incomeStatementModalClick = async () => {
    setModalLoading((prev) => ({
      ...prev,
      incomeStatement: true,
    }));

    try {
      const response = await getData(
        "accounting/admin/ledgers/income-statement/"
      );
      if (response.status === 200) {
        setModalData((prev) => ({
          ...prev,
          incomeStatement: response.data,
        }));
        setModalLoading((prev) => ({
          ...prev,
          incomeStatement: false,
        }));
        setModals((prev) => ({
          ...prev,
          incomeStatement: true,
        }));
      }
    } catch (error) {
      console.log("error getting income statement", error.response);
      setModalLoading((prev) => ({
        ...prev,
        incomeStatement: false,
      }));
      setModals((prev) => ({
        ...prev,
        incomeStatement: false,
      }));
      setModalData((prev) => ({
        ...prev,
        incomeStatement: {},
      }));
    }
  };

  const handleIncomeStatementModalCancel = () => {
    setModals((prev) => ({
      ...prev,
      incomeStatement: false,
    }));
    setModalData((prev) => ({
      ...prev,
      incomeStatement: {},
    }));
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Ledgers</h1>
          <Breadcrumb className="fs-5" items={breadcrumbitems} />
        </div>
        <div>
          <Button
            icon={<EyeOutlined />}
            className="me-3"
            size="large"
            loading={modalLoading.incomeStatement}
            onClick={incomeStatementModalClick}
          >
            Income Statement
          </Button>
          <Button
            onClick={balanceSheetModalClick}
            loading={modalLoading.balanceSheet}
            icon={<EyeOutlined />}
            size="large"
          >
            Balance Sheet
          </Button>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search..."
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
            />
            {/* <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) =>
                handleFilterChange("transaction_type", value)
              }
            >
              <Option value="all">All Transaction Types</Option>
              <Option value="Credit">Credit</Option>
              <Option value="Debit">Debit</Option>
            </Select> */}

            <DatePicker
              placeholder="Start Date"
              className="w-200"
              value={filters.start_date ? dayjs(filters.start_date) : null}
              onChange={(value) =>
                handleFilterChange(
                  "start_date",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
            <DatePicker
              placeholder="End Date"
              className="w-200"
              value={filters.end_date ? dayjs(filters.end_date) : null}
              onChange={(value) =>
                handleFilterChange(
                  "end_date",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={ledgersData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* balance sheet modal */}
      {modals.balanceSheet && (
        <Modal
          title={<h2 className="fs-3 mb-3">Balancesheet</h2>}
          open={modals.balanceSheet}
          onCancel={handleBalancesheetModalCancel}
          onOk={() =>
            generatePDFReport("Balance Sheet", modalData.balanceSheet)
          }
          okText="Download"
        >
          <table
            style={{
              marginTop: 30,
              width: "100%",
              borderCollapse: "collapse",
              textAlign: "left",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "#f0f0f0",
                  borderBottom: "2px solid #ccc",
                }}
              >
                <th style={{ padding: 10, border: "1px solid #ccc" }}>Field</th>
                <th style={{ padding: 10, border: "1px solid #ccc" }}>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(modalData.balanceSheet).length > 0 ? (
                Object.entries(modalData.balanceSheet).map(([key, value]) => (
                  <tr key={key}>
                    <td
                      style={{
                        padding: 10,
                        border: "1px solid #ccc",
                        textTransform: "capitalize",
                      }}
                    >
                      {key.replace(/_/g, " ")}
                    </td>
                    <td style={{ padding: 10, border: "1px solid #ccc" }}>
                      {value}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      padding: 10,
                      textAlign: "center",
                      border: "1px solid #ccc",
                    }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal>
      )}

      {/* income statement modal */}
      {modals.incomeStatement && (
        <Modal
          title={<h2 className="fs-3 mb-3">Income Statement</h2>}
          open={modals.incomeStatement}
          onCancel={handleIncomeStatementModalCancel}
          onOk={() =>
            generatePDFReport("Income Statement", modalData.incomeStatement)
          }
          okText="Download"
        >
          <table
            style={{
              marginTop: 30,
              width: "100%",
              borderCollapse: "collapse",
              textAlign: "left",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "#f0f0f0",
                  borderBottom: "2px solid #ccc",
                }}
              >
                <th style={{ padding: 10, border: "1px solid #ccc" }}>Field</th>
                <th style={{ padding: 10, border: "1px solid #ccc" }}>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(modalData.incomeStatement).length > 0 ? (
                Object.entries(modalData.incomeStatement).map(
                  ([key, value]) => (
                    <tr key={key}>
                      <td
                        style={{
                          padding: 10,
                          border: "1px solid #ccc",
                          textTransform: "capitalize",
                        }}
                      >
                        {key.replace(/_/g, " ")}
                      </td>
                      <td style={{ padding: 10, border: "1px solid #ccc" }}>
                        {value}
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      padding: 10,
                      textAlign: "center",
                      border: "1px solid #ccc",
                    }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal>
      )}
    </div>
  );
};

export default Ledger;
