import { Breadcrumb, Button, Col, Input, message, Row, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProfile,
  updateProfile,
} from "../../libs/redux/features/profile/profileSlice";
import { createFormDataImage } from "../../components/utility";
import { uploadImage } from "../../libs/axios/axiosInstance";

const Profile = () => {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  const [profileData, setProfileData] = useState({});
  const [file, setFile] = useState(null);

  const handleUploadChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 1) {
      newFileList = [newFileList[newFileList.length - 1]];
    }
    setFile(newFileList[0] ? newFileList[0].originFileObj : null);
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setProfileData(data);
    }
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(profileData);
    if (!file && !data.image_url) {
      message.warning("No User Image Selected");
      return;
    }

    let payload = {
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      bio: profileData.bio,
      image: profileData.image,
    };

    if (!file && profileData.image) {
      dispatch(updateProfile(payload)).unwrap();
    } else {
      const formData = createFormDataImage(
        file,
        profileData.first_name,
        payload.first_name,
        profileData.first_name,
        true
      );
      console.log("profile, formdata", formData, payload);

      try {
        const response = await uploadImage(formData);
        console.log("image post response", response);

        if (response.data && response.data.id) {
          payload.image = response.data.id;
        }

        await dispatch(updateProfile(payload)).unwrap();
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div>
      <div className="breadcrumb">
        <div className="text-prime">
          <h1 className="fs-2">Profile</h1>
          <Breadcrumb
            className="fs-5"
            items={[{ title: <Link to="/">Home</Link> }, { title: "Profile" }]}
          />
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <form className="py-4" onSubmit={handleSubmit}>
          <Row className="responsive-row">
            <Col xs={24} sm={24} md={8} lg={6}>
              <div className="card mb-sm-3 shadow-sm">
                <div className="flex justify-center aic flex-column">
                  <div className="profile-image-section py-4">
                    <Upload
                      className="flex aic justify-center"
                      listType="picture-circle"
                      maxCount={1}
                      showUploadList={false}
                      onChange={handleUploadChange}
                    >
                      <img
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : profileData.image_url
                        }
                        alt={data.first_name || "user"}
                        className="profile-img"
                      />
                    </Upload>
                  </div>
                  <div className="text-prime">
                    <p className="text-center fw-6 fs-3">
                      {data.first_name || "User"} {data.last_name}
                    </p>
                    <p className="text-center fw-5 fs-4">Admin</p>
                    <hr className="mb-3" />
                    <p className="text-center">{data.email || "email"}</p>

                    <p className="text-center">{data.mobile || "Phone"}</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={16} lg={18} className="relative">
              <Row>
                <Col xs={24} sm={12}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">First Name</p>
                    <Input
                      size="large"
                      placeholder="First Name"
                      value={profileData.first_name || ""}
                      onChange={(e) =>
                        setProfileData((prev) => ({
                          ...prev,
                          first_name: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">Last Name</p>
                    <Input
                      size="large"
                      placeholder="Last Name"
                      value={profileData.last_name || ""}
                      onChange={(e) =>
                        setProfileData((prev) => ({
                          ...prev,
                          last_name: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                </Col>
                <Col xs={24}>
                  <div className="mb-4">
                    <p className="mb-2 ps-2 fw-6">Bio</p>
                    <TextArea
                      placeholder="Bio"
                      value={profileData.bio || ""}
                      onChange={(e) =>
                        setProfileData((prev) => ({
                          ...prev,
                          bio: e.target.value,
                        }))
                      }
                      required
                      autoSize={{ minRows: 5, maxRows: 5 }}
                    />
                  </div>
                </Col>
              </Row>

              <div className="px-3 absolute w-100 bottom-0">
                <Button
                  type="primary"
                  size="large"
                  className="w-100"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
};

export default Profile;
