import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import usePageTitle from "../../../components/PageTitle";
import {
  fetchSingle as getSingleCalendar,
  postData as postCalendar,
  updateData as putCalendar,
} from "../../../libs/redux/features/academic/calendarsNewSlice";
import { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { HomeOutlined } from "@ant-design/icons";

// const DISPLAY_FORMAT = "DD/MM/YYYY HH:mm";
const SERVER_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

const CalendarForm = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const singleData = useSelector((state) => state.calendarsNew.singleData);
  const isLoading = useSelector((state) => state.calendarsNew.isLoading);
  const error = useSelector((state) => state.calendarsNew.error);

  usePageTitle(id ? singleData.title : "Add Calendar");

  useEffect(() => {
    if (id) {
      dispatch(getSingleCalendar(id));
    }
  }, [id, dispatch]);

  const [calendar, setCalendar] = useState({
    title: "",
    description: "",
    start: "",
    end: "",
    all_day: false,
    is_active: true,
  });

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setCalendar({
        title: singleData.title,
        description: singleData.description,
        start: dayjs(singleData.start),
        end: dayjs(singleData.end),
        all_day: singleData.all_day,
        is_active: singleData.is_active,
      });
    }
  }, [id, singleData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      ...calendar,
      start: dayjs(calendar.start).format(SERVER_FORMAT),
      end: dayjs(calendar.end).format(SERVER_FORMAT),
    };
    if (id) {
      dispatch(putCalendar({ id, payload }));
    } else {
      dispatch(
        postCalendar({
          payload,
          onSuccess: () => {
            message.success("New calendar entry created successfully!");
            setCalendar({
              title: "",
              description: "",
              start: "",
              end: "",
              all_day: false,
              is_active: true,
            });
          },
        })
      );
    }
  };
  return (
    <div>
      <div className="breadcrumb">
        <div>
          <h1 className="fs-2 text-dark">
            {id ? calendar.title : "Add Calendar"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    <HomeOutlined />
                  </Link>
                ),
              },
              {
                title: "Academic",
              },
              {
                title: <Link to="/academic/calendars">Calendars</Link>,
              },
              {
                title: id ? calendar.title : "Add Calendar",
              },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Calendar Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Title</p>
                        {error && error.title ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.title})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Name"
                        required
                        value={calendar.title}
                        onChange={(e) =>
                          setCalendar((prev) => ({
                            ...prev,
                            title: e.target.value,
                          }))
                        }
                        status={error && error.title ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Description</p>
                      <TextArea
                        className="w-100"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        placeholder="Description"
                        required
                        value={calendar.description}
                        onChange={(e) =>
                          setCalendar((prev) => ({
                            ...prev,
                            description: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Start Date</p>
                        {error && error.start ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.start})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <DatePicker
                        size="large"
                        className="w-100 rounded-10"
                        showTime
                        name="start"
                        onChange={(date) =>
                          setCalendar((prev) => ({
                            ...prev,
                            start: date,
                          }))
                        }
                        value={calendar.start ? dayjs(calendar.start) : null}
                        placeholder="Start Date"
                        status={error && error.start ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* End Date</p>
                        {error && error.end ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.end})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <DatePicker
                        size="large"
                        className="w-100 rounded-10"
                        showTime
                        name="end"
                        onChange={(date) =>
                          setCalendar((prev) => ({
                            ...prev,
                            end: date,
                          }))
                        }
                        value={calendar.end ? dayjs(calendar.end) : null}
                        placeholder="End Date"
                        status={error && error.end ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="my-3">
                      <Checkbox
                        checked={calendar.all_day}
                        onChange={(e) =>
                          setCalendar((prev) => ({
                            ...prev,
                            all_day: e.target.checked,
                          }))
                        }
                      >
                        All Day ?
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-5">
                      <Checkbox
                        checked={calendar.is_active}
                        onChange={(e) =>
                          setCalendar((prev) => ({
                            ...prev,
                            is_active: e.target.checked,
                          }))
                        }
                      >
                        Is Active ?
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className="w-100 mt-3"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      {id ? "Update" : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
    </div>
  );
};

export default CalendarForm;
