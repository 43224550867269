import { Breadcrumb, Button, Col, Input, message, Row, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getData, postData } from "../../../libs/axios/axiosInstance";
import usePageTitle from "../../../components/PageTitle";

const TeacherPage = () => {
  usePageTitle("Teachers Page");
  const [teachers, setTeachers] = useState({
    seo: {
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    },
    page_title: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const fetchTeacherPage = async () => {
    setIsLoading(true);
    try {
      const fetchRes = await getData("website/teacherpage/");
      if (fetchRes.status === 200 || 201) {
        const data = fetchRes.data;
        setTeachers({
          seo: data.seo,
          page_title: data.page_title,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error fetching teacher page", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTeacherPage();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const postTeacherPage = await postData("website/teacherpage/", teachers);
      if (postTeacherPage.status === 200 || 201) {
        message.success("Teacher page added successfully!");
        fetchTeacherPage();
      }
    } catch (error) {
      console.log("error updating teacher page", error);
    }
  };

  return (
    <div>
      <div className="breadcrumb">
        <div>
          <h1 className="fs-2 text-dark">Teachers Page</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Website" },
              { title: "Pages" },
              { title: "Teachers Page" },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <div className="bg-white rounded-10 mb-5">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">SEO Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row className="mb-5">
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Meta Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Title"
                        value={teachers.seo.meta_title}
                        onChange={(e) =>
                          setTeachers((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_title: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Meta Descriptions</p>
                      <TextArea
                        className="w-100"
                        placeholder="Meta Descriptions"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        value={teachers.seo.meta_description}
                        onChange={(e) =>
                          setTeachers((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_description: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="">
                      <p className="mb-2 ps-2 fw-6">
                        Meta Keywords (comma separated)
                      </p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Keywords"
                        value={teachers.seo.meta_keywords}
                        onChange={(e) =>
                          setTeachers((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_keywords: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Page Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Page Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Page Title"
                        value={teachers.page_title}
                        onChange={(e) =>
                          setTeachers((prev) => ({
                            ...prev,
                            page_title: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className="w-100 my-5"
                      loading={isLoading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </form>
        </Spin>
      </div>
    </div>
  );
};

export default TeacherPage;
