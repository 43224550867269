import { Button, Modal, Select, Input, DatePicker, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchExpenseTypes } from "../../../libs/redux/features/accounting/expenseTypesSlice";
import { paymentMethodData } from "../../../components/utility";
import {
  postData,
  updateData,
} from "../../../libs/redux/features/accounting/espenseSlice";
import dayjs from "dayjs";

const { Option } = Select;

const ExpenseModal = ({ open, onClose, id = null, data = {} }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.expenses.isLoading);
  const error = useSelector((state) => state.expenses.error);
  const expenseTypes = useSelector((state) => state.expenseType.data);

  const [modalData, setModalData] = useState({
    title: "",
    description: "",
    amount: "",
    date: "",
    expense_type: null,
    payment_method: 0,
  });

  useEffect(() => {
    if (expenseTypes.length === 0) {
      dispatch(fetchExpenseTypes());
    }
  }, [expenseTypes.length, dispatch]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setModalData({
        title: data.title,
        description: data.description,
        date: data.date,
        amount: data.amount,
        expense_type: data.expense_type,
        payment_method: data.payment_method,
      });
    }
  }, [data]);

  const handleModalCancel = () => {
    onClose();
    setModalData({
      title: "",
      description: "",
      amount: "",
      date: "",
      expense_type: null,
      payment_method: 0,
    });
  };

  const handleModalOk = () => {
    const payload = {
      ...modalData,
      date: dayjs(modalData.date).format("YYYY-MM-DD"),
    };
    if (!id) {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New Payment added successfully!");
            handleModalCancel();
          },
        })
      );
    } else {
      dispatch(updateData({ id, payload }));
    }
  };

  return (
    <Modal
      title={<h2 className="fs-2 mb-3 text-dark">Add New Expense</h2>}
      open={open}
      onCancel={handleModalCancel}
      footer={[
        <Button key="cancel" onClick={handleModalCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleModalOk}
          disabled={isLoading || id}
          loading={isLoading}
        >
          {id ? "Update" : "Submit"}
        </Button>,
      ]}
    >
      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">* Title</p>
          {error && error.title ? (
            <p className="text-sm text-danger ms-3">({error.title})</p>
          ) : (
            ""
          )}
        </div>
        <Input
          className="w-100"
          size="large"
          placeholder="Title"
          required
          value={modalData.title}
          onChange={(e) =>
            setModalData((prev) => ({
              ...prev,
              title: e.target.value,
            }))
          }
          status={error && error.title ? "error" : ""}
        />
      </div>
      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">* Amount</p>
          {error && error.amount ? (
            <p className="text-sm text-danger ms-3">({error.amount})</p>
          ) : (
            ""
          )}
        </div>
        <Input
          className="w-100"
          size="large"
          placeholder="Amount"
          required
          value={modalData.amount}
          onChange={(e) =>
            setModalData((prev) => ({
              ...prev,
              amount: e.target.value,
            }))
          }
          status={error && error.amount ? "error" : ""}
        />
      </div>
      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">* Payment Method</p>
          {error && error.payment_method ? (
            <p className="text-sm text-danger ms-3">({error.payment_method})</p>
          ) : (
            ""
          )}
        </div>
        <Select
          className="w-100"
          size="large"
          placeholder="Select Payment Method"
          value={modalData.payment_method}
          onChange={(value) =>
            setModalData((prev) => ({
              ...prev,
              payment_method: value,
            }))
          }
          status={error && error.payment_method ? "error" : ""}
        >
          {paymentMethodData.map((item) => (
            <Option value={item.value} key={item.value}>
              {item.title}
            </Option>
          ))}
        </Select>
      </div>
      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">* Date</p>
          {error && error.date ? (
            <p className="text-sm text-danger ms-3">({error.date})</p>
          ) : (
            ""
          )}
        </div>
        <DatePicker
          className="w-100"
          size="large"
          placeholder="Date"
          required
          value={modalData.date}
          onChange={(value) =>
            setModalData((prev) => ({
              ...prev,
              date: value,
            }))
          }
          status={error && error.date ? "error" : ""}
        />
      </div>

      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">* Expense Type</p>
          {error && error.expense_type ? (
            <p className="text-sm text-danger ms-3">({error.expense_type})</p>
          ) : (
            ""
          )}
        </div>
        <Select
          className="w-100"
          size="large"
          placeholder="Select Expense type"
          value={modalData.expense_type}
          onChange={(value) =>
            setModalData((prev) => ({
              ...prev,
              expense_type: value,
            }))
          }
          status={error && error.expense_type ? "error" : ""}
        >
          {expenseTypes && expenseTypes.length > 0
            ? expenseTypes.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </div>
      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">* Description</p>
          {error && error.description ? (
            <p className="text-sm text-danger ms-3">({error.description})</p>
          ) : (
            ""
          )}
        </div>
        <TextArea
          className="w-100"
          autoSize={{ minRows: 2, maxRows: 4 }}
          placeholder="Description"
          required
          value={modalData.description}
          onChange={(e) =>
            setModalData((prev) => ({
              ...prev,
              description: e.target.value,
            }))
          }
          status={error && error.description ? "error" : ""}
        />
      </div>
    </Modal>
  );
};

export default ExpenseModal;
