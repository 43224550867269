import {
  Breadcrumb,
  Button,
  Table,
  Select,
  Pagination,
  DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  handleDelete,
  downloadPDF,
  downloadXLS,
} from "../../../components/utility";
import usePageTitle from "../../../components/PageTitle";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";
import { fetchAll as fetchAssignments } from "../../../libs/redux/features/academic/assignmentsNewSlice";
import dayjs from "dayjs";
import { fetchAll as fetchSections } from "../../../libs/redux/features/academic/sectionsNewSlice";
import { fetchAll as fetchCourses } from "../../../libs/redux/features/academic/coursesNewSlice";

const { Option } = Select;

const Assignments = () => {
  usePageTitle("Assignments");

  const dispatch = useDispatch();

  const data = useSelector((state) => state.assignmentsNew.data);
  const isLoading = useSelector((state) => state.assignmentsNew.isLoading);
  const totalItems = useSelector((state) => state.assignmentsNew.totalItems);

  const classesData = useSelector((state) => state.classesNew.data);
  const coursesData = useSelector((state) => state.coursesNew.data);
  const sectionsData = useSelector((state) => state.sectionsNew.data);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
    if (coursesData.length === 0) {
      dispatch(fetchCourses());
    }
    if (sectionsData.length === 0) {
      dispatch(fetchSections());
    }
  }, [classesData.length, coursesData.length, sectionsData.length, dispatch]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    assigned_date: null,
    due_date: null,
    class_level: null,
    course: null,
    section: null,
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.assigned_date !== null) {
      params.assigned_date = filters.assigned_date;
    }
    if (filters.due_date !== null) {
      params.due_date = filters.due_date;
    }
    if (filters.class_level !== null) {
      params.class_level = filters.class_level;
    }
    if (filters.section !== null) {
      params.section = filters.section;
    }
    if (filters.course !== null) {
      params.course = filters.course;
    }
    dispatch(fetchAssignments(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Title: item.title || "N/A",
    Class: item.class_level_name || "N/A",
    Section: item.section_name || "N/A",
    Course: item.course_name || "N/A",
    Teacher: item.teacher_name || "N/A",
    "Assigned Date": item.assigned_date || "N/A",
    "Due Date": item.due_date || "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Title",
      "Class",
      "Section",
      "Course",
      "Teacher",
      "Assigned Date",
      "Due Date",
    ];
    downloadPDF(data, columns, formatTimeSlotsData, "Assignment Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Assignment Data");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Class",
      dataIndex: "class_level_name",
    },
    {
      title: "Section",
      dataIndex: "section_name",
    },
    {
      title: "Teacher",
      dataIndex: "teacher_name",
    },
    {
      title: "Assigned Date",
      dataIndex: "assigned_date",
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/academic/assignments/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.title}`,
                dispatch,
                `academic/admin/assignments/${record.id}/`,
                fetchAssignments,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Assignments</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    <HomeOutlined />
                  </Link>
                ),
              },
              { title: "Academic" },
              { title: "Assignments" },
            ]}
          />
        </div>
        <div>
          <Link to="/academic/assignments/add-assignment">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <DatePicker
              placeholder="Assigned Date"
              className="w-200"
              value={
                filters.assigned_date ? dayjs(filters.assigned_date) : null
              }
              onChange={(value) =>
                handleFilterChange(
                  "assigned_date",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
              required
            />
            <DatePicker
              placeholder="Due Date"
              className="w-200"
              value={filters.due_date ? dayjs(filters.due_date) : null}
              onChange={(value) =>
                handleFilterChange(
                  "due_date",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
              required
            />
            <Select
              showSearch
              className="w-200"
              placeholder="Select Class"
              optionFilterProp="children"
              value={filters.class_level || null}
              onChange={(value) => handleFilterChange("class_level", value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              allowClear
              onClear={() => handleFilterChange("class_level", null)}
            >
              {classesData &&
                classesData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
            <Select
              showSearch
              className="w-200"
              placeholder="Select Section"
              optionFilterProp="children"
              value={filters.section || null}
              onChange={(value) => handleFilterChange("section", value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              allowClear
              onClear={() => handleFilterChange("section", null)}
            >
              {sectionsData &&
                sectionsData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
            <Select
              showSearch
              className="w-200"
              placeholder="Select Course"
              optionFilterProp="children"
              value={filters.course || null}
              onChange={(value) => handleFilterChange("course", value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              allowClear
              onClear={() => handleFilterChange("course", null)}
            >
              {coursesData &&
                coursesData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Assignments;
