import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, postData } from "../../../axios/axiosInstance";
import { message } from "antd";

export const fetchProfile = createAsyncThunk(
  "profile/fetchProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getData("auth/profile/");
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.message || "Error fetching profile"
          : "Error fetching profile";
      // message.error(errorMessage);
      console.log("profile error", errorMessage);

      return rejectWithValue(error.response);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (profileData, { rejectWithValue }) => {
    try {
      const response = await postData("auth/profile/", profileData);
      if (response.status === 200 || 201) {
        message.success("Profile updated");
        localStorage.setItem("user_data", JSON.stringify(response.data));
      }
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.message || "Error updating profile"
          : "Error updating profile";
      message.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    isLoading: false,
    data: {},
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
        // console.log("Updated state with fetched profile:", state.data); // Debug log
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.error = action.payload;
      })
      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
        // console.log("Updated state with new profile:", state.data); // Debug log
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default profileSlice.reducer;
