import { Button, Input, message, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchStudents } from "../../../libs/redux/features/student-info/studentsSlice";
import { fetchAll as fetchStructures } from "../../../libs/redux/features/accounting/feesStructureSlice";
import { paymentMethodData } from "../../../components/utility";
import {
  updateData,
  postData,
} from "../../../libs/redux/features/accounting/feesPaymentSlice";

const { Option } = Select;

const FeesPaymentModal = ({ open, onClose, id = null, data = {} }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.feesPayments.isLoading);
  const error = useSelector((state) => state.feesPayments.error);

  const studentsData = useSelector((state) => state.students.data);
  const feesStructuresData = useSelector((state) => state.feesStructures.data);

  const [modalData, setModalData] = useState({
    amount_paid: "",
    student: null,
    fee_structure: null,
    payment_method: 1,
    notes: null,
  });

  useEffect(() => {
    if (studentsData.length === 0) {
      dispatch(fetchStudents());
    }
    if (feesStructuresData.length === 0) {
      dispatch(fetchStructures());
    }
  }, [dispatch, studentsData.length, feesStructuresData.length]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setModalData({
        amount_paid: data.amount_paid,
        student: data.student,
        fee_structure: data.fee_structure,
        payment_method: data.payment_method,
        notes: data.notes,
      });
    }
  }, [data]);

  const handleModalCancel = () => {
    onClose();
    setModalData({
      amount_paid: "",
      student: null,
      fee_structure: null,
      payment_method: 1,
      notes: null,
    });
  };

  const handleModalOk = () => {
    const payload = modalData;
    if (!id) {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New Payment added successfully!");
            handleModalCancel();
          },
        })
      );
    } else {
      dispatch(updateData({ id, payload }));
    }
  };

  return (
    <Modal
      title={<h2 className="fs-2 mb-3 text-dark">New Payment</h2>}
      open={open}
      footer={[
        <Button key="cancel" onClick={handleModalCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleModalOk}
          disabled={isLoading || id}
          loading={isLoading}
        >
          {id ? "Update" : "Submit"}
        </Button>,
      ]}
      onCancel={handleModalCancel}
    >
      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">* Amount Paid</p>
          {error && error.amount_paid ? (
            <p className="text-sm text-danger ms-3">({error.amount_paid})</p>
          ) : (
            ""
          )}
        </div>
        <Input
          className="w-100"
          size="large"
          placeholder="Amount paid"
          required
          value={modalData.amount_paid}
          onChange={(e) =>
            setModalData((prev) => ({
              ...prev,
              amount_paid: e.target.value,
            }))
          }
          status={error && error.amount_paid ? "error" : ""}
        />
      </div>
      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">* Payment Method</p>
          {error && error.payment_method ? (
            <p className="text-sm text-danger ms-3">({error.payment_method})</p>
          ) : (
            ""
          )}
        </div>
        <Select
          className="w-100"
          size="large"
          placeholder="Select Payment Method"
          value={modalData.payment_method}
          onChange={(value) =>
            setModalData((prev) => ({
              ...prev,
              payment_method: value,
            }))
          }
          status={error && error.payment_method ? "error" : ""}
        >
          {paymentMethodData.map((item) => (
            <Option value={item.value} key={item.value}>
              {item.title}
            </Option>
          ))}
        </Select>
      </div>
      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">* Student</p>
          {error && error.student ? (
            <p className="text-sm text-danger ms-3">({error.student})</p>
          ) : (
            ""
          )}
        </div>
        <Select
          className="w-100"
          size="large"
          placeholder="Select Student"
          showSearch
          optionFilterProp="children"
          value={modalData.student || null}
          onChange={(value) =>
            setModalData((prev) => ({
              ...prev,
              student: value,
            }))
          }
          status={error && error.student ? "error" : ""}
        >
          {studentsData && studentsData.length > 0
            ? studentsData.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.user.first_name} {item.user.last_name}
                </Option>
              ))
            : ""}
        </Select>
      </div>
      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">* Fee Structure</p>
          {error && error.fee_structure ? (
            <p className="text-sm text-danger ms-3">({error.fee_structure})</p>
          ) : (
            ""
          )}
        </div>
        <Select
          className="w-100"
          size="large"
          placeholder="Select Fees Structure"
          value={modalData.fee_structure || null}
          onChange={(value) =>
            setModalData((prev) => ({
              ...prev,
              fee_structure: value,
            }))
          }
          status={error && error.fee_structure ? "error" : ""}
        >
          {feesStructuresData && feesStructuresData.length > 0
            ? feesStructuresData.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </div>
      <div className="mb-3">
        <div className="mb-2 flex aic">
          <p className="ps-2 fw-6">Notes</p>
          {error && error.notes ? (
            <p className="text-sm text-danger ms-3">({error.notes})</p>
          ) : (
            ""
          )}
        </div>
        <Input
          className="w-100"
          size="large"
          placeholder="Notes"
          required
          value={modalData.notes}
          onChange={(e) =>
            setModalData((prev) => ({
              ...prev,
              notes: e.target.value,
            }))
          }
          status={error && error.notes ? "error" : ""}
        />
      </div>
    </Modal>
  );
};

export default FeesPaymentModal;
