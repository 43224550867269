import { useDispatch, useSelector } from "react-redux";
import usePageTitle from "../../../components/PageTitle";
import { useEffect, useState } from "react";
import { fetchAll as fetchAssignments } from "../../../libs/redux/features/academic/assignmentsNewSlice";
import { fetchAll } from "../../../libs/redux/features/student-info/studentsSlice";
import { fetchAll as fetchAssignmentSubmissions } from "../../../libs/redux/features/academic/assignmentSubmissionsNewSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../../components/utility";
import { Link } from "react-router-dom";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import BreadBtn from "../../../components/BreadBtn";
import DataConversion from "../../../components/DataConversion";
import { Pagination, Select, Table } from "antd";

const { Option } = Select;

const AssignmentSubmission = () => {
  usePageTitle("Assignment Submissions");
  const breadcrumbitems = [
    {
      title: "Academic",
    },
    { title: "Assignment Submissions" },
  ];

  const dispatch = useDispatch();

  const data = useSelector((state) => state.assignmentSubmissionsNew.data);
  const totalItems = useSelector(
    (state) => state.assignmentSubmissionsNew.totalItems
  );
  const isLoading = useSelector(
    (state) => state.assignmentSubmissionsNew.isLoading
  );

  const assingmentsData = useSelector((state) => state.assignmentsNew.data);
  const studentsData = useSelector((state) => state.students.data);

  useEffect(() => {
    if (assingmentsData.length === 0) {
      dispatch(fetchAssignments());
    }
    if (studentsData.length === 0) {
      dispatch(fetchAll());
    }
  }, [dispatch, assingmentsData.length, studentsData.length]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    is_graded: "all",
    assignment: null,
    student: null,
  });

  useEffect(() => {
    const params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.is_graded !== "all") {
      params.is_graded = filters.is_graded;
    }
    if (filters.assignment !== null) {
      params.assignment = filters.assignment;
    }
    if (filters.student !== null) {
      params.student = filters.student;
    }

    dispatch(fetchAssignmentSubmissions(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => {
    const student = studentsData.find((student) => student.id === item.student);
    const assignment = assingmentsData.find(
      (assignmentItem) => assignmentItem.id === item
    )?.title;
    return {
      Serial: index + 1,
      Student: `${student.user.first_name} ${student.user.last_name}` || "N/A",
      Assignment: assignment,
      "Is Graded": item.is_graded ? "Yes" : "No" || "N/A",
      Grade: item.grade || "N/A",
      Feedback: item.feedback || "N/A",
    };
  };

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Student",
      "Assignment",
      "Is Graded",
      "Grade",
      "Feedback",
    ];
    downloadPDF(
      data,
      columns,
      formatTimeSlotsData,
      "assignment submission Data"
    );
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "assignment submission Data");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Student",
      dataIndex: "student",
    },
    {
      title: "Assignment",
      dataIndex: "assignment",
      render: (assignment) =>
        assingmentsData &&
        assingmentsData.find((item) => item.id === assignment)?.title,
    },
    {
      title: "Is Graded",
      dataIndex: "is_graded",
      render: (grade) => (grade ? "Yes" : "No"),
    },
    {
      title: "Grade",
      dataIndex: "grade",
    },
    {
      title: "Submitted Date",
      dataIndex: "submitted_date",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link
            to={`/academic/assignment-submissions/${record.id}`}
            className="me-4"
          >
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.student}`,
                dispatch,
                `academic/admin/assignment-submissions/${record.id}/`,
                fetchAssignmentSubmissions,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <BreadBtn
        headerTitle="Assignment Submissions"
        items={breadcrumbitems}
        addPath="/academic/assignment-submissions/add-assignment-submission"
      />
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Select
              showSearch
              className="w-200"
              placeholder="Select Student"
              optionFilterProp="children"
              onChange={(value) => handleFilterChange("student", value || null)}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              allowClear
              onClear={() => handleFilterChange("student", null)}
            >
              {studentsData && studentsData.length > 0
                ? studentsData.map((student) => (
                    <Option value={student.id} key={student.id}>
                      {student.user.first_name}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              showSearch
              className="w-200"
              placeholder="Select Assignment"
              optionFilterProp="children"
              onChange={(value) =>
                handleFilterChange("assignment", value || null)
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              allowClear
              onClear={() => handleFilterChange("assignment", null)}
            >
              {assingmentsData && assingmentsData.length > 0
                ? assingmentsData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.title}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("is_graded", value)}
            >
              <Option value="all">All Graded & Non Graded</Option>
              <Option value={true}>Graded</Option>
              <Option value={false}>Non Graded</Option>
            </Select>
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default AssignmentSubmission;
