import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

// Fetch time slots with optional filters (start_time, end_time, limit, offset, is_break)
export const fetchTimeSlots = createAsyncThunk(
  "timeSlots/fetchTimeSlots",
  async (
    { start_time = "", end_time = "", limit = 10, offset = 0, is_break } = {},
    { rejectWithValue }
  ) => {
    try {
      const params = {
        limit,
        offset,
        start_time,
        end_time,
      };

      if (is_break !== undefined) {
        params.is_break = is_break; // Boolean value for is_break
      }

      const response = await getData("academic/admin/time-slots/", params);
      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch a single time slot by ID
export const fetchSingleTimeSlot = createAsyncThunk(
  "timeSlots/fetchSingleTimeSlot",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`academic/admin/time-slots/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Post a new time slot
export const postTimeSlot = createAsyncThunk(
  "timeSlots/postTimeSlot",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await postData("academic/admin/time-slots/", payload);
      if (response.status === 200 || 201) {
        message.success("Time slot created successfully!");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.start_time?.[0] ||
          error.response.statusText ||
          "Failed to post time slot.";
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Update an existing time slot by ID
export const putTimeSlot = createAsyncThunk(
  "timeSlots/putTimeSlot",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await putData(
        `academic/admin/time-slots/${id}/`,
        payload
      );
      if (response.status === 200 || 201) {
        message.success("Time slot updated successfully!");
        fetchSingleTimeSlot(id);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.start_time?.[0] ||
          error.response.statusText ||
          "Failed to update time slot.";
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Time slots slice
const timeSlotsSlice = createSlice({
  name: "timeSlots",
  initialState: {
    data: [],
    singleData: {},
    isLoading: false,
    offset: 0,
    limit: 10,
    totalItems: 0,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeSlots.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTimeSlots.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchTimeSlots.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = action.payload;
      })
      .addCase(fetchSingleTimeSlot.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleTimeSlot.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleData = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleTimeSlot.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postTimeSlot.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTimeSlot.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postTimeSlot.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(putTimeSlot.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putTimeSlot.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(putTimeSlot.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default timeSlotsSlice.reducer;
