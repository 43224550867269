import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "./ProtectedRoutes";
import Department from "../view/teachers/department/Department";
import DepartmentForm from "../view/teachers/department/DepartmentForm";
import Designation from "../view/teachers/designation/Designation";
import DesignationForm from "../view/teachers/designation/DesignationForm";
import Teachers from "../view/teachers/teacher/Teachers";
import TeacherForm from "../view/teachers/teacher/TeacherForm";
import TeacherAttendance from "../view/teachers/teacher-attendance/TeacherAttendance";
import TeacherAttendanceForm from "../view/teachers/teacher-attendance/TeacherAttendanceForm";

const TeacherRoutes = () => {
  return (
    <Routes>
      {/* departments */}
      <Route
        path="/departments"
        element={<ProtectedRoutes Component={Department} />}
      />
      <Route
        path="/departments/add-department"
        element={<ProtectedRoutes Component={DepartmentForm} />}
      />
      <Route
        path="/departments/:id"
        element={<ProtectedRoutes Component={DepartmentForm} />}
      />
      {/* designations */}
      <Route
        path="/designations"
        element={<ProtectedRoutes Component={Designation} />}
      />
      <Route
        path="/designations/add-designation"
        element={<ProtectedRoutes Component={DesignationForm} />}
      />
      <Route
        path="/designations/:id"
        element={<ProtectedRoutes Component={DesignationForm} />}
      />
      {/* teachers */}
      <Route
        path="/teachers"
        element={<ProtectedRoutes Component={Teachers} />}
      />
      <Route
        path="/teachers/add-teacher"
        element={<ProtectedRoutes Component={TeacherForm} />}
      />
      <Route
        path="/teachers/:id"
        element={<ProtectedRoutes Component={TeacherForm} />}
      />
      {/* attendance */}
      <Route
        path="/teacher-attendance"
        element={<ProtectedRoutes Component={TeacherAttendance} />}
      />
      <Route
        path="/teacher-attendance/add-attendance"
        element={<ProtectedRoutes Component={TeacherAttendanceForm} />}
      />
    </Routes>
  );
};

export default TeacherRoutes;
