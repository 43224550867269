import {
  Breadcrumb,
  Button,
  Col,
  Image,
  Input,
  message,
  Row,
  Spin,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import UploadButton from "../../../components/UploadButton";
import ImagePopup from "../../../components/ImagePopup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingle as fetchSingleTestimonial,
  postData as postTestimonial,
  updateData as putTestimonial,
} from "../../../libs/redux/features/website/testimonialSlice";
import usePageTitle from "../../../components/PageTitle";
import { HomeOutlined } from "@ant-design/icons";

const TestimonialForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isLoading, singleData } = useSelector((state) => state.testimonials);

  const [testimonial, setTestimonial] = useState({
    name: "",
    designation: "",
    feedback: "",
    address: "",
    image: {},
  });

  useEffect(() => {
    if (!isNaN(id)) {
      dispatch(fetchSingleTestimonial(id));
    }
  }, [id, dispatch]);

  usePageTitle(!isNaN(id) ? `${singleData.name}` : "Add Testimonial");

  useEffect(() => {
    if (!isNaN(id) && Object.keys(singleData).length > 0) {
      setTestimonial(singleData);
    }
  }, [id, singleData]);

  const [imageModal, setImageModal] = useState(false);
  const handleImageUploadSuccess = (newImage) => {
    setTestimonial((prev) => ({
      ...prev,
      image: newImage[0],
    }));
    setImageModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isNaN(id)) {
      const payload = { ...testimonial, image: testimonial.image.id };
      dispatch(putTestimonial({ id: id, payload: payload }));
    } else {
      const image = testimonial.image.id;
      if (image) {
        const payload = { ...testimonial, image: image };
        dispatch(
          postTestimonial({
            payload,
            onSuccess: () => {
              message.success("New testimonial created successfully!");
              setTestimonial({
                name: "",
                designation: "",
                feedback: "",
                address: "",
                image: {},
              });
            },
          })
        );
      } else {
        message.error("No image detected!");
      }
    }
  };

  return (
    <div>
      <div className="breadcrumb p-4">
        <div>
          <h1 className="fs-2 text-dark">
            {!isNaN(id) ? `${singleData.name}` : "Add Testimonial"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    <HomeOutlined />
                  </Link>
                ),
              },
              {
                title: "Website",
              },
              {
                title: "Components",
              },
              {
                title: (
                  <Link to="/website/components/testimonials">
                    Testimonials
                  </Link>
                ),
              },
              {
                title: !isNaN(id) ? `${singleData.name}` : "Add Testimonial",
              },
            ]}
          />
        </div>
      </div>
      <div className="bg-white rounded-10 mb-5">
        <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
          <h2 className="fs-4">Page Information</h2>
        </div>
        <div className="p-4" style={{ paddingTop: 10 }}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">* Name</p>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Name"
                      value={testimonial.name}
                      onChange={(e) =>
                        setTestimonial((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">* Designation</p>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Designation"
                      value={testimonial.designation}
                      onChange={(e) =>
                        setTestimonial((prev) => ({
                          ...prev,
                          designation: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">* Feedback</p>
                    <TextArea
                      className="w-100"
                      autoSize={{ minRows: 2, maxRows: 4 }}
                      placeholder="Feedback"
                      value={testimonial.feedback}
                      onChange={(e) =>
                        setTestimonial((prev) => ({
                          ...prev,
                          feedback: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">* Address</p>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Address"
                      value={testimonial.address}
                      onChange={(e) =>
                        setTestimonial((prev) => ({
                          ...prev,
                          address: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-5">
                    <p className="mb-2 ps-2 fw-6">* Image</p>
                    <div className="image-preview-grid">
                      {testimonial.image &&
                      Object.keys(testimonial.image).length > 0 ? (
                        <Image
                          height={100}
                          width={100}
                          className="object-cover rounded-5"
                          alt={testimonial.image.alt_text}
                          src={testimonial.image.image}
                        />
                      ) : (
                        ""
                      )}
                      <UploadButton
                        onClick={() => setImageModal(true)}
                        height={100}
                        width={100}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <Button
                    className="w-100 mt-3"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </form>
          </Spin>
        </div>
      </div>
      {imageModal && (
        <ImagePopup
          open={imageModal}
          onCancel={() => setImageModal(false)}
          onUploadSuccess={handleImageUploadSuccess}
          cropAspectRatio={16 / 9}
          imageTitle={`${testimonial.name} - testimonial-image`}
        />
      )}
    </div>
  );
};

export default TestimonialForm;
