import React, { useEffect, useState, useCallback } from "react";
import {
  Breadcrumb,
  Table,
  Tooltip,
  Popconfirm,
  message,
  Pagination,
  Input,
} from "antd";
import { Link } from "react-router-dom";
import { getData, deleteData } from "../../../libs/axios/axiosInstance";
import { FaRegCopy } from "react-icons/fa6";
import { DeleteOutlined } from "@ant-design/icons";
import usePageTitle from "../../../components/PageTitle";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";
import { downloadPDF, downloadXLS } from "../../../components/utility";

const { Search } = Input;

const ContactMessage = () => {
  usePageTitle("Contact Us Messages");
  const [messages, setMessages] = useState({
    totalItems: 0,
    data: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    search: "",
  });

  const fetchMessages = useCallback(async () => {
    const filters = {
      limit: params.limit,
      offset: params.offset,
    };
    setIsLoading(true);
    try {
      if (params.search !== "") {
        filters.search = params.search;
      }
      const response = await getData("website/contactmessage/", filters);
      if (response.status === 200 || response.status === 201) {
        setMessages({
          totalItems: response.data.count,
          data: response.data.results,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }, [params]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  const handleSearch = (value) => {
    setParams((prevParams) => ({
      ...prevParams,
      search: value,
      offset: 0,
    }));
  };

  const handlePageChange = (page, pageSize) => {
    setParams((prevParams) => ({
      ...prevParams,
      offset: (page - 1) * pageSize,
      limit: pageSize,
    }));
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    message.success("Copied to clipboard!");
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteData(`website/contactmessage/${id}/`);

      if (response.status === 200 || response.status === 201) {
        message.success("Message deleted successfully!");
        fetchMessages(); // Re-fetch data after deletion
      } else {
        message.error("Failed to delete the message.");
      }
    } catch (error) {
      message.error("An error occurred while deleting the message.");
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      fixed: "left",
      render: (text, record, index) => index + 1 + params.offset,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 200,
      key: "email",
      render: (text) => (
        <div className="flex aic">
          <p>{text}</p>
          <FaRegCopy
            className="ms-3"
            onClick={() => handleCopy(text)}
            style={{ cursor: "pointer", color: "#2b3990" }}
          />
        </div>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text) => (
        <div className="flex aic">
          <p>{text || "N/A"}</p>
          {text && (
            <FaRegCopy
              className="ms-3"
              onClick={() => handleCopy(text)}
              style={{ cursor: "pointer", color: "#2b3990" }}
            />
          )}
        </div>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (text) => (
        <Tooltip title={text}>
          <span>
            {text.slice(0, 20)}
            {text.length > 20 ? "..." : ""}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <Popconfirm
          placement="leftTop"
          title={`Are you sure you want to delete the message from "${record.name}"?`}
          onConfirm={() => handleDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined className="text-danger c-pointer" />
        </Popconfirm>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Name: item.name || "N/A",
    Email: item.email || "N/A",
    Phone: item.phone || "N/A",
    Subject: item.subject || "N/A",
    Message: item.message || "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Name", "Email", "Phone", "Subject", "Message"];
    downloadPDF(
      messages.data && messages.data,
      columns,
      formatTimeSlotsData,
      "Contact Messages Data"
    );
  };

  const handleDownloadXLS = () => {
    downloadXLS(
      messages.data && messages.data,
      formatTimeSlotsData,
      "Contact Messages Data"
    );
  };
  return (
    <div>
      <div className="breadcrumb">
        <div className="">
          <h1 className="fs-2 text-dark">Contact Us Messages</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Website" },
              { title: "Components" },
              { title: "Contact Us Messages" },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic">
            <Search
              placeholder="Search Title"
              onSearch={handleSearch}
              className="w-200"
            />
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={messages.data}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => (
            <div className="flex-justify-between">
              <Pagination
                current={params.offset / params.limit + 1}
                pageSize={params.limit}
                total={messages.totalItems}
                onChange={handlePageChange}
                showSizeChanger
                pageSizeOptions={["10", "20", "30"]}
              />
              <p>
                Showing {params.offset + 1} to{" "}
                {Math.min(params.offset + params.limit, messages.totalItems)} of{" "}
                {messages.totalItems}
              </p>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default ContactMessage;
