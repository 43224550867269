import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, patchData } from "../../../axios/axiosInstance";

// Default limit of items per fetch
const DEFAULT_LIMIT = 36;

export const fetchGallery = createAsyncThunk(
  "gallery/fetchGallery",
  async (
    { limit = DEFAULT_LIMIT, offset = 0, search = "" },
    { rejectWithValue }
  ) => {
    try {
      const query = {
        search,
        limit: limit,
        offset: offset,
      };

      const response = await getData(`website/image/?`, query);
      console.log("fetch gallery", response);

      return {
        results: response.data.results,
        count: response.data.count,
        offset,
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchSingleImage = createAsyncThunk(
  "gallery/fetchSingleImage",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`website/image/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const patchSingleImage = createAsyncThunk(
  "gallery/patchSingleImage",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await patchData(`website/image/${id}/`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const gallerySlice = createSlice({
  name: "gallery",
  initialState: {
    data: [],
    isLoading: false,
    hasMore: true,
    offset: 0,
    limit: DEFAULT_LIMIT,
    totalItems: 0,
    singleImageLoading: false,
    singleImage: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGallery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchGallery.fulfilled, (state, action) => {
        const { results, count, offset } = action.payload;

        // Update loading state
        state.isLoading = false;

        // Update data array
        if (offset === 0) {
          // If this is the initial fetch, replace data with new results
          state.data = results;
        } else {
          // Append new results to existing data
          state.data = [...state.data, ...results];
        }

        // Update total items count
        state.totalItems = count;

        // Update offset
        state.offset = offset;

        // Determine if there are more items to fetch
        state.hasMore = state.offset < state.totalItems;
      })
      .addCase(fetchGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchSingleImage.pending, (state) => {
        state.singleImageLoading = true;
      })
      .addCase(fetchSingleImage.fulfilled, (state, action) => {
        state.singleImageLoading = false;
        state.singleImage = action.payload;
      })
      .addCase(fetchSingleImage.rejected, (state, action) => {
        state.singleImageLoading = false;
        state.singleImage = {};
        state.error = action.payload;
      })
      .addCase(patchSingleImage.pending, (state) => {
        state.singleImageLoading = true;
      })
      .addCase(patchSingleImage.fulfilled, (state, action) => {
        state.singleImageLoading = false;
        state.singleImage = action.payload;
      })
      .addCase(patchSingleImage.rejected, (state, action) => {
        state.singleImageLoading = false;
        state.singleImage = {};
        state.error = action.payload;
      });
  },
});

export default gallerySlice.reducer;
