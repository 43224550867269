import {
  Breadcrumb,
  Button,
  Col,
  Image,
  Input,
  message,
  Row,
  Spin,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getData, postData } from "../../../libs/axios/axiosInstance";
import UploadButton from "../../../components/UploadButton";
import ImagePopup from "../../../components/ImagePopup";
import useScrollEffect from "../../../components/scroEffect";
import usePageTitle from "../../../components/PageTitle";

const HomePage = () => {
  usePageTitle("Home Page");
  const isAtTop = useScrollEffect();

  const [homePage, setHomePage] = useState({
    seo: {
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    },
    about_us_title: "",
    about_us_text: "",
    school_speciality_title: "",
    gallery_title: "",
    event_title: "",
    about_us_background: {},
  });

  const [featuredImageModal, setFeaturedImageModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleImageUploadSuccess = (newImg) => {
    console.log(newImg);

    setHomePage((prev) => ({
      ...prev,
      about_us_background: newImg[0],
    }));
    setFeaturedImageModal(false);
  };
  console.log(homePage);

  const fetchHomePage = async () => {
    setIsLoading(true);
    try {
      const res = await getData("website/homepage/");
      if (res.status === 200) {
        const data = res.data;
        setHomePage({
          seo: data.seo,
          about_us_title: data.about_us_title,
          about_us_text: data.about_us_text,
          school_speciality_title: data.school_speciality_title,
          gallery_title: data.gallery_title,
          event_title: data.event_title,
          about_us_background: data.about_us_background,
        });
        setIsLoading(false);
      } else {
        message.error("error getting home page data");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error fetching home page", error);
      message.error("network error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHomePage();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
      let payload = homePage;
      payload.about_us_background = homePage.about_us_background.id;
      console.log("payload", payload);

      const postHomePage = await postData("website/homepage/", payload);
      console.log(postHomePage);

      if (postHomePage.status === 200) {
        message.success("Home page content added successfully!");
        fetchHomePage();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }
  return (
    <div>
      <div
        className={`breadcrumb flex-justify-between ${
          !isAtTop ? "sticky" : ""
        }`}
      >
        <div>
          <h1 className={`fs-2 text-dark ${!isAtTop ? "lh-1" : ""}`}>
            Home Page
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Website" },
              { title: "Pages" },
              { title: "Home" },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={false}>
            <div className="bg-white rounded-10 mb-5">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">SEO Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row className="mb-5">
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Meta Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Title"
                        value={homePage.seo.meta_title}
                        onChange={(e) =>
                          setHomePage((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_title: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Meta Descriptions</p>
                      <TextArea
                        className="w-100"
                        placeholder="Meta Descriptions"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        value={homePage.seo.meta_description}
                        onChange={(e) =>
                          setHomePage((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_description: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="">
                      <p className="mb-2 ps-2 fw-6">
                        Meta Keywords (comma separated)
                      </p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Keywords"
                        value={homePage.seo.meta_keywords}
                        onChange={(e) =>
                          setHomePage((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_keywords: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Page Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">About Us Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Page Title"
                        value={homePage.about_us_title}
                        onChange={(e) =>
                          setHomePage((prev) => ({
                            ...prev,
                            about_us_title: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">About Us Text</p>
                      <TextArea
                        className="w-100"
                        placeholder="Meta Descriptions"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        value={homePage.about_us_text}
                        onChange={(e) =>
                          setHomePage((prev) => ({
                            ...prev,
                            about_us_text: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">School Speciality Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="School Speciality Title"
                        value={homePage.school_speciality_title}
                        onChange={(e) =>
                          setHomePage((prev) => ({
                            ...prev,
                            school_speciality_title: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Gallery Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Gallery Title"
                        value={homePage.gallery_title}
                        onChange={(e) =>
                          setHomePage((prev) => ({
                            ...prev,
                            gallery_title: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Event Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Event Title"
                        value={homePage.event_title}
                        onChange={(e) =>
                          setHomePage((prev) => ({
                            ...prev,
                            event_title: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Gallery Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Page Title"
                        value={homePage.gallery_title}
                        onChange={(e) =>
                          setHomePage((prev) => ({
                            ...prev,
                            gallery_title: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Featured Image</p>
                      <div className="image-preview-grid">
                        {homePage.about_us_background &&
                        Object.keys(homePage.about_us_background).length > 0 ? (
                          <Image
                            height={100}
                            width={100}
                            className="object-cover rounded-5"
                            alt={homePage.about_us_background.alt_text}
                            src={homePage.about_us_background.image}
                          />
                        ) : (
                          ""
                        )}
                        <UploadButton
                          onClick={() => setFeaturedImageModal(true)}
                          height={100}
                          width={100}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className="w-100 my-5"
                      loading={isLoading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
      {featuredImageModal && (
        <ImagePopup
          imgLimit={1}
          existingImgs={[homePage.about_us_background]}
          open={featuredImageModal}
          onCancel={() => setFeaturedImageModal(false)}
          onUploadSuccess={handleImageUploadSuccess}
          cropAspectRatio={16 / 9}
          imageTitle={homePage.about_us_title}
        />
      )}
    </div>
  );
};

export default HomePage;
