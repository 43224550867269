// SEOInformation.js
import React from "react";
import { Row, Col, Input } from "antd";
import TextArea from "antd/es/input/TextArea";

const SEOInformation = ({ seo, onChange }) => {
  return (
    <div className="bg-white rounded-10 mb-5">
      <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
        <h2 className="fs-4">SEO Information</h2>
      </div>
      <div className="p-4" style={{ paddingTop: 10, paddingBottom: 5 }}>
        <Row className="mb-5">
          <Col span={24}>
            <div className="mb-3">
              <p className="mb-2 ps-2 fw-6">* Meta Title</p>
              <Input
                className="w-100"
                size="large"
                placeholder="Meta Title"
                value={seo.meta_title}
                onChange={(e) =>
                  onChange((prev) => ({
                    ...prev,
                    meta_title: e.target.value,
                  }))
                }
                required
              />
            </div>
          </Col>
          <Col span={24}>
            <div className="mb-3">
              <p className="mb-2 ps-2 fw-6">* Meta Descriptions</p>
              <TextArea
                className="w-100"
                placeholder="Meta Descriptions"
                autoSize={{ minRows: 2, maxRows: 4 }}
                value={seo.meta_description}
                onChange={(e) =>
                  onChange((prev) => ({
                    ...prev,
                    meta_description: e.target.value,
                  }))
                }
                required
              />
            </div>
          </Col>
          <Col span={24}>
            <div className="">
              <p className="mb-2 ps-2 fw-6">
                * Meta Keywords (comma separated)
              </p>
              <Input
                className="w-100"
                size="large"
                placeholder="Meta Keywords"
                value={seo.meta_keywords}
                onChange={(e) =>
                  onChange((prev) => ({
                    ...prev,
                    meta_keywords: e.target.value,
                  }))
                }
                required
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SEOInformation;
