import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";

const UploadButton = ({ onClick, height, width, danger }) => {
  return (
    <>
      <Button
        onClick={onClick}
        className={`flex flex-column aic justify-center ${
          danger ? "text-danger border-dashed-double" : ""
        }`}
        style={{ height: height, width: width }}
        type="dashed"
        danger={danger}
      >
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </Button>
      {danger ? "This field is required" : ""}
    </>
  );
};

export default UploadButton;
