import {
  Breadcrumb,
  Button,
  Input,
  message,
  Modal,
  Pagination,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAll,
  postData,
  updateData,
} from "../../../libs/redux/features/payroll/paySchedulesSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../../components/utility";
import {
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import DataConversion from "../../../components/DataConversion";
import { Link } from "react-router-dom";

const { Option } = Select;

const PayrollSchedules = () => {
  usePageTitle("Pay Schedules");

  const breadcrumbitems = [
    {
      title: (
        <Link to="/">
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: "Payroll Info",
    },
    { title: "Pay Schedules" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const paySchedulesData = useSelector((state) => state.paySchedules.data);
  const totalItems = useSelector((state) => state.paySchedules.totalItems);
  const isLoading = useSelector((state) => state.paySchedules.isLoading);
  const error = useSelector((state) => state.paySchedules.error);

  const [modalData, setModalData] = useState({
    name: "",
    frequency: 1,
  });

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };

    dispatch(fetchAll(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Name: item.name || "N/A",
    Frequency:
      item.frequency === 1
        ? "Monthly"
        : item.frequency === 2
        ? "Bi Weekly"
        : item.frequency === 3
        ? "Weekly"
        : "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Name", "Frequency"];
    downloadPDF(
      paySchedulesData,
      columns,
      formatTimeSlotsData,
      "Pay Schedules Data"
    );
  };

  const handleDownloadXLS = () => {
    downloadXLS(paySchedulesData, formatTimeSlotsData, "Pay Schedules Data");
  };

  const [id, setId] = useState(null);

  const fetchSingleItem = (data) => {
    setModalData({
      name: data.name,
      frequency: data.frequency,
    });
    setId(data.id);
    setAddModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      render: (frequency) =>
        frequency === 1
          ? "Monthly"
          : frequency === 2
          ? "Bi Weekly"
          : frequency === 3
          ? "Weekly"
          : "N/A",
    },

    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Button className="me-4 p-2" onClick={() => fetchSingleItem(record)}>
            <EyeOutlined />
          </Button>
          <div
            onClick={() =>
              handleDelete(
                `${record.name}`,
                dispatch,
                `payroll/admin/pay-schedule/${record.id}/`,
                fetchAll,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handleModalCancel = () => {
    setAddModal(false);
    if (id) {
      setId(null);
    }
    setModalData({
      name: "",
      frequency: 1,
    });
  };

  const handleModalOk = () => {
    const payload = modalData;
    if (!id) {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New pay schedule added successfully!");
            handleModalCancel();
            setAddModal(false);
          },
        })
      );
    } else {
      dispatch(updateData({ id, payload }));
    }
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Pay Schedules</h1>
          <Breadcrumb className="fs-5" items={breadcrumbitems} />
        </div>
        <div>
          <Button
            onClick={() => setAddModal(true)}
            type="primary"
            icon={<PlusOutlined />}
            size="large"
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3">
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={paySchedulesData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* add and edit modal */}
      {addModal && (
        <Modal
          title={<h2 className="fs-2 mb-3">Final Grade Form</h2>}
          open={addModal}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={isLoading}
              loading={isLoading}
            >
              {id ? "Update" : "Submit"}
            </Button>,
          ]}
          onCancel={handleModalCancel}
        >
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Name</p>
              {error && error.name ? (
                <p className="text-sm text-danger ms-3">({error.name})</p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Name"
              required
              value={modalData.name}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              status={error && error.name ? "error" : ""}
            />
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Frequency</p>
              {error && error.frequency ? (
                <p className="text-sm text-danger ms-3">({error.frequency})</p>
              ) : (
                ""
              )}
            </div>
            <Select
              className="w-100"
              size="large"
              value={modalData.frequency}
              onChange={(value) =>
                setModalData((prev) => ({
                  ...prev,
                  frequency: value,
                }))
              }
              status={error && error.frequency ? "error" : ""}
            >
              <Option value={1}>Monthly</Option>
              <Option value={2}>Bi Weekly</Option>
              <Option value={3}>Weekly</Option>
            </Select>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PayrollSchedules;
