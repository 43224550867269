import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { PlusOutlined, HomeOutlined } from "@ant-design/icons";

const BreadBtn = ({
  headerTitle,
  items,
  addPath,
  addTitle,
  addButton,
  btnClick,
}) => {
  return (
    <div
      className={`breadcrumb ${
        addPath || addButton ? "flex-justify-between" : ""
      }`}
    >
      <div>
        <h1 className="fs-2 text-dark">{headerTitle}</h1>
        <Breadcrumb
          className="fs-5"
          items={[
            {
              title: (
                <Link to="/">
                  <HomeOutlined />
                </Link>
              ),
            },
            ...items,
          ]}
        />
      </div>

      {addPath && (
        <div>
          <Link to={addPath}>
            <Button type="primary" icon={<PlusOutlined />} size="large">
              {addTitle ? addTitle : "Add New"}
            </Button>
          </Link>
        </div>
      )}
      {addButton && (
        <div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            onClick={btnClick}
          >
            {addTitle ? addTitle : "Add New"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default BreadBtn;
