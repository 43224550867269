import { Breadcrumb, Button, Col, Input, message, Row, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getData, postData } from "../../../libs/axios/axiosInstance";
import useScrollEffect from "../../../components/scroEffect";
import usePageTitle from "../../../components/PageTitle";
import JoditEditor from "jodit-react";

const Admission = () => {
  usePageTitle("Admission Page");
  const isAtTop = useScrollEffect();
  const [admission, setAdmission] = useState({
    seo: {
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    },
    page_title: "",
    title: "",
    about_admission: "",
    brief_description: "",
    admission_session: "",
    admission_phone: "",
    admission_email: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getData("website/admissionpage/")
      .then((res) => {
        const data = res.data;
        setAdmission({
          seo: data.seo,
          page_title: data.page_title,
          title: data.title,
          about_admission: data.about_admission,
          brief_description: data.brief_description,
          admission_session: data.admission_session,
          admission_phone: data.admission_phone,
          admission_email: data.admission_email,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error getting admission", error);
        setIsLoading(false);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("admission payload", admission);
    const response = await postData("website/admissionpage/", admission);
    console.log(response);
    if (response.status === 200) {
      message.success("Admission page added successfully!");
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div
        className={`breadcrumb flex-justify-between ${
          !isAtTop ? "sticky" : ""
        }`}
      >
        <div>
          <h1 className={`fs-2 text-dark ${!isAtTop ? "lh-1" : ""}`}>
            Admission Page
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Website" },
              { title: "Pages" },
              { title: "Admission" },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10 mb-5">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">SEO Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row className="mb-5">
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Meta Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Title"
                        value={admission.seo.meta_title}
                        onChange={(e) =>
                          setAdmission((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_title: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Meta Descriptions</p>
                      <TextArea
                        className="w-100"
                        placeholder="Meta Descriptions"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        value={admission.seo.meta_description}
                        onChange={(e) =>
                          setAdmission((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_description: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="">
                      <p className="mb-2 ps-2 fw-6">
                        Meta Keywords (comma separated)
                      </p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Keywords"
                        value={admission.seo.meta_keywords}
                        onChange={(e) =>
                          setAdmission((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_keywords: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Page Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Page Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Page Title"
                        value={admission.page_title}
                        onChange={(e) =>
                          setAdmission((prev) => ({
                            ...prev,
                            page_title: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Page Title"
                        value={admission.title}
                        onChange={(e) =>
                          setAdmission((prev) => ({
                            ...prev,
                            title: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">About Admission</p>
                      <TextArea
                        className="w-100"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        placeholder="About Admission"
                        value={admission.about_admission}
                        onChange={(e) =>
                          setAdmission((prev) => ({
                            ...prev,
                            about_admission: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Brief Description</p>
                      <JoditEditor
                        className="rounded-5"
                        style={{ maxHeight: "50vh" }}
                        value={admission.brief_description}
                        onBlur={(value) =>
                          setAdmission((prev) => ({
                            ...prev,
                            brief_description: value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Admission Session</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Admission Session"
                        value={admission.admission_session}
                        onChange={(e) =>
                          setAdmission((prev) => ({
                            ...prev,
                            admission_session: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Admission Phone</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Admission Phone"
                        value={admission.admission_phone}
                        onChange={(e) =>
                          setAdmission((prev) => ({
                            ...prev,
                            admission_phone: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Admission Email</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Admission Email"
                        value={admission.admission_email}
                        onChange={(e) =>
                          setAdmission((prev) => ({
                            ...prev,
                            admission_email: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="my-5">
                      <Button
                        type="primary"
                        size="large"
                        className="w-100"
                        htmlType="submit"
                        loading={isLoading}
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
    </div>
  );
};

export default Admission;
