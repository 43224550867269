import React, { useState } from "react";
import {
  Modal,
  Button,
  DatePicker,
  Select,
  Input,
  Image,
  Row,
  Col,
} from "antd";
import dayjs from "dayjs";

const { Option } = Select;

const SingleAttendanceModal = ({
  visible,
  onCancel,
  onSubmit,
  teacher,
  initialAttendance,
  loading,
}) => {
  const [modalAttendance, setModalAttendance] = useState(initialAttendance);

  const handleInputChange = (key, value) => {
    setModalAttendance((prev) => ({
      ...prev,
      [key]: key === "date" ? value : value,
    }));
  };

  const handleOk = () => {
    const formattedPayload = {
      ...modalAttendance,
      date: dayjs(modalAttendance.date).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    };
    onSubmit(formattedPayload);
  };

  return (
    <Modal
      title={`${teacher?.user?.first_name || ""} ${
        teacher?.user?.last_name || ""
      }`}
      open={visible}
      onCancel={onCancel}
      footer={
        <Button
          type="primary"
          size="medium"
          onClick={handleOk}
          loading={loading}
        >
          Submit
        </Button>
      }
    >
      <Row>
        <Col span={24} className="mb-3">
          <Image
            style={{ maxHeight: 120 }}
            src={teacher?.user?.image?.image}
            alt={teacher?.user?.image?.alt_text}
          />
        </Col>
        <Col span={24} className="mb-3">
          <DatePicker
            className="w-100"
            size="medium"
            value={dayjs(modalAttendance.date)}
            onChange={(value) => handleInputChange("date", value)}
            showTime
          />
        </Col>
        <Col span={24} className="mb-3">
          <Select
            value={modalAttendance.attendance_type}
            className="w-100"
            size="medium"
            onChange={(value) => handleInputChange("attendance_type", value)}
          >
            <Option value="Full Day">Full Day</Option>
            <Option value="Half Day">Half Day</Option>
            <Option value="Quarter Day">Quarter Day</Option>
          </Select>
        </Col>
        <Col span={24} className="mb-3">
          <Select
            value={modalAttendance.status}
            className="w-100"
            size="medium"
            onChange={(value) => handleInputChange("status", value)}
          >
            <Option value="Present">Present</Option>
            <Option value="Absent">Absent</Option>
            <Option value="Late">Late</Option>
            <Option value="Excused">Excused</Option>
          </Select>
        </Col>
        <Col span={24}>
          <Input
            placeholder="Notes"
            className="w-100"
            size="medium"
            value={modalAttendance.notes}
            onChange={(e) => handleInputChange("notes", e.target.value)}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default SingleAttendanceModal;
