import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

// Fetch pages with optional filters (limit, offset)
export const fetchPages = createAsyncThunk(
  "pages/fetchPages",
  async ({ limit = 10, offset = 0 } = {}, { rejectWithValue }) => {
    try {
      const params = {
        limit,
        offset,
      };
      const response = await getData("utility/admin/page/", params);
      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { detail: "Something went wrong" }
      );
    }
  }
);

// Fetch a single page by ID
export const fetchSinglePage = createAsyncThunk(
  "pages/fetchSinglePage",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`utility/admin/page/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { detail: "Something went wrong" }
      );
    }
  }
);

// Post a new page
export const postPage = createAsyncThunk(
  "pages/postPage",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await postData("utility/admin/page/", payload);
      if (response.status >= 200 && response.status < 300) {
        message.success("Page created successfully!");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.name?.[0] ||
          error.response.statusText ||
          "Failed to create page.";
        message.error(errorMessage);
      }
      return rejectWithValue(
        error.response?.data || { detail: "Something went wrong" }
      );
    }
  }
);

// Update an existing page by ID
export const putPage = createAsyncThunk(
  "pages/putPage",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await putData(`utility/admin/page/${id}/`, payload);
      if (response.status >= 200 && response.status < 300) {
        message.success("Page updated successfully!");
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.name?.[0] ||
          error.response.statusText ||
          "Failed to update page.";
        message.error(errorMessage);
      }
      return rejectWithValue(
        error.response?.data || { detail: "Something went wrong" }
      );
    }
  }
);

// Pages slice
const pagesSlice = createSlice({
  name: "pages",
  initialState: {
    data: [],
    singleData: {},
    isLoading: false,
    offset: 0,
    limit: 10,
    totalItems: 0,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchPages cases
      .addCase(fetchPages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchPages.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = action.payload;
      })

      // Handle fetchSinglePage cases
      .addCase(fetchSinglePage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSinglePage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleData = action.payload;
        state.error = null;
      })
      .addCase(fetchSinglePage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Handle postPage cases
      .addCase(postPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postPage.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postPage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Handle putPage cases
      .addCase(putPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putPage.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(putPage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default pagesSlice.reducer;
