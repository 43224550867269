import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchSingle as fetchSingleTimeSlot,
  postData as postTimeSlot,
  updateData as putTimeSlot,
} from "../../../libs/redux/features/academic/timeslotsNewSlice";
import { Button, Checkbox, Col, message, Row, Spin, TimePicker } from "antd";
import dayjs from "dayjs";
import usePageTitle from "../../../components/PageTitle";
import BreadBtn from "../../../components/BreadBtn";

const TimeSlotsForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const singleData = useSelector((state) => state.timeslotsNew.singleData);
  const isLoading = useSelector((state) => state.timeslotsNew.isLoading);
  const error = useSelector((state) => state.timeslotsNew.error);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleTimeSlot(id));
    }
  }, [id, dispatch]);

  usePageTitle(id ? "TIme Slot Detail" : "Add Time Slot");

  const [timeSlot, setTimeSlot] = useState({
    start_time: "",
    end_time: "",
    is_break: false,
  });

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setTimeSlot({
        start_time: singleData.start_time,
        end_time: singleData.end_time,
        is_break: singleData.is_break,
      });
    }
  }, [id, singleData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = timeSlot;
    if (id) {
      dispatch(putTimeSlot({ id: id, payload: payload }));
    } else {
      dispatch(
        postTimeSlot({
          payload,
          onSuccess: () => {
            message.success("New timeslot created successfully!");
            setTimeSlot({
              start_time: "",
              end_time: "",
              is_break: false,
            });
          },
        })
      );
    }
  };
  const breadcrumbitems = [
    {
      title: "Academic",
    },
    {
      title: <Link to="/academic/time-slots">Time Slots</Link>,
    },
    {
      title: id ? "Time Slot Detail" : "Add Designation",
    },
  ];
  return (
    <div>
      <BreadBtn
        headerTitle={id ? "Time Slot Detail" : "Add Time Slot"}
        items={breadcrumbitems}
      />

      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Time Slot Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Start Time</p>
                        {error && error.start_time ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.start_time})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <TimePicker
                        className="w-100"
                        size="large"
                        placeholder="Start Time"
                        required
                        value={
                          timeSlot.start_time
                            ? dayjs(timeSlot.start_time, "HH:mm:ss")
                            : ""
                        }
                        onChange={(value) =>
                          setTimeSlot((prev) => ({
                            ...prev,
                            start_time: value
                              ? dayjs(value).format("HH:mm:ss")
                              : null,
                          }))
                        }
                        status={error && error.start_time ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* End Time</p>
                        {error && error.end_time ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.end_time})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <TimePicker
                        className="w-100"
                        size="large"
                        placeholder="Start Time"
                        required
                        value={
                          timeSlot.end_time
                            ? dayjs(timeSlot.end_time, "HH:mm:ss")
                            : ""
                        }
                        onChange={(value) =>
                          setTimeSlot((prev) => ({
                            ...prev,
                            end_time: value
                              ? dayjs(value).format("HH:mm:ss")
                              : null,
                          }))
                        }
                        status={error && error.end_time ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="my-5">
                      <Checkbox
                        checked={timeSlot.is_break}
                        onChange={(e) =>
                          setTimeSlot((prev) => ({
                            ...prev,
                            is_break: e.target.checked,
                          }))
                        }
                      >
                        Is Break ?
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className="w-100 mt-3"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      {id ? "Update" : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
    </div>
  );
};

export default TimeSlotsForm;
