import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchTimeSlots } from "../../../libs/redux/features/academic/timeslotsNewSlice";
import {
  handleDelete,
  handleSwitchChange,
  downloadPDF,
  downloadXLS,
} from "../../../components/utility";
import { TimePicker, Select, Table, Pagination, Switch } from "antd";
import { Link } from "react-router-dom";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";
import BreadBtn from "../../../components/BreadBtn";

const { Option } = Select;

const TimeSlots = () => {
  usePageTitle("Time Slots");

  const dispatch = useDispatch();

  const data = useSelector((state) => state.timeslotsNew.data);
  const isLoading = useSelector((state) => state.timeslotsNew.isLoading);
  const totalItems = useSelector((state) => state.timeslotsNew.totalItems);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    start_time: null,
    end_time: null,
    is_break: "all",
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.start_time !== null) {
      params.start_time = filters.start_time;
    }
    if (filters.end_time !== null) {
      params.end_time = filters.end_time;
    }
    if (filters.is_break !== "all") {
      params.is_break = filters.is_break;
    }
    dispatch(fetchTimeSlots(params));
  }, [filters, dispatch]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 45,
      // fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
    },
    {
      title: "End Time",
      dataIndex: "end_time",
    },
    {
      title: "Is Break",
      dataIndex: "is_break",
      render: (is_break, record) => (
        <Switch
          checked={is_break}
          onChange={(checked) =>
            handleSwitchChange(
              "is_break",
              checked,
              record,
              dispatch,
              `academic/admin/time-slots/${record.id}/`,
              fetchTimeSlots,
              filters
            )
          }
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/academic/time-slots/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.start_time} - ${record.end_time}`,
                dispatch,
                `academic/admin/time-slots/${record.id}/`,
                fetchTimeSlots,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    "Start Time": item.start_time || "N/A",
    "End Time": item.end_time || "N/A",
    "Is Break": item.is_break ? "Active" : "Inactive",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Start Time", "End Time", "Is Break"];
    downloadPDF(data, columns, formatTimeSlotsData, "TimeSlots Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "TimeSlots Data");
  };

  const breadcrumbitems = [{ title: "Academic" }, { title: "Time Slots" }];

  return (
    <div>
      <BreadBtn
        headerTitle="TimeSlots"
        items={breadcrumbitems}
        addPath="/academic/time-slots/add-time-slot"
      />

      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <TimePicker
              className="w-200"
              onChange={(value) =>
                handleFilterChange(
                  "start_time",
                  value ? dayjs(value).format("HH:mm:ss") : null
                )
              }
              value={
                filters.start_time
                  ? dayjs(filters.start_time, "HH:mm:ss")
                  : null
              }
              placeholder="Start Time"
            />
            <TimePicker
              className="w-200"
              onChange={(value) =>
                handleFilterChange(
                  "end_time",
                  value ? dayjs(value).format("HH:mm:ss") : null
                )
              }
              value={
                filters.end_time ? dayjs(filters.end_time, "HH:mm:ss") : null
              }
              placeholder="End Time"
            />
            <Select
              className="w-200"
              value={filters.is_break}
              onChange={(value) => handleFilterChange("is_break", value)}
            >
              <Option value="all">No Break Status Filter</Option>
              <Option value={true}>Is Break</Option>
              <Option value={false}>No Break</Option>
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default TimeSlots;
