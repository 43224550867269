import { Pagination, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchAcademicYear } from "../../libs/redux/features/academic/academicYearNewSlice";
import { fetchAll as fetchClasses } from "../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchCourses } from "../../libs/redux/features/academic/coursesNewSlice";
import { fetchAll as fetchTeachers } from "../../libs/redux/features/teacher-info/teachersNewSlice";
import { fetchAll } from "../../libs/redux/features/learning/onlineClassesSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../components/utility";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import BreadBtn from "../../components/BreadBtn";
import DataConversion from "../../components/DataConversion";

const { Option } = Select;

const OnlineClasses = () => {
  usePageTitle("Online Classes");

  const breadcrumbitems = [
    {
      title: "Learning",
    },
    { title: "Online Classes" },
  ];

  const dispatch = useDispatch();
  const onlineClasses = useSelector((state) => state.onlineClasses.data);
  const totalItems = useSelector((state) => state.onlineClasses.totalItems);
  const isLoading = useSelector((state) => state.onlineClasses.isLoading);

  const academicYearData = useSelector((state) => state.academicYearsNew.data);
  const classesData = useSelector((state) => state.classesNew.data);
  const coursesData = useSelector((state) => state.coursesNew.data);
  const teachersData = useSelector((state) => state.teachersNew.data);

  useEffect(() => {
    if (academicYearData.length === 0) {
      dispatch(fetchAcademicYear());
    }
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
    if (teachersData.length === 0) {
      dispatch(fetchTeachers());
    }
  }, [
    dispatch,
    academicYearData.length,
    classesData.length,
    teachersData.length,
  ]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    academic_year: "all",
    class_level: "all",
    course: "all",
    teacher: null,
  });

  useEffect(() => {
    if (filters.class_level !== "all") {
      dispatch(fetchCourses({ class_level: filters.class_level }));
    } else {
      dispatch(fetchCourses());
    }
  }, [dispatch, filters.class_level]);

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.academic_year !== "all") {
      params.academic_year = filters.academic_year;
    }
    if (filters.class_level !== "all") {
      params.class_level = filters.class_level;
    }
    if (filters.course !== "all") {
      params.course = filters.course;
    }
    if (filters.teacher !== null) {
      params.teacher = filters.teacher;
    }
    dispatch(fetchAll(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => {
    const teacher =
      teachersData &&
      teachersData.find((teacherItem) => teacherItem.id === item.teacher);
    const course =
      coursesData &&
      coursesData.find((courseItem) => courseItem.id === item.course)?.name;
    return {
      Serial: index + 1,
      "Start Time": item.start_time || "N/A",
      "End Time": item.end_time || "N/A",
      "Meeting Link": item.meeting_link || "N/A",
      Course: course || "N/A",
      Teacher:
        (teacher && `${teacher.user.first_name} ${teacher.user.last_name}`) ||
        "N/A",
    };
  };

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Start Time",
      "End Time",
      "Meeting Link",
      "Course",
      "Teacher",
    ];
    downloadPDF(
      onlineClasses,
      columns,
      formatTimeSlotsData,
      "online classes Data"
    );
  };

  const handleDownloadXLS = () => {
    downloadXLS(onlineClasses, formatTimeSlotsData, "online classes Data");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Course",
      dataIndex: "course",
      render: (courseId) =>
        coursesData && coursesData.find((item) => item.id === courseId)?.name,
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
    },
    {
      title: "End Time",
      dataIndex: "end_time",
    },
    {
      title: "Meeting Link",
      dataIndex: "meeting_link",
    },
    {
      title: "Teacher",
      dataIndex: "teacher",
      render: (teacherId) => {
        const teacher =
          teachersData && teachersData.find((item) => item.id === teacherId);

        return teacher
          ? `${teacher.user.first_name} ${teacher.user.last_name}`
          : "Unknown";
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/learning/online-classes/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.course}`,
                dispatch,
                `learning/admin/online-classes/${record.id}/`,
                fetchAll,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <BreadBtn
        headerTitle="Online Classes"
        items={breadcrumbitems}
        addPath="/learning/online-classes/add-class"
      />
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("academic_year", value)}
            >
              <Option value="all">All Academic Years</Option>
              {academicYearData && academicYearData.length > 0
                ? academicYearData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.year}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("class_level", value)}
            >
              <Option value="all">All Classes</Option>
              {classesData && classesData.length > 0
                ? classesData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("course", value)}
            >
              <Option value="all">All Courses</Option>
              {coursesData && coursesData.length > 0
                ? coursesData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              className="w-200"
              value={filters.teacher || null}
              onChange={(value) => handleFilterChange("teacher", value)}
              placeholder="Select Teacher"
              showSearch
              optionFilterProp="children"
              allowClear
              onClear={() => handleFilterChange("teacher", null)}
            >
              {teachersData && teachersData.length > 0
                ? teachersData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.user.first_name} {item.user.last_name}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={onlineClasses}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default OnlineClasses;
