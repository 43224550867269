import { Modal, Spin } from "antd";
import { Link } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { LoadingOutlined } from "@ant-design/icons";
import { IoCalendarSharp, IoCalendarOutline } from "react-icons/io5";
import { FaPersonWalkingLuggage } from "react-icons/fa6";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { PiExamFill } from "react-icons/pi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { TbReportMoney } from "react-icons/tb";
import { BiMoneyWithdraw } from "react-icons/bi";
import { MdOutlineSpeakerNotes } from "react-icons/md";

export const quick_links_data = [
  {
    title: "Exam Results",
    path: "/exam-data/exam-results",
    icon: PiExamFill,
  },
  {
    title: "Student Reports",
    path: "exam-data/student-reports",
    icon: HiOutlineDocumentReport,
  },
  {
    title: "Leave Requests",
    path: "/payroll-info/leave-requests",
    icon: FaPersonWalkingLuggage,
  },
  {
    title: "Payrolls",
    path: "/payroll-info/payrolls",
    icon: TbReportMoney,
  },
  {
    title: "Salary Slips",
    path: "/payroll-info/salary-slips",
    icon: BiMoneyWithdraw,
  },
  {
    title: "Budgets",
    path: "/accounting/budgets",
    icon: FaRegMoneyBillAlt,
  },
  {
    title: "Notices",
    path: "/website/components/notices",
    icon: MdOutlineSpeakerNotes,
  },
  {
    title: "Class Routines",
    path: "/academic/class-routines",
    icon: IoCalendarOutline,
  },
  {
    title: "Calendars",
    path: "/academic/calendars",
    icon: IoCalendarSharp,
  },
];

export const EarningsChart = ({ data }) => {
  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="earnings" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
      <div className="fs-4 mt-3 text-center">Total Earnings</div>
    </>
  );
};

export const ExpensesChart = ({ data }) => {
  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="expenses" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
      <div className="fs-4 mt-3 text-center">Total Expenses</div>
    </>
  );
};

export const earningsData = [
  { month: "Jan", earnings: 4000 },
  { month: "Feb", earnings: 3000 },
  { month: "Mar", earnings: 5000 },
  { month: "Apr", earnings: 4000 },
  { month: "May", earnings: 6000 },
  { month: "Jun", earnings: 7000 },
  { month: "Jul", earnings: 6000 },
  { month: "Aug", earnings: 5000 },
  { month: "Sep", earnings: 7000 },
  { month: "Oct", earnings: 8000 },
  { month: "Nov", earnings: 6000 },
  { month: "Dec", earnings: 9000 },
];

export const expensesData = [
  { month: "Jan", expenses: 2000 },
  { month: "Feb", expenses: 1000 },
  { month: "Mar", expenses: 1500 },
  { month: "Apr", expenses: 2500 },
  { month: "May", expenses: 3000 },
  { month: "Jun", expenses: 3500 },
  { month: "Jul", expenses: 3000 },
  { month: "Aug", expenses: 2700 },
  { month: "Sep", expenses: 3200 },
  { month: "Oct", expenses: 4100 },
  { month: "Nov", expenses: 4500 },
  { month: "Dec", expenses: 5000 },
];

export const CardSm = ({ Icon, title, value, loading = false }) => {
  return (
    <div className="flex aic px-2">
      <div
        className="border-dark-sm rounded-5 flex aic justify-center"
        style={{ height: 40, width: 40 }}
      >
        <Icon className="fs-3 text-prime" />
      </div>
      <div className="ms-3 flex flex-column">
        <p className="fw-6 text-dark">{title}</p>
        <Spin indicator={<LoadingOutlined />} spinning={loading} size="small">
          <p className="fw-7 text-prime">{value}</p>
        </Spin>
      </div>
    </div>
  );
};

export const QuickLinkCard = ({ path, Icon, title }) => {
  return (
    <div className="shadow-sm-2 rounded-10 bg-secondary">
      <Link
        to={path}
        className="p-3 flex flex-column justify-around aic "
        style={{ minHeight: 112 }}
      >
        <div className="p-1 border-dark-sm rounded-circle">
          <div
            className="p-1 bg-dark-2 text-white rounded-circle flex justify-center aic"
            style={{ height: 35, width: 35 }}
          >
            <Icon />
          </div>
        </div>
        <h5 className="text-dark line-height-quickLink text-center">{title}</h5>
      </Link>
    </div>
  );
};

export const PaymentModal = ({ open, onCancel, onOk }) => {
  return (
    <Modal open={open} onCancel={onCancel} onOk={onOk}>
      Payment Modal
    </Modal>
  );
};
