import {
  Breadcrumb,
  Button,
  Col,
  Image,
  Input,
  Row,
  Spin,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getData, postData } from "../../../libs/axios/axiosInstance";
import { IoCloseCircle } from "react-icons/io5";
import ImagePopup from "../../../components/ImagePopup";
import UploadButton from "../../../components/UploadButton";
import useScrollEffect from "../../../components/scroEffect";
import usePageTitle from "../../../components/PageTitle";

const About = () => {
  usePageTitle("About Page");
  const isAtTop = useScrollEffect();
  const [about, setAbout] = useState({
    seo: {
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    },
    page_title: "",
    title: "",
    description: "",
    our_mission_title: "",
    our_mission: "",
    our_mission_image: {},
    our_vision_title: "",
    our_vision: "",
    testimonial_title: "",
    our_vision_image: {},
    speciality_title: "",
    images: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [imagesModal, setImagesModal] = useState(false);
  const [missionImageModal, setMissionImageModal] = useState(false);
  const [visionImageModal, setVisionImageModal] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getData("website/aboutpage/")
      .then((res) => {
        const data = res.data;
        setAbout({
          seo: data.seo,
          page_title: data.page_title,
          title: data.title,
          description: data.description,
          our_mission_title: data.our_mission_title,
          our_mission: data.our_mission,
          our_mission_image: data.our_mission_image,
          our_vision_title: data.our_vision_title,
          our_vision: data.our_vision,
          our_vision_image: data.our_vision_image,
          testimonial_title: data.testimonial_title,
          speciality_title: data.speciality_title,
          images: data.images,
        });

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error getting about", err);
        setIsLoading(false);
      });
  }, []);

  const handleMissionImageUploadSuccess = (newImage) => {
    setAbout((prev) => ({
      ...prev,
      our_mission_image: newImage[0],
    }));
    setMissionImageModal(false);
  };

  const handleVisionImageUploadSuccess = (newImage) => {
    setAbout((prev) => ({
      ...prev,
      our_vision_image: newImage[0],
    }));
    setVisionImageModal(false);
  };

  const handleRemoveImage = (index) => {
    setAbout((prev) => {
      const updatedImages = [...prev.images];
      updatedImages.splice(index, 1);
      return {
        ...prev,
        images: updatedImages,
      };
    });
  };

  const handleImagesUploadSuccess = (newImages) => {
    setAbout((prev) => ({
      ...prev,
      images: [...prev.images, ...newImages],
    }));
    setImagesModal(false);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      seo: about.seo,
      page_title: about.page_title,
      title: about.title,
      description: about.description,
      our_mission_title: about.our_mission_title,
      our_mission: about.our_mission,
      our_mission_image: about.our_mission_image.id,
      our_vision_title: about.our_vision_title,
      our_vision: about.our_vision,
      our_vision_image: about.our_vision_image.id,
      testimonial_title: about.testimonial_title,
      speciality_title: about.speciality_title,
      images: about.images.map((item) => item.id),
    };
    const response = await postData("website/aboutpage/", payload);
    console.log(response.data);
    if (response.status === 200) {
      message.success("About page content added successfully!");
    }
    setIsLoading(false);
  }

  return (
    <div>
      <div
        className={`breadcrumb flex-justify-between ${
          !isAtTop ? "sticky shadow-sm-2" : ""
        }`}
      >
        <div>
          <h1 className={`fs-2 text-dark ${!isAtTop ? "lh-1" : ""}`}>
            About Page
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Website" },
              { title: "Pages" },
              { title: "About" },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10 mb-5">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">SEO Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row className="mb-5">
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Meta Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Title"
                        value={about.seo.meta_title}
                        onChange={(e) =>
                          setAbout((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_title: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Meta Descriptions</p>
                      <TextArea
                        className="w-100"
                        placeholder="Meta Descriptions"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        value={about.seo.meta_description}
                        onChange={(e) =>
                          setAbout((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_description: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="">
                      <p className="mb-2 ps-2 fw-6">
                        Meta Keywords (comma separated)
                      </p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Keywords"
                        value={about.seo.meta_keywords}
                        onChange={(e) =>
                          setAbout((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_keywords: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Page Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">General Images</p>
                      <div className="image-preview-grid">
                        {about.images.length > 0
                          ? about.images.map((item, index) => (
                              <div className="relative me-2" key={index}>
                                <Image
                                  height={120}
                                  width={120}
                                  className="object-cover rounded-5"
                                  alt={item.alt_text}
                                  src={item.image}
                                />
                                <Button
                                  className="p-0 multi-image-remove-icon"
                                  onClick={() => handleRemoveImage(index)}
                                >
                                  <IoCloseCircle />
                                </Button>
                              </div>
                            ))
                          : ""}
                        {about.images.length < 3 ? (
                          <UploadButton
                            onClick={() => setImagesModal(true)}
                            height={120}
                            width={120}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Page Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Page Title"
                        value={about.page_title}
                        onChange={(e) =>
                          setAbout((prev) => ({
                            ...prev,
                            page_title: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Title"
                        value={about.title}
                        onChange={(e) =>
                          setAbout((prev) => ({
                            ...prev,
                            title: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Description</p>
                      <TextArea
                        placeholder="Description"
                        required
                        autoSize={{ minRows: 3, maxRows: 6 }}
                        value={about.description}
                        onChange={(e) =>
                          setAbout((prev) => ({
                            ...prev,
                            description: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Our Mission Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Our Mission Title"
                        required
                        value={about.our_mission_title}
                        onChange={(e) =>
                          setAbout((prev) => ({
                            ...prev,
                            our_mission_title: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Our Mission</p>
                      <TextArea
                        placeholder="Our Mission"
                        required
                        autoSize={{ minRows: 3, maxRows: 6 }}
                        value={about.our_mission}
                        onChange={(e) =>
                          setAbout((prev) => ({
                            ...prev,
                            our_mission: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Our Mission Image</p>
                      <div className="image-preview-grid">
                        {about.our_mission_image &&
                        Object.keys(about.our_mission_image).length > 0 ? (
                          <Image
                            height={100}
                            width={100}
                            className="object-cover rounded-5"
                            alt={about.our_mission_image.alt_text}
                            src={about.our_mission_image.image}
                          />
                        ) : (
                          ""
                        )}
                        <UploadButton
                          onClick={() => setMissionImageModal(true)}
                          height={100}
                          width={100}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Our Vision Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Our Vision Title"
                        required
                        value={about.our_vision_title}
                        onChange={(e) =>
                          setAbout((prev) => ({
                            ...prev,
                            our_vision_title: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Our Vision</p>
                      <TextArea
                        placeholder="Our Vision"
                        required
                        autoSize={{ minRows: 3, maxRows: 6 }}
                        value={about.our_vision}
                        onChange={(e) =>
                          setAbout((prev) => ({
                            ...prev,
                            our_vision: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Our Vision Image</p>
                      <div className="image-preview-grid">
                        {about.our_vision_image &&
                        Object.keys(about.our_vision_image).length > 0 ? (
                          <Image
                            height={100}
                            width={100}
                            className="object-cover rounded-5"
                            alt={about.our_vision_image.alt_text}
                            src={about.our_vision_image.image}
                          />
                        ) : (
                          ""
                        )}
                        <UploadButton
                          onClick={() => setVisionImageModal(true)}
                          height={100}
                          width={100}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Testimonial Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Testimonial Title"
                        required
                        value={about.testimonial_title}
                        onChange={(e) =>
                          setAbout((prev) => ({
                            ...prev,
                            testimonial_title: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Speciality Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Speciality Title"
                        required
                        value={about.speciality_title}
                        onChange={(e) =>
                          setAbout((prev) => ({
                            ...prev,
                            speciality_title: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className="w-100 my-5"
                      loading={isLoading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
      {imagesModal && (
        <ImagePopup
          imgLimit={3}
          existingImgs={about.images}
          open={imagesModal}
          onCancel={() => setImagesModal(false)}
          onUploadSuccess={handleImagesUploadSuccess}
          cropAspectRatio={16 / 9}
          imageTitle={about.page_title}
        />
      )}
      {missionImageModal && (
        <ImagePopup
          imgLimit={1}
          existingImgs={[about.our_mission_image]}
          open={missionImageModal}
          onCancel={() => setMissionImageModal(false)}
          onUploadSuccess={handleMissionImageUploadSuccess}
          cropAspectRatio={16 / 9}
          imageTitle={`${about.page_title} - ${about.our_mission_title}`}
        />
      )}
      {visionImageModal && (
        <ImagePopup
          imgLimit={1}
          existingImgs={[about.our_vision_image]}
          open={visionImageModal}
          onCancel={() => setVisionImageModal(false)}
          onUploadSuccess={handleVisionImageUploadSuccess}
          cropAspectRatio={16 / 9}
          imageTitle={`${about.page_title} - ${about.our_vision_title}`}
        />
      )}
    </div>
  );
};

export default About;
