import React, { useEffect, useState } from "react";
import usePageTitle from "../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchUsers } from "../../libs/redux/features/users/usersSlice";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  roleData,
} from "../../components/utility";
import { Link } from "react-router-dom";
import BreadBtn from "../../components/BreadBtn";
import DataConversion from "../../components/DataConversion";
import { Button, Image, Pagination, Select, Table, Tag } from "antd";

const { Option } = Select;

const Users = () => {
  usePageTitle("Users");

  const breadcrumbitems = [
    {
      title: "Users",
    },
  ];

  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.users.data);
  const totalItems = useSelector((state) => state.users.totalItems);
  const isLoading = useSelector((state) => state.users.isLoading);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    role: "all",
    gender: "all",
    is_approved: "all",
    is_staff: "all",
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.role !== "all") {
      params.role = filters.role;
    }
    if (filters.gender !== "all") {
      params.gender = filters.gender;
    }
    if (filters.is_approved !== "all") {
      params.is_approved = filters.is_approved;
    }
    if (filters.is_staff !== "all") {
      params.is_staff = filters.is_staff;
    }
    dispatch(fetchUsers(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Name: `${item.first_name} ${item.last_name}` || "N/A",
    Dob: item.dob || "N/A",
    Email: item.email || "N/A",
    Mobile: item.mobile || "N/A",
    Role:
      item.role === 0
        ? "Admin"
        : item.role === 1
        ? "Teacher"
        : item.role === 2
        ? "Guest"
        : "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Name", "Dob", "Email", "Mobile", "Role"];
    downloadPDF(usersData, columns, formatTimeSlotsData, "Users Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(usersData, formatTimeSlotsData, "Users Data");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Name",
      dataIndex: "user",
      render: (_, user) => (
        <div className="flex aic">
          {(user.image && (
            <Image
              src={user.image.image}
              alt={user.image.alt_text}
              style={{
                width: 50,
                height: 50,
                borderRadius: 10,
                marginRight: 10,
                objectFit: "cover",
              }}
            />
          )) ||
            ""}

          <span>{`${user.first_name} ${user.last_name}`}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
    },

    {
      title: "Mobile",
      dataIndex: "mobile",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role) =>
        role === 0 ? (
          <Tag color="#108ee9">Admin</Tag>
        ) : role === 1 ? (
          <Tag color="#2db7f5">Teacher</Tag>
        ) : role === 2 ? (
          <Tag color="#87d068">Student</Tag>
        ) : (
          <Tag color="geekblue">Office Staff</Tag>
        ),
    },
    {
      title: "Approval",
      dataIndex: "is_approved",
      render: (isApproved) =>
        isApproved ? (
          <Tag color="#108ee9">Approved</Tag>
        ) : (
          <Tag color="#f50">Rejected</Tag>
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/users/${record.id}`}>
            <Button className="me-4 p-2">
              <EyeOutlined />
            </Button>
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.first_name} ${record.last_name}`,
                dispatch,
                `auth/admin/users/${record.id}/`,
                fetchUsers
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <BreadBtn
        headerTitle="Users"
        addPath="/users/add-user"
        items={breadcrumbitems}
      />
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("role", value)}
            >
              <Option value="all">All Roles</Option>
              {roleData &&
                roleData.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.title}
                  </Option>
                ))}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("gender", value)}
            >
              <Option value="all">All Gender</Option>
              <Option value={0}>Male</Option>
              <Option value={1}>Female</Option>
              <Option value={2}>Other</Option>
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("is_staff", value)}
            >
              <Option value="all">Filter by Staff</Option>
              <Option value={true}>Is Staff</Option>
              <Option value={false}>Not Staff</Option>
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("is_approved", value)}
            >
              <Option value="all">Filter by Approval</Option>
              <Option value={true}>Approved</Option>
              <Option value={false}>Rejected</Option>
            </Select>
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={usersData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Users;
