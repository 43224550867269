import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import useScrollEffect from "../../../components/scroEffect";
import { getData, postData } from "../../../libs/axios/axiosInstance";
import { Breadcrumb, Button, Col, Image, Input, message, Row } from "antd";
import { Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import ImagePopup from "../../../components/ImagePopup";
import UploadButton from "../../../components/UploadButton";

const GloballDataSetting = () => {
  usePageTitle("Global Setting");
  const isAtTop = useScrollEffect();

  const [globalSetting, setGlobalSetting] = useState({
    site_name: "",
    tagline: "",
    website_url: "",
    email: "",
    phone: "",
    address: "",
    short_description: "",
    long_description: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    number_of_students: 1,
    number_of_teacher: 1,
    number_of_programs: 1,
    logo: {},
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const fetchGlobalSetting = async () => {
    setIsLoading(true);
    try {
      const response = await getData("utility/admin/global-settings/");
      if (response.status === 200) {
        const data = response.data;
        setGlobalSetting({
          site_name: data.site_name || "",
          tagline: data.tagline || "",
          website_url: data.website_url || "",
          email: data.email || "",
          phone: data.phone || "",
          address: data.address || "",
          short_description: data.short_description || "",
          long_description: data.long_description || "",
          facebook: data.facebook || "",
          twitter: data.twitter || "",
          linkedin: data.linkedin || "",
          instagram: data.instagram || "",
          youtube: data.youtube || "",
          number_of_students: data.number_of_students || 1,
          number_of_teacher: data.number_of_teacher || 1,
          number_of_programs: data.number_of_programs || 1,
          logo: data.logo || {},
        });
        setIsLoading(false);
      }
    } catch (err) {
      setError(err.response.data);
      console.log("error getting global settings", err.response);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGlobalSetting();
  }, []);

  const [featuredImageModal, setFeaturedImageModal] = useState(false);

  const handleImageUploadSuccess = (newImg) => {
    setGlobalSetting((prev) => ({
      ...prev,
      logo: newImg[0],
    }));
    setFeaturedImageModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const payload = {
        ...globalSetting,
        logo: globalSetting.logo.id,
      };
      const response = await postData(
        "utility/admin/global-settings/",
        payload
      );

      if (response.status === 200) {
        message.success("Global settings updated successfully!");
        setIsLoading(false);
        fetchGlobalSetting();
      }
    } catch (err) {
      console.log("error posting global setting", err.response);
      setError(err.response.data);
    }
  };
  return (
    <div>
      <div
        className={`breadcrumb flex-justify-between ${
          !isAtTop ? "sticky" : ""
        }`}
      >
        <div>
          <h1 className={`fs-2 text-dark ${!isAtTop ? "lh-1" : ""}`}>
            Global Setting
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Website" },
              { title: "settings" },
              { title: "Global Setting" },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <div className="bg-white rounded-10">
            <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
              <h2 className="fs-4">Global Settings Information</h2>
            </div>
            <div className="p-4" style={{ paddingTop: 10 }}>
              <Row>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Site Name</p>
                      {error && error.site_name ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.site_name})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Site Name"
                      required
                      value={globalSetting.site_name}
                      onChange={(e) =>
                        setGlobalSetting((prev) => ({
                          ...prev,
                          site_name: e.target.value,
                        }))
                      }
                      status={error && error.site_name ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Tag Line</p>
                      {error && error.tagline ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.tagline})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Tag Line"
                      required
                      value={globalSetting.tagline}
                      onChange={(e) =>
                        setGlobalSetting((prev) => ({
                          ...prev,
                          tagline: e.target.value,
                        }))
                      }
                      status={error && error.tagline ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Website URL</p>
                      {error && error.website_url ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.website_url})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Website URL"
                      required
                      value={globalSetting.website_url}
                      onChange={(e) =>
                        setGlobalSetting((prev) => ({
                          ...prev,
                          website_url: e.target.value,
                        }))
                      }
                      status={error && error.website_url ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Email</p>
                      {error && error.email ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.email})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Email"
                      required
                      value={globalSetting.email}
                      onChange={(e) =>
                        setGlobalSetting((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }
                      status={error && error.email ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Phone</p>
                      {error && error.phone ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.phone})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Phone"
                      required
                      value={globalSetting.phone}
                      onChange={(e) =>
                        setGlobalSetting((prev) => ({
                          ...prev,
                          phone: e.target.value,
                        }))
                      }
                      status={error && error.phone ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Address</p>
                      {error && error.address ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.address})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Address"
                      required
                      value={globalSetting.address}
                      onChange={(e) =>
                        setGlobalSetting((prev) => ({
                          ...prev,
                          address: e.target.value,
                        }))
                      }
                      status={error && error.address ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Short Description</p>
                      {error && error.short_description ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.short_description})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <TextArea
                      className="w-100"
                      autoSize={{ minRows: 2, maxRows: 4 }}
                      placeholder="Short Description"
                      required
                      value={globalSetting.short_description}
                      onChange={(e) =>
                        setGlobalSetting((prev) => ({
                          ...prev,
                          short_description: e.target.value,
                        }))
                      }
                      status={error && error.short_description ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Long Description</p>
                      {error && error.long_description ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.long_description})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <TextArea
                      className="w-100"
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder="Long Description"
                      required
                      value={globalSetting.long_description}
                      onChange={(e) =>
                        setGlobalSetting((prev) => ({
                          ...prev,
                          long_description: e.target.value,
                        }))
                      }
                      status={error && error.long_description ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Number of Students</p>
                      {error && error.number_of_students ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.number_of_students})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Number of Students"
                      required
                      type="number"
                      value={globalSetting.number_of_students}
                      onChange={(e) =>
                        setGlobalSetting((prev) => ({
                          ...prev,
                          number_of_students: e.target.value,
                        }))
                      }
                      status={error && error.number_of_students ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Number of Teachers</p>
                      {error && error.number_of_teacher ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.number_of_teacher})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Number of Teachers"
                      required
                      type="number"
                      value={globalSetting.number_of_teacher}
                      onChange={(e) =>
                        setGlobalSetting((prev) => ({
                          ...prev,
                          number_of_teacher: e.target.value,
                        }))
                      }
                      status={error && error.number_of_teacher ? "error" : ""}
                    />
                  </div>
                </Col>

                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Number of Programs</p>
                      {error && error.number_of_programs ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.number_of_programs})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Number of Programs"
                      required
                      type="number"
                      value={globalSetting.number_of_programs}
                      onChange={(e) =>
                        setGlobalSetting((prev) => ({
                          ...prev,
                          number_of_programs: e.target.value,
                        }))
                      }
                      status={error && error.number_of_programs ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Facebook</p>
                      {error && error.facebook ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.facebook})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Facebook"
                      required
                      value={globalSetting.facebook}
                      onChange={(e) =>
                        setGlobalSetting((prev) => ({
                          ...prev,
                          facebook: e.target.value,
                        }))
                      }
                      status={error && error.facebook ? "error" : ""}
                    />
                  </div>
                </Col>

                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Twitter</p>
                      {error && error.twitter ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.twitter})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Twitter"
                      required
                      value={globalSetting.twitter}
                      onChange={(e) =>
                        setGlobalSetting((prev) => ({
                          ...prev,
                          twitter: e.target.value,
                        }))
                      }
                      status={error && error.twitter ? "error" : ""}
                    />
                  </div>
                </Col>

                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* LinkedIn</p>
                      {error && error.linkedin ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.linkedin})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="LinkedIn"
                      required
                      value={globalSetting.linkedin}
                      onChange={(e) =>
                        setGlobalSetting((prev) => ({
                          ...prev,
                          linkedin: e.target.value,
                        }))
                      }
                      status={error && error.linkedin ? "error" : ""}
                    />
                  </div>
                </Col>

                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Instagram</p>
                      {error && error.instagram ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.instagram})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Instagram"
                      required
                      value={globalSetting.instagram}
                      onChange={(e) =>
                        setGlobalSetting((prev) => ({
                          ...prev,
                          instagram: e.target.value,
                        }))
                      }
                      status={error && error.instagram ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">Logo</p>
                    <div className="image-preview-grid">
                      {globalSetting.logo &&
                      Object.keys(globalSetting.logo).length > 0 ? (
                        <Image
                          height={100}
                          width={100}
                          className="object-cover rounded-5"
                          alt={globalSetting.logo.alt_text}
                          src={globalSetting.logo.image}
                        />
                      ) : (
                        ""
                      )}
                      <UploadButton
                        onClick={() => setFeaturedImageModal(true)}
                        height={100}
                        width={100}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="w-100 my-5"
                    loading={isLoading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </div>
      {featuredImageModal && (
        <ImagePopup
          open={featuredImageModal}
          onCancel={() => setFeaturedImageModal(false)}
          onUploadSuccess={handleImageUploadSuccess}
          cropAspectRatio={16 / 9}
        />
      )}
    </div>
  );
};

export default GloballDataSetting;
