import { Button, Col, Empty, Input, message, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchStudents } from "../../../libs/redux/features/student-info/studentsSlice";
import { fetchAll as fetchExams } from "../../../libs/redux/features/exams-features/examsSlice";
import { IoIosClose } from "react-icons/io";
import { Link } from "react-router-dom";
import { postData } from "../../../libs/axios/axiosInstance";

const { Option } = Select;

const ExamResultBulkUpload = ({ open, onCancel }) => {
  const [modalData, setModalData] = useState({
    exam: null,
    results: [],
  });

  const dispatch = useDispatch();
  const examsData = useSelector((state) => state.exams.data);
  const studentsData = useSelector((state) => state.students.data);

  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // Fetch exams when component mounts
  useEffect(() => {
    if (examsData.length === 0) {
      dispatch(fetchExams());
    }
  }, [examsData.length, dispatch]);
  console.log("students data", studentsData);

  // Fetch students based on selected exam's class_level
  useEffect(() => {
    if (modalData.exam) {
      const selectedExam = examsData.find((exam) => exam.id === modalData.exam);
      console.log("selected exam", selectedExam);

      if (selectedExam && selectedExam.class_level) {
        dispatch(fetchStudents({ school_class: selectedExam.class_level }));
      }
    }
  }, [modalData.exam, examsData, dispatch]);

  // Update results array based on fetched students data
  useEffect(() => {
    if (studentsData.length > 0 && modalData.exam) {
      setModalData((prev) => ({
        ...prev,
        results: studentsData.map((student) => ({
          student: student.id,
          marks_obtained: "",
        })),
      }));
    } else {
      setModalData((prev) => ({
        ...prev,
        results: [],
      }));
    }
  }, [studentsData, modalData.exam]);

  // Handle marks input change
  const handleMarksChange = (index, value) => {
    const updatedResults = [...modalData.results];
    updatedResults[index].marks_obtained = value;
    setModalData((prev) => ({ ...prev, results: updatedResults }));
  };

  // Handle modal cancel
  const handleModalCancel = () => {
    onCancel();
    setModalData({
      exam: null,
      results: [],
    });
  };

  const handleRemoveResult = (examIndex) => {
    if (modalData.results.length > 1) {
      setModalData((prevData) => ({
        ...prevData,
        results: prevData.results.filter((_, index) => index !== examIndex),
      }));
    } else {
      message.warning("At least one result is required.");
    }
  };

  const handleModalOk = async () => {
    setIsLoading(true);
    console.log("payload", modalData);

    try {
      const response = await postData(
        "exam/admin/exam-results/bulk-create/",
        modalData
      );
      if (response.status === 201) {
        message.success("Results added successfully");
        setError({});
        onCancel();
      }
    } catch (error) {
      if (error.response?.data) {
        const errorData = error.response.data;
        message.error(
          errorData.non_field_errors?.[0] || "Error uploading exam data!"
        );
        // Assuming errorData contains field-specific errors
        setError(errorData);
      } else {
        message.error("An unexpected error occurred.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  console.log("modal data", modalData);

  return (
    <Modal
      open={open}
      onCancel={handleModalCancel}
      title={<h2 className="fs-4 mb-3">Exam Result Bulk Upload Form</h2>}
      footer={[
        <Button key="cancel" onClick={handleModalCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleModalOk}
          disabled={modalData.results && modalData.results.length === 0}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      {/* Exam Selector */}
      <div className="mb-3">
        <Select
          showSearch
          className="w-100"
          placeholder="Select Course"
          optionFilterProp="children"
          value={modalData.exam || null}
          onChange={(value) =>
            setModalData((prev) => ({
              ...prev,
              exam: value,
            }))
          }
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          allowClear
          onClear={() =>
            setModalData((prev) => ({
              ...prev,
              exam: null,
              results: [],
            }))
          }
          status={error && error.exam ? "error" : ""}
        >
          {examsData &&
            examsData.length > 0 &&
            examsData.map((exam) => (
              <Option value={exam.id} key={exam.id}>
                {exam.name}
              </Option>
            ))}
        </Select>
      </div>

      {/* Results Section */}
      {modalData.results && modalData.results.length > 0 ? (
        modalData.results.map((item, index) => {
          const student = studentsData.find((s) => s.id === item.student);
          return (
            <div className="border rounded-5 py-2 mb-2" key={index}>
              <Row className="responsive-row">
                {/* Student Name */}
                <Col span={10}>
                  <Input
                    disabled
                    placeholder="Student Name"
                    value={
                      student
                        ? `${student.user.first_name} ${student.user.last_name}`
                        : ""
                    }
                  />
                </Col>

                {/* Marks Obtained */}
                <Col span={12}>
                  <Input
                    placeholder="Marks Obtained"
                    value={item.marks_obtained}
                    onChange={(e) => handleMarksChange(index, e.target.value)}
                  />
                </Col>

                {/* Remove Button */}
                <Col span={2}>
                  <Button
                    className="p-0"
                    onClick={() => handleRemoveResult(index)}
                    danger
                    style={{
                      height: 20,
                      width: 20,
                      borderRadius: 4,
                    }}
                  >
                    <IoIosClose />
                  </Button>
                </Col>
              </Row>
            </div>
          );
        })
      ) : (
        <div className="w-100 d-flex aic justify-center">
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: 60 }}
            description={<div>No students for the selected exam</div>}
          >
            <Link type="primary" to="/student-data/students/add-student">
              <Button type="primary">Create Now</Button>
            </Link>
          </Empty>
        </div>
      )}
    </Modal>
  );
};

export default ExamResultBulkUpload;
