import React, { useEffect, useState } from "react";
import usePageTitle from "../../components/PageTitle";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingle,
  postData,
  updateData,
} from "../../libs/redux/features/users/usersSlice";
import {
  Button,
  Col,
  DatePicker,
  Image,
  Input,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import BreadBtn from "../../components/BreadBtn";
import dayjs from "dayjs";
import UploadButton from "../../components/UploadButton";
import ImagePopup from "../../components/ImagePopup";
import { roleData } from "../../components/utility";

const { Option } = Select;

const UsersForm = () => {
  const breadcrumbitems = [
    { title: <Link to="/users">Users</Link> },
    { title: "User Form" },
  ];

  usePageTitle("User Form");
  const { id } = useParams();

  const dispatch = useDispatch();
  const singleData = useSelector((state) => state.users.singleData);
  const error = useSelector((state) => state.users.error);
  const isLoading = useSelector((state) => state.users.isLoading);

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    image: {},
    dob: "",
    role: 0,
    bio: "",
    email: "",
    mobile: "",
    password: "",
    password_confirm: "",
    gender: 0,
  });

  useEffect(() => {
    if (id) {
      dispatch(fetchSingle(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setUser({
        first_name: singleData.first_name,
        last_name: singleData.last_name,
        image: singleData.image,
        role: singleData.role,
        dob: singleData.dob,
        bio: singleData.bio,
        email: singleData.email,
        mobile: singleData.mobile,
        gender: singleData.gender,
      });
    }
  }, [id, singleData]);

  const [userImageModal, setUserImageModal] = useState(false);

  const onUserChange = (key, value) => {
    setUser((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleImageUploadSuccess = (newImage) => {
    setUser((prev) => ({
      ...prev,
      image: newImage[0],
    }));
    setUserImageModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      ...user,
      image: user.image.id,
    };

    if (id) {
      dispatch(updateData({ id, payload }));
    } else {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New user added successfully!");
            setUser({
              first_name: "",
              last_name: "",
              image: {},
              dob: "",
              bio: "",
              role: 0,
              email: "",
              mobile: "",
              password: "",
              password_confirm: "",
              gender: 0,
            });
          },
        })
      );
    }
  };
  return (
    <div>
      <BreadBtn headerTitle="User Form" items={breadcrumbitems} />
      <div className="page-content-section bg-white rounded-10">
        <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
          <h2 className="fs-4">User Information</h2>
        </div>
        <Spin spinning={isLoading}>
          <div className="p-4" style={{ paddingTop: 10 }}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* First Name</p>
                      {error && error.first_name ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.first_name})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <Input
                      className="w-100"
                      size="large"
                      placeholder="First Name"
                      value={user.first_name}
                      onChange={(e) =>
                        onUserChange("first_name", e.target.value)
                      }
                      required
                      status={error && error.first_name ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Last Name</p>
                      {error && error.last_name ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.last_name})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Last Name"
                      value={user.last_name}
                      onChange={(e) =>
                        onUserChange("last_name", e.target.value)
                      }
                      required
                      status={error && error.last_name ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">* DOB</p>
                    <DatePicker
                      size="large"
                      className="w-100 rounded-10"
                      onChange={(value) =>
                        onUserChange(
                          "dob",
                          value ? dayjs(value).format("YYYY-MM-DD") : null
                        )
                      }
                      format="YYYY-MM-DD"
                      value={user.dob ? dayjs(user.dob, "YYYY-MM-DD") : null}
                      placeholder="Select DOB"
                      required
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Email</p>
                      {error && error.email ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.email})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      type="email"
                      size="large"
                      placeholder="Email"
                      value={user.email}
                      onChange={(e) => onUserChange("email", e.target.value)}
                      required
                      status={error && error.email ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Mobile</p>
                      {error && error.mobile ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.mobile})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Mobile"
                      value={user.mobile}
                      onChange={(e) => onUserChange("mobile", e.target.value)}
                      required
                      status={error && error.mobile ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">* Role</p>
                    <Select
                      size="large"
                      className="w-100"
                      value={user.role}
                      onChange={(value) => onUserChange("role", value)}
                    >
                      {roleData.map((item) => (
                        <Option key={item.title} value={item.value}>
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">* Bio</p>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Bio"
                      value={user.bio}
                      onChange={(e) => onUserChange("bio", e.target.value)}
                      required
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Password</p>
                      {error && error.password ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.password})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Password"
                      value={user.password}
                      onChange={(e) => onUserChange("password", e.target.value)}
                      required
                      status={error && error.password ? "error" : ""}
                      disabled={id}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Confirm Password</p>
                      {error && error.password_confirm ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.password_confirm})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Confirm Password"
                      value={user.password_confirm}
                      onChange={(e) =>
                        onUserChange("password_confirm", e.target.value)
                      }
                      required
                      disabled={id}
                      status={error && error.password_confirm ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">* Gender</p>
                    <Select
                      size="large"
                      className="w-100"
                      value={user.gender}
                      onChange={(value) => onUserChange("gender", value)}
                    >
                      <Option value={0}>Male</Option>
                      <Option value={1}>Female</Option>
                      <Option value={2}>Other</Option>
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* User Image</p>
                      {error && error.image ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.image})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="image-preview-grid">
                      {user.image && Object.keys(user.image).length > 0 ? (
                        <Image
                          height={100}
                          width={100}
                          className="object-cover rounded-5"
                          alt={user.image.alt_text}
                          src={user.image.image}
                        />
                      ) : (
                        ""
                      )}
                      <UploadButton
                        danger={error && error.image ? true : false}
                        onClick={() => setUserImageModal(true)}
                        height={100}
                        width={100}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="w-100"
              >
                Submit
              </Button>
            </form>
          </div>
        </Spin>
      </div>
      {/* image modal */}
      {userImageModal && (
        <ImagePopup
          open={userImageModal}
          onCancel={() => setUserImageModal(false)}
          onUploadSuccess={handleImageUploadSuccess}
        />
      )}
    </div>
  );
};

export default UsersForm;
