import {
  Breadcrumb,
  Button,
  Col,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchStudents } from "../../../libs/redux/features/student-info/studentsSlice";
import { fetchAll as fetchExams } from "../../../libs/redux/features/exams-features/examsSlice";
import {
  fetchAll as fetchExamResults,
  postData,
  updateData,
} from "../../../libs/redux/features/exams-features/examResultsSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../../components/utility";
import {
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import usePageTitle from "../../../components/PageTitle";
import DataConversion from "../../../components/DataConversion";
import ExamResultBulkUpload from "./ExamResultBulkUpload";
import { Link } from "react-router-dom";

const { Option } = Select;

const ExamResults = () => {
  usePageTitle("Exam Results");

  const breadcrumbitems = [
    {
      title: (
        <Link to="/">
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: "Exam Data",
    },
    { title: "Exam Results" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const examResultsData = useSelector((state) => state.examResults.data);
  const totalItems = useSelector((state) => state.examResults.totalItems);
  const isLoading = useSelector((state) => state.examResults.isLoading);
  const error = useSelector((state) => state.examResults.error);

  const examsData = useSelector((state) => state.exams.data);
  const studentsData = useSelector((state) => state.students.data);

  useEffect(() => {
    if (examsData.length === 0) {
      dispatch(fetchExams());
    }
    if (studentsData.length === 0) {
      dispatch(fetchStudents());
    }
  }, [examsData.length, studentsData.length, dispatch]);

  const [modalData, setModalData] = useState({
    exam: null,
    student: null,
    marks_obtained: "",
  });

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };

    dispatch(fetchExamResults(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Exam: item.exam || "N/A",
    Grade: item.grade_letter || "N/A",
    Mark: item.marks_obtained || "N/A",
    Status: item.is_failed && item.is_failed ? "Passed" : "Failed" || "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Exam", "Grade", "Mark", "Status"];
    downloadPDF(
      examResultsData,
      columns,
      formatTimeSlotsData,
      "Exam Result Data"
    );
  };

  const handleDownloadXLS = () => {
    downloadXLS(examResultsData, formatTimeSlotsData, "Exam Result Data");
  };

  const [id, setId] = useState(null);

  const fetchSingleItem = (data) => {
    setModalData({
      exam: data.exam,
      student: data.student,
      marks_obtained: data.marks_obtained,
    });
    setId(data.id);
    setAddModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Student",
      dataIndex: "student",
      render: (student) => {
        const studentDetail =
          studentsData && studentsData.find((item) => item.id === student);
        return studentDetail
          ? `${studentDetail.user.first_name} ${studentDetail.user.last_name}`
          : "Unknown";
      },
    },
    {
      title: "Exam",
      dataIndex: "exam",
      render: (exam) =>
        examsData && examsData.find((item) => item.id === exam)?.name,
    },
    {
      title: "Grade",
      dataIndex: "grade_letter",
    },
    {
      title: "Marks",
      dataIndex: "marks_obtained",
    },
    {
      title: "Status",
      dataIndex: "is_failed",
      render: (status) => (status ? "Failed" : "Passed"),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Button className="me-4 p-2" onClick={() => fetchSingleItem(record)}>
            <EyeOutlined />
          </Button>
          <div
            onClick={() =>
              handleDelete(
                `result for ${record.student}`,
                dispatch,
                `exam/admin/exam-results/${record.id}/`,
                fetchExamResults,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handleModalCancel = () => {
    setAddModal(false);
    if (id) {
      setId(null);
    }
    setModalData({
      exam: "",
      student: "",
      marks_obtained: "",
    });
  };

  const handleModalOk = () => {
    const payload = modalData;
    if (!id) {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New Exam Result added successfully!");
            setModalData({
              exam: "",
              student: "",
              marks_obtained: "",
            });
            setAddModal(false);
          },
        })
      );
    } else {
      dispatch(updateData({ id, payload }));
    }
  };

  const [bulkModal, setBulkModal] = useState(false);

  const bulkModalClick = () => {
    setBulkModal(true);
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Exam Results</h1>
          <Breadcrumb className="fs-5" items={breadcrumbitems} />
        </div>
        <div>
          <Button
            icon={<PlusOutlined />}
            className="me-3"
            size="large"
            onClick={bulkModalClick}
          >
            Bulk create
          </Button>
          <Button
            onClick={() => setAddModal(true)}
            type="primary"
            icon={<PlusOutlined />}
            size="large"
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3">
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>

        <Table
          columns={columns}
          dataSource={examResultsData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* add and edit data modal */}
      {addModal && (
        <Modal
          title={<h2 className="fs-2 mb-3">Exam Results</h2>}
          open={addModal}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={isLoading}
              loading={isLoading}
            >
              {id ? "Update" : "Submit"}
            </Button>,
          ]}
          onCancel={handleModalCancel}
        >
          <Row className="responsive-row">
            <Col span={24}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Student</p>
                  {error && error.student ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.student})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Select
                  showSearch
                  className="w-100"
                  size="large"
                  placeholder="Select Student"
                  value={modalData.student || null}
                  onChange={(value) =>
                    setModalData((prev) => ({
                      ...prev,
                      student: value,
                    }))
                  }
                  optionFilterProp="children"
                  allowClear
                  onClear={() =>
                    setModalData((prev) => ({
                      ...prev,
                      student: null,
                    }))
                  }
                  status={error && error.student ? "error" : ""}
                >
                  {studentsData && studentsData.length > 0
                    ? studentsData.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.user.first_name} {item.user.last_name}
                        </Option>
                      ))
                    : ""}
                </Select>
              </div>
            </Col>
            <Col span={24}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Exam</p>
                  {error && error.exam ? (
                    <p className="text-sm text-danger ms-3">({error.exam})</p>
                  ) : (
                    ""
                  )}
                </div>
                <Select
                  showSearch
                  className="w-100"
                  size="large"
                  placeholder="Select Exam"
                  value={modalData.exam || null}
                  onChange={(value) =>
                    setModalData((prev) => ({
                      ...prev,
                      exam: value,
                    }))
                  }
                  optionFilterProp="children"
                  allowClear
                  onClear={() =>
                    setModalData((prev) => ({
                      ...prev,
                      exam: null,
                    }))
                  }
                  status={error && error.exam ? "error" : ""}
                >
                  {examsData && examsData.length > 0
                    ? examsData.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))
                    : ""}
                </Select>
              </div>
            </Col>
            <Col span={24}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Marks Obtained</p>
                  {error && error.marks_obtained ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.marks_obtained})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Input
                  className="w-100"
                  size="large"
                  placeholder="Total Marks"
                  required
                  type="number"
                  value={modalData.marks_obtained}
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      marks_obtained: e.target.value,
                    }))
                  }
                  status={error && error.marks_obtained ? "error" : ""}
                />
              </div>
            </Col>
          </Row>
        </Modal>
      )}

      {bulkModal && (
        <ExamResultBulkUpload
          open={bulkModal}
          onCancel={() => setBulkModal(false)}
        />
      )}
    </div>
  );
};

export default ExamResults;
