import React from "react";

const PageCard = ({ title, content }) => {
  return (
    <div className="bg-white rounded-10">
      <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
        <h2 className="fs-4">{title}</h2>
      </div>
      <div className="p-4" style={{ paddingTop: 10 }}>
        {content}
      </div>
    </div>
  );
};

export default PageCard;
