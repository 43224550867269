import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll } from "../../../libs/redux/features/student-info/promotionRecordsSlice";
import { downloadPDF, downloadXLS } from "../../../components/utility";
import BreadBtn from "../../../components/BreadBtn";
import DataConversion from "../../../components/DataConversion";
import { Input, Pagination, Select, Table } from "antd";

const { Option } = Select;
const { Search } = Input;

const PromotionRecord = () => {
  usePageTitle("Promotion Records");

  const breadcrumbitems = [
    {
      title: "Student Data",
    },
    { title: "Promotion Records" },
  ];

  const dispatch = useDispatch();

  const records = useSelector((state) => state.promotionRecords.data);
  const isLoading = useSelector((state) => state.promotionRecords.isLoading);
  const totalItems = useSelector((state) => state.promotionRecords.totalItems);

  const classesData = useSelector((state) => state.classesNew.data);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
  }, [dispatch, classesData.length]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    from_class: null,
    to_class: null,
    search: "",
  });

  useEffect(() => {
    const params = {
      limit: filters.limit,
      offset: filters.offset,
    };

    if (filters.from_class !== null) {
      params.from_class = filters.from_class;
    }
    if (filters.to_class !== null) {
      params.to_class = filters.to_class;
    }
    if (filters.search !== "") {
      params.search = filters.search;
    }
    dispatch(fetchAll(params));
  }, [filters, dispatch]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Student: item.student || "N/A",
    "From Class": item.from_class || "N/A",
    "To Class": item.to_class || "N/A",
    "From Section": item.from_section || "N/A",
    "To Section": item.to_section || "N/A",
    "Promoted by": item.promoted_by || "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Student",
      "From Class",
      "To Class",
      "From Section",
      "To Section",
      "Promoted by",
    ];
    downloadPDF(records, columns, formatTimeSlotsData, "promotion records");
  };

  const handleDownloadXLS = () => {
    downloadXLS(records, formatTimeSlotsData, "promotion records");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Student",
      dataIndex: "student",
    },
    {
      title: "From Class",
      dataIndex: "from_class",
      render: (classId) =>
        classesData && classesData.find((item) => item.id === classId)?.name,
    },
    {
      title: "To Class",
      dataIndex: "to_class",
      render: (classId) =>
        classesData && classesData.find((item) => item.id === classId)?.name,
    },
    {
      title: "From Section",
      dataIndex: "from_section",
    },
    {
      title: "To Section",
      dataIndex: "to_section",
    },
    {
      title: "Promoted by",
      dataIndex: "promoted_by",
    },
  ];

  return (
    <div>
      <BreadBtn
        headerTitle="Promotion Records"
        items={breadcrumbitems}
        addPath="/student-data/promotion-records/new-promotion"
        addTitle="New Promotion"
      />
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search..."
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
            />
            <Select
              showSearch
              className="w-200"
              placeholder="Select From Class"
              optionFilterProp="children"
              value={filters.from_class || null}
              onChange={(value) => handleFilterChange("from_class", value)}
              allowClear
              onClear={() => handleFilterChange("from_class", null)}
            >
              {classesData &&
                classesData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
            <Select
              showSearch
              className="w-200"
              placeholder="Select From Class"
              optionFilterProp="children"
              value={filters.to_class || null}
              onChange={(value) => handleFilterChange("to_class", value)}
              allowClear
              onClear={() => handleFilterChange("to_class", null)}
            >
              {classesData &&
                classesData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </div>

          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={records}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = (filters.offset - 1) * filters.limit + 1;
            const endItem = Math.min(
              filters.offset * filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={filters.offset}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    handleFilterChange("offset", page);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default PromotionRecord;
