import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../features/authentication/authSlice";
import countrySlice from "../features/authentication/countrySlice";
import profileSlice from "../features/profile/profileSlice";
import academicYearSlice from "../features/academic/academicYearSlice";
import gallerySlice from "../features/website/gallerySlice";
import careerSlice from "../features/website/careerSlice";
import eventSlice from "../features/website/eventSlice";
import noticesSlice from "../features/website/noticesSlice";
import facilitieSlice from "../features/website/facilitieSlice";
import specialitySlice from "../features/website/specialitySlice";
import sliderSlice from "../features/website/sliderSlice";
import testimonialSlice from "../features/website/testimonialSlice";
import classSlice from "../features/academic/classSlice";
import departmentsSlice from "../features/teacher-info/departmentsSlice";
import designationsSlice from "../features/teacher-info/designationsSlice";
import teachersSlice from "../features/teacher-info/teachersSlice";
import timeSlotSlice from "../features/academic/timeSlotSlice";
import coursesSlice from "../features/academic/coursesSlice";
// import routinesSlice from "../features/academic/routinesSlice";
// import calendarSlice from "../features/academic/calendarSlice";
import sectionSlice from "../features/academic/sectionSlice";
// import assignmentSlice from "../features/academic/assignmentSlice";
// import assignmentSubmissionSlice from "../features/academic/assignmentSubmissionSlice";
// import homeworksSlice from "../features/academic/homeworksSlice";
import homeworkSubmissionsReducer from "../features/academic/homeworkSubmissionsSlice";
import pagesSlice from "../features/website/pagesSlice";
import teacherAttendanceReducer from "../features/teacher-info/teacherAttendanceSlice";
import studentsReducer from "../features/student-info/studentsSlice";
import studentAttendanceReducer from "../features/student-info/studentAttendanceSlice";
import enrollmentsReducer from "../features/student-info/enrollmentSlice";
import promotionRecordsReducer from "../features/student-info/promotionRecordsSlice";
import onlineClassesReducer from "../features/learning/onlineClassesSlice";
import studyMaterialsReducer from "../features/learning/studyMaterialsSlice";
import examTypesReducer from "../features/exams-features/examTypeSlice";
import gradesReducer from "../features/exams-features/gradesSlice";
import examsReducer from "../features/exams-features/examsSlice";
import finalGradesReducer from "../features/exams-features/finalGradesSlice";
import budgetsReducer from "../features/accounting/budgetSlice";
import expenseTypeReducer from "../features/accounting/expenseTypesSlice";
import expensesReducer from "../features/accounting/espenseSlice";
import feesStructuresReducer from "../features/accounting/feesStructureSlice";
import feesPaymentsReducer from "../features/accounting/feesPaymentSlice";
import ledgersReducer from "../features/accounting/ledgerSlice";
import transactionsReducer from "../features/accounting/transactionSlice";
import usersReducer from "../features/users/usersSlice";
import sectionsNewReducer from "../features/academic/sectionsNewSlice";
import coursesNewReducer from "../features/academic/coursesNewSlice";
import academicYearsNewReducer from "../features/academic/academicYearNewSlice";
import examResultsReducer from "../features/exams-features/examResultsSlice";
import leaveRequestsReducer from "../features/payroll/leaveRequestSlice";
import payrollItemsReducer from "../features/payroll/payrollItemsSlice";
import paySchedulesReducer from "../features/payroll/paySchedulesSlice";
import payrollsReducer from "../features/payroll/payrollsSlice";
import salarySlipsReducer from "../features/payroll/salarySlipsSlice";
import classesNewReducer from "../features/academic/classesNewSlice";
import teachersNewReducer from "../features/teacher-info/teachersNewSlice";
import timeslotsNewReducer from "../features/academic/timeslotsNewSlice";
import routinesNewReducer from "../features/academic/routinesNewSlice";
import calendarsNewReducer from "../features/academic/calendarsNewSlice";
import assignmentsNewReducer from "../features/academic/assignmentsNewSlice";
import assignmentSubmissionsNewReducer from "../features/academic/assignmentSubmissionsNewSlice";
import homeworksNewReducer from "../features/academic/homeworksNewSlice";
import departmentsNewReducer from "../features/teacher-info/departmentNewSlice";
import designationsNewReducer from "../features/teacher-info/designationsNewSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    country: countrySlice,
    profile: profileSlice,

    gallery: gallerySlice,
    career: careerSlice,
    events: eventSlice,
    notices: noticesSlice,
    facilities: facilitieSlice,
    specialities: specialitySlice,
    sliders: sliderSlice,
    testimonials: testimonialSlice,

    pages: pagesSlice,
    teacherAttendance: teacherAttendanceReducer,
    // academic
    academicYear: academicYearSlice,
    academicYearsNew: academicYearsNewReducer,
    classesNew: classesNewReducer,
    classes: classSlice,
    timeSlots: timeSlotSlice,
    timeslotsNew: timeslotsNewReducer,
    courses: coursesSlice,
    coursesNew: coursesNewReducer,
    // routines: routinesSlice,
    routinesNew: routinesNewReducer,
    // calendar: calendarSlice,
    calendarsNew: calendarsNewReducer,
    sections: sectionSlice,
    sectionsNew: sectionsNewReducer,
    // assignments: assignmentSlice,
    assignmentsNew: assignmentsNewReducer,
    // assignmentSubmissions: assignmentSubmissionSlice,
    assignmentSubmissionsNew: assignmentSubmissionsNewReducer,
    // homeworks: homeworksSlice,
    homeworksNew: homeworksNewReducer,
    homeworkSubmissions: homeworkSubmissionsReducer,
    // students
    students: studentsReducer,
    studentAttendance: studentAttendanceReducer,
    enrollments: enrollmentsReducer,
    promotionRecords: promotionRecordsReducer,
    // teachers
    departments: departmentsSlice,
    departmentsNew: departmentsNewReducer,
    designations: designationsSlice,
    designationsNew: designationsNewReducer,
    teachers: teachersSlice,
    teachersNew: teachersNewReducer,
    // learning
    onlineClasses: onlineClassesReducer,
    studyMaterials: studyMaterialsReducer,
    // exam
    examTypes: examTypesReducer,
    grades: gradesReducer,
    exams: examsReducer,
    finalGrades: finalGradesReducer,
    examResults: examResultsReducer,
    // accounting
    budgets: budgetsReducer,
    expenseType: expenseTypeReducer,
    expenses: expensesReducer,
    feesStructures: feesStructuresReducer,
    feesPayments: feesPaymentsReducer,
    ledgers: ledgersReducer,
    transactions: transactionsReducer,
    // user
    users: usersReducer,
    // payroll
    leaveRequests: leaveRequestsReducer,
    payrollItems: payrollItemsReducer,
    paySchedules: paySchedulesReducer,
    payrolls: payrollsReducer,
    salarySlips: salarySlipsReducer,
  },
});

export default store;
