import React, { useEffect, useState } from "react";
import usePageTitle from "../../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchPages } from "../../../../libs/redux/features/website/pagesSlice";
import { Breadcrumb, Button, Pagination, Switch, Table } from "antd";
import {
  handleDelete,
  handleStatusChange,
} from "../../../../components/utility";
import { Link } from "react-router-dom";
import { PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";

const AdminPage = () => {
  usePageTitle("Admin Pages");
  const dispatch = useDispatch();
  const { data, isLoading, totalItems } = useSelector((state) => state.pages);

  const [filters, setFilters] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const offset = (filters.currentPage - 1) * filters.pageSize;

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    dispatch(
      fetchPages({
        limit: filters.pageSize,
        offset,
      })
    );
  }, [dispatch, offset, filters]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) =>
        (filters.currentPage - 1) * filters.pageSize + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
    },

    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      width: 100,
      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) =>
            handleStatusChange(
              checked,
              record,
              dispatch,
              `utility/admin/page/${record.id}/`,
              fetchPages
            )
          }
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link
            to={`/website/settings/admin-pages/${record.id}`}
            className="me-4"
          >
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.title}`,
                dispatch,
                `utility/admin/page/${record.id}/`,
                fetchPages
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Admin Pages</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Website" },
              { title: "Settings" },
              { title: "Admin Pages" },
            ]}
          />
        </div>
        <div>
          <Link to="/website/settings/admin-pages/add-page">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = (filters.currentPage - 1) * filters.pageSize + 1;
            const endItem = Math.min(
              filters.currentPage * filters.pageSize,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={filters.currentPage}
                  pageSize={filters.pageSize}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    handleFilterChange("currentPage", page);
                    handleFilterChange("pageSize", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default AdminPage;
